// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { percentage } from 'helpers/numberHelpers';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { SnapshotProgress } from './SnapshotProgress';

export const PaymentsProgress = () => {
  const {
    loanSummary: { numberOfRemainingPayments, numberOfPaymentsMade },
  } = useRecoilValue(selectedLoanState);

  const totalPaymentsAmount = numberOfRemainingPayments + numberOfPaymentsMade;

  return (
    <SnapshotProgress
      header='Remaining Payments'
      amount={numberOfRemainingPayments}
      progressPercentage={percentage(numberOfPaymentsMade, totalPaymentsAmount)}
      caption={`${numberOfPaymentsMade}/${totalPaymentsAmount} Payments Made`}
    />
  );
};
