/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { styled } from '@mui/material';
import { TextH1 } from 'components/Typographies';
import { PrivacyForm } from './PrivacyForm';
import { PrivacyFormDescription } from './PrivacyFormDescription';

const $PageContainer = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  paddingBottom: '72px',
}));

const $ContentContainer = styled('div')({
  maxWidth: '950px',
  margin: '0 auto',
  padding: '80px 16px 0',
});

const $PageTitle = styled(TextH1)({
  fontSize: '22px',
  textAlign: 'center',
  marginBottom: '40px',
});

export const DoNotSellMyPersonalInfo = () => (
  <$PageContainer>
    <$ContentContainer>
      <$PageTitle>Do Not Sell My Personal Information</$PageTitle>
      <PrivacyFormDescription />
      <PrivacyForm />
    </$ContentContainer>
  </$PageContainer>
);
