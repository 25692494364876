/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled } from '@mui/material';
import { Box } from '@mui/system';
import ExpandArrow from 'assets/Expand-Arrow.svg';
import { RotatingImage } from 'components/RotatingImage';
import { type ReactNode, useState } from 'react';
import { FooterLine } from './FooterLine';

const $Container = styled(Box)({
  color: 'rgba(255, 255, 255, 0.8)',
  fontWeight: 'normal',
  display: 'inline',
  textAlign: 'center',
});

const $ClickableTitleWrapper = styled('span')({
  textDecoration: 'underline',
  cursor: 'pointer',
});

const $ExpandIconWrapper = styled(Box)({
  textAlign: 'center',
  height: '100%',
  display: 'inline-block',
  padding: '4px 0 0 4px',
  verticalAlign: 'middle',
});

type SeeMoreLocationsT = {
  children: ReactNode | ReactNode[];
};

export const SeeMoreLocations = (props: SeeMoreLocationsT) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ExpandIcon = (
    <$ExpandIconWrapper>
      <RotatingImage variant={isExpanded ? 'rotated' : 'normal'} src={ExpandArrow} alt='toggle arrow icon' />
    </$ExpandIconWrapper>
  );

  return (
    <$Container display='inline' sx={isExpanded ? {} : { paddingBottom: '16px' }}>
      <$ClickableTitleWrapper onClick={() => setIsExpanded(!isExpanded)} data-testid='more-locations'>
        {isExpanded ? (
          <FooterLine>See Less Locations{ExpandIcon}</FooterLine>
        ) : (
          <FooterLine>See More Locations{ExpandIcon}</FooterLine>
        )}
      </$ClickableTitleWrapper>
      {isExpanded && <div>{props.children}</div>}
    </$Container>
  );
};
