/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Suspense, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Divider, styled } from '@mui/material';
import { AccountBalanceRounded } from '@mui/icons-material';
import { selectedLoanState } from 'store/LoanStore';
import { useLoansStateWithRefresh } from 'hooks/useLoanStateWithRefresh';
import { VSpacer } from 'components/VSpacer';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { usePayments } from 'hooks/usePayments';
import { TextH2 } from 'components/Typographies';
import { PaymentAccount } from '../PaymentAccount';
import type { PaymentAccountT } from '../PaymentAccountModel';
import { ManagePaymentSubmit } from './ManagePaymentSubmit';
import { ManagePaymentMethodExisting } from './ManagePaymentMethodExisting';

const $BankIcon = styled(AccountBalanceRounded)(({ theme }) => ({
  color: theme.palette.success.main,
  backgroundColor: theme.palette.success.light,
  padding: '18px',
  marginTop: '40px',
  marginBottom: '40px',
  width: '85px',
  height: 'auto',
  borderRadius: '16px',
}));

const $Divider = styled(Divider)(() => ({
  marginTop: '0px',
  marginBottom: '24px',
}));

export const ManagePaymentMethod = () => {
  const {
    paymentInformation: { bankAccountNumber },
  } = useRecoilValue(selectedLoanState);

  const { refreshLoans } = useLoansStateWithRefresh();
  const [isModifyingAutopay, setIsModifyingAutopay] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useForm<PaymentAccountT>({
    mode: 'onChange',
    defaultValues: {
      accountNumber: '',
      accountNumberConfirm: '',
      routingNumber: '',
      accountType: undefined,
      saveAsPrimary: true,
    },
  });

  const { handleSubmit, control, getValues, formState } = form;

  const { setPaymentMethod } = usePayments();

  const onSuccessfulSubmit: SubmitHandler<PaymentAccountT> = (data: PaymentAccountT) => {
    setLoading(true);
    setPaymentMethod(data)
      .then(() => {
        refreshLoans();
        setIsModifyingAutopay(false);
      })
      .catch((_error) => {
        throw new Error('manage payment method failed');
      })
      .finally(() => setLoading(false));
  };

  const pageTitle = bankAccountNumber ? 'Manage Payment Method' : 'Add Payment Method';

  return (
    <Suspense>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSuccessfulSubmit)} style={{ textAlign: 'center' }} data-testid='onetime-form'>
          <TextH2 sx={{ textAlign: 'center' }}>{pageTitle}</TextH2>
          <$BankIcon />
          <$Divider />
          {!bankAccountNumber || isModifyingAutopay ? (
            <>
              <PaymentAccount control={control} getValues={getValues} />
              <VSpacer spacing={4} />
              <ManagePaymentSubmit type='submit' loading={loading} disabled={!formState.isValid} />
              <VSpacer spacing={3} />
            </>
          ) : (
            <ManagePaymentMethodExisting onEdit={() => setIsModifyingAutopay(true)} />
          )}
        </form>
      </FormProvider>
    </Suspense>
  );
};
