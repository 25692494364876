/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box } from '@mui/material';
import type { Dayjs } from 'dayjs';
import CalendarIcon from 'assets/calendar.svg';
import { TextData4 } from 'components/Typographies';

type SchedulePaymentHeaderProps = {
  selectedDate: Dayjs;
};

export const SchedulePaymentHeader = ({ selectedDate }: SchedulePaymentHeaderProps) => (
  <Box display='flex' sx={{ width: '100%' }} data-testid='selectDate'>
    <img src={CalendarIcon} alt='calendar-icon' style={{ marginRight: '12px' }} />
    <TextData4 sx={(theme) => ({ [theme.breakpoints.down('md')]: { fontSize: '14px' } })}>
      Select Payment Date: {selectedDate.format('MM/DD/YYYY')}
    </TextData4>
  </Box>
);
