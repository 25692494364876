/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import type { ChangeEvent } from 'react';
import { forwardRef, useState } from 'react';
import type { InputBaseComponentProps } from '@mui/material';
import { NumericFormat, type InputAttributes } from 'react-number-format';
import { useController, type Control, type FieldValues, type FieldPath } from 'react-hook-form';
import { type TextFieldProps } from '@mui/material/TextField';
import type { FormRules } from 'helpers/formRulesHelpers';
import { FormTextField } from './FormTextField';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = forwardRef<typeof NumericFormat<InputAttributes>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={2}
      placeholder='$0.00'
      valueIsNumericString
      prefix='$'
    />
  );
});

interface State {
  numberformat: string;
}

type FormCurrencyFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  rules?: FormRules;
} & TextFieldProps;

export const FormCurrencyField = <T extends FieldValues>({
  control,
  name,
  ...textProps
}: FormCurrencyFieldProps<T>) => {
  const [values, setValues] = useState<State>({
    numberformat: '',
  });

  const { field } = useController({ name, control });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const inputProps = textProps.inputProps ?? {};
  textProps.inputProps = inputProps;

  return (
    <FormTextField
      label=''
      value={values.numberformat || field.value || ''}
      onChange={handleChange}
      name={name}
      id='formatted-numberformat-input'
      InputProps={{
        inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>,
      }}
      variant='outlined'
      control={control}
      {...textProps}
    />
  );
};
