// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

export const Statuses = {
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  SUSPENDED: 'SUSPENDED',
  UNKNOWN: 'UNKNOWN',
} as const;

export type StatusesType = Record<keyof typeof Statuses, typeof Statuses[keyof typeof Statuses]>;

export const Substatuses = {
  DELINQUENT: 'DELINQUENT',
  CHARGE_OFF: 'CHARGE_OFF',
  PAID_OFF: 'PAID_OFF',
} as const;
