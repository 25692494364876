// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import type { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/PublicHeader';

const $Container = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  minWidth: '320px',
  backgroundColor: `${theme.palette.background.default}`,
}));

export type PlainLayoutPropsT = {
  children: ReactNode;
};

export const PlainLayout = ({ children }: PlainLayoutPropsT) => (
  <$Container disableGutters maxWidth={false}>
    <Header />
    {children}
    <Footer />
  </$Container>
);
