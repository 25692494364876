// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { DialogTitle, styled } from '@mui/material';

const $DialogTitle = styled(DialogTitle)(({ theme }) => ({
  ...theme.typography.h1,
  color: '#003865',
  lineHeight: 1.6,
  fontFamily: 'AvenirNext',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '0',
  marginBottom: '16px',
  [theme.breakpoints.down('md')]: {
    marginTop: '32px',
  },
}));

export const SessionDialogTitle = () => <$DialogTitle>Still There?</$DialogTitle>;
