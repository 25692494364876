// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { styled, AppBar, Container, Toolbar, Box, type BoxProps as MuiBoxProps } from '@mui/material';
import NaluLogo from '../../assets/nalupay_logo.svg';

const $Header = styled(AppBar)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  backgroundColor: `${theme.palette.background.paper}`,
  marginBottom: '2px',
}));

const $Container = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0 24px !important',
  },
  [theme.breakpoints.only('md')]: {
    padding: '0 56px !important',
  },
  [theme.breakpoints.only('lg')]: {
    padding: '0 176px !important',
  },
}));

const $HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  height: '80px',
  margin: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    height: '56px',
  },
}));

const Logo = (props: MuiBoxProps) => <Box {...props} component='img' src={NaluLogo} alt='NaluLogo' />;

export const Header = () => (
  <$Header position='static'>
    <$Container maxWidth='lg'>
      <$HeaderToolbar disableGutters>
        <Logo />
      </$HeaderToolbar>
    </$Container>
  </$Header>
);
