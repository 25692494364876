/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { type ButtonProps as MuiButtonProps } from '@mui/material';
import { AutopayActionLink } from './AutopayActionLink';

type AutopayButtonPropsT = MuiButtonProps & {
  linkText: string;
};

export const AutopayButton = ({ linkText, ...rest }: AutopayButtonPropsT) => (
  <AutopayActionLink {...rest} variant='text'>
    {linkText}
  </AutopayActionLink>
);
