/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Drawer, styled } from '@mui/material';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import type { ReactNode } from 'react';
import { RouteNames } from 'hooks/useLoanCodeInRoute';
import { FlyoutHeader } from './FlyoutHeader';

const DRAWER_WIDTH = '520px';

const $Drawer = styled(Drawer)(({ theme }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    padding: '32px 40px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '32px 16px',
    },
  },
}));

type PaymentFlyoutPropsT = {
  children: ReactNode;
};

export const PaymentFlyout = ({ children }: PaymentFlyoutPropsT) => {
  const { navigateToParentRoute, navigateToDashboard, headRoute } = useNavigationHelpers();
  const handleClose = () => {
    if (headRoute === RouteNames.Dashboard) {
      navigateToDashboard();
    } else {
      navigateToParentRoute();
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <$Drawer variant='temporary' anchor='right' open onClose={handleClose}>
        <FlyoutHeader onClose={handleClose} />
        {children}
      </$Drawer>
    </Box>
  );
};
