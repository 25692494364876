/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { VSpacer } from 'components/VSpacer';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { DataRow } from 'components/DataRow';
import { BlueButton } from 'components/Buttons';
import { TitleSubTitle } from 'components/TitleSubTitle';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import { PaymentMethodCard } from './PaymentMethodCard';
import { AutopaySummary } from './AutopaySummary';

export const AutopayTurnedOff = () => {
  const loan = useRecoilValue(selectedLoanState);
  const { autopayEnabled, bankAccountNumber, bankAccountType, bankRoutingNumber } = loan.paymentInformation;
  const { navigateToParentRoute } = useNavigationHelpers();

  const handleClose = () => {
    navigateToParentRoute();
  };

  return (
    <>
      <TitleSubTitle
        title='Autopay Successfully Turned Off'
        subtitle='You must continue to make your scheduled loan payment.'
      />
      <AutopaySummary accountNumber={loan.loanCode} autopayOn={autopayEnabled} linkText='' />
      <DataRow label='Autopay has been turned off for this account:' data='' sx={{ width: '100%' }} />
      <VSpacer spacing={3} />
      <PaymentMethodCard
        accountType={bankAccountType}
        accountNumber={bankAccountNumber}
        routingNumber={bankRoutingNumber}
      />
      <VSpacer spacing={5} />
      <BlueButton onClick={handleClose} sx={{ textAlign: 'center' }}>
        Close
      </BlueButton>
    </>
  );
};
