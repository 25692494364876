/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled, Typography, useTheme } from '@mui/material';
import { PickersDay, type PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import dayjs, { type Dayjs } from 'dayjs';
import { useRecoilValue } from 'recoil';
import { firstPaymentWindowDate, lastBillingCycleDate } from 'store/LoanStore';

const $DueLabel = styled(Typography)(({ theme }) => ({
  fontSize: '8px',
  fontWeight: 600,
  lineHeight: 1,
  letterSpacing: '0.26px',
  marginTop: '-6px',
  marginLeft: '8px',
  zIndex: 2,
  width: 'min-content',
  padding: '0 2px 0 2px',
  borderRadius: '2px',
  color: theme.palette.error.main,
  backgroundColor: theme.palette.common.white,
}));

type SchedulePaymentDayProps = {
  day: Dayjs;
  dayComponentProps: PickersDayProps<dayjs.Dayjs>;
  selectedDate: Dayjs;
  dueDate: Dayjs;
};

export const SchedulePaymentDay = ({ day, dayComponentProps, selectedDate, dueDate }: SchedulePaymentDayProps) => {
  const theme = useTheme();
  const today = dayjs();
  const paymentWindowStartDate = useRecoilValue(firstPaymentWindowDate);
  const lastDayToPay = useRecoilValue(lastBillingCycleDate);
  const isSelected = !dayComponentProps.outsideCurrentMonth && day.isSame(selectedDate, 'day');
  const isDueDate = !dayComponentProps.outsideCurrentMonth && day.isSame(dueDate, 'day');

  const isOutsidePaymentWindow =
    !dayComponentProps.outsideCurrentMonth &&
    day.isAfter(today.subtract(1, 'day'), 'day') &&
    (day.isBefore(paymentWindowStartDate, 'day') ||
      (day.isAfter(dueDate, 'day') && day.isBefore(lastDayToPay.add(1, 'day'), 'day')));

  const isInsidePaymentWindow =
    !dayComponentProps.outsideCurrentMonth &&
    day.isAfter(today.subtract(1, 'day'), 'day') &&
    day.isAfter(paymentWindowStartDate.subtract(1, 'day'), 'day') &&
    day.isBefore(dueDate, 'day');

  const getBackgroundColor = () => {
    if (isOutsidePaymentWindow) {
      return theme.palette.secondary.main;
    }

    return isInsidePaymentWindow ? '#abdcfa' : theme.palette.common.white;
  };

  return (
    <Box display='flex' flexDirection='column' height={36} padding='0 2px 0 2px'>
      <PickersDay
        {...dayComponentProps}
        selected={isSelected}
        sx={{
          minHeight: '36px',
          border: isDueDate ? `solid 2px ${theme.palette.error.main}` : '0px',
          backgroundColor: getBackgroundColor(),
          '&:hover': { backgroundColor: getBackgroundColor() },
          '&.Mui-selected': { fontWeight: 600 },
        }}
      />
      {isDueDate && <$DueLabel>DUE</$DueLabel>}
    </Box>
  );
};
