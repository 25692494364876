/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled, Divider } from '@mui/material';
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { listToString } from 'helpers/stringHelpers';
import { type LoanTransactions } from 'store/TransactionStore';

// Use this as a base for all banners
export const $BannerWrapper = styled(Box)({
  marginBottom: '8px',
  width: '100%',
  borderRadius: '12px',
  boxShadow: '0 8px 20px 0 rgba(0, 0, 0, 0.16)',
});

export const $BannerDivider = styled(Divider)(({ theme }) => ({
  width: 0,
  height: '27px',
  margin: 'auto 31px',
  backgroundColor: theme.palette.background.default,
  borderColor: theme.palette.background.default,
}));

export function getPaymentProcessingLabel(loanTransactions: LoanTransactions[]) {
  if (loanTransactions.length === 0) {
    return '';
  }

  if (loanTransactions.length > 1) {
    return `Payments processing for ${listToString(
      loanTransactions.map((x) => x.loanCode),
      ', ',
      ' and ',
    )}. This may take up to five business days to process.`;
  }

  if (loanTransactions[0].transactions.length > 1) {
    return `Payments processing for ${loanTransactions[0].loanCode}. This may take up to five business days to process.`;
  }

  return `Payment processing for ${loanTransactions[0].loanCode}. This may take up to five business days to process.`;
}

export function getPastDuePaymentLabel(dashboard: boolean, pastDueAmount: number) {
  return dashboard
    ? 'You have 1 or more payment(s) that are past due. Please make a payment as soon as possible.'
    : `Your payment of ${toCurrencyFromCents(pastDueAmount)} is past due. To make your account current, 
      please make a payment.`;
}
