/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { InteractionStatus } from '@azure/msal-browser';
import type { TokenClaims } from '@azure/msal-common';
import { useMsal } from '@azure/msal-react';
import { useEventListener } from 'ahooks';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { useIdleTimer, type IIdleTimerProps } from 'react-idle-timer';
import { useSetRecoilState } from 'recoil';
import { autopayBannerDismissed, pastDueBannerDismissed } from 'store/BannerStore';
import { useUserStore, userAuthState, type UserInfo } from 'store/UserStore';

export type RenderComponentPropsT = {
  children: JSX.Element;
};

type CustomClaims = TokenClaims & {
  naluPayCustomerId: string;
};

export const RenderComponent = ({ children }: RenderComponentPropsT) => {
  const { instance, accounts, inProgress } = useMsal();
  const { logout, checkAuth } = useAuth();
  const claims = accounts[0]?.idTokenClaims as CustomClaims;
  const setAccount = useSetRecoilState(userAuthState);
  const { getUserInfo } = useUserStore();
  const setAutopayBannerDismissed = useSetRecoilState(autopayBannerDismissed);
  const setPastDueBannerDismissed = useSetRecoilState(pastDueBannerDismissed);

  const milliSeconds = 1000;
  const seconds = 60;
  const minutes = 15;
  const timeout = minutes * seconds * milliSeconds;

  const idleTimer: IIdleTimerProps = {
    timeout,
    crossTab: true,
    onIdle() {
      logout();
    },
  };

  useIdleTimer(idleTimer);

  const onFocus = () => {
    checkAuth();
  };

  useEventListener('focus', onFocus);

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then(async (authResponse) => {
        if (authResponse) {
          instance.setActiveAccount(authResponse.account);
          setAutopayBannerDismissed(false);
          setPastDueBannerDismissed(false);
        }

        if (accounts.length === 0) {
          logout();
        }

        if (inProgress === InteractionStatus.None && accounts.length > 0) {
          const userInfo: UserInfo = await getUserInfo();

          setAccount({
            ...userInfo,
            borrowerId: claims.naluPayCustomerId,
            isAuthenticated: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [instance, accounts, inProgress]);

  return children;
};
