/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { VSpacer } from 'components/VSpacer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedLoanState, submittedDashboardLoan } from 'store/LoanStore';
import { DataRow } from 'components/DataRow';
import { TextBody2 } from 'components/Typographies';
import { BlueButton } from 'components/Buttons';
import { TitleSubTitle } from 'components/TitleSubTitle';
import { showingAutopayThankYouBanner } from 'store/BannerStore';
import { Box, styled } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import { PaymentMethodCard } from './PaymentMethodCard';
import { AutopaySummary } from './AutopaySummary';

const $CheckIcon = styled(TaskAltIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  verticalAlign: 'middle',
  width: '21px',
  height: '21px',
  margin: '0 0 2px 16px',
}));

const title = (
  <>
    Autopay Successfully Enabled
    <$CheckIcon />
  </>
);

export const AutopayUpdated = () => {
  const location = useLocation();
  const isDashboard = location.pathname.toLowerCase().includes('dashboard');
  const loan = useRecoilValue(isDashboard ? submittedDashboardLoan : selectedLoanState);
  const { bankAccountNumber, bankAccountType, bankRoutingNumber } = loan.paymentInformation;
  const { navigateToParentRoute } = useNavigationHelpers();
  const setAutopayThankYouBanner = useSetRecoilState(showingAutopayThankYouBanner);

  const handleClose = () => {
    navigateToParentRoute();
  };

  useEffect(() => () => setAutopayThankYouBanner(true), []);

  return (
    <>
      <TitleSubTitle
        title={title}
        subtitle={
          <Box sx={{ lineHeight: '24px' }}>
            This account will be used to automatically apply your monthly loan payment amount to your account on the
            scheduled due date.
          </Box>
        }
      />
      <AutopaySummary accountNumber={loan.loanCode} autopayOn linkText='' />
      <DataRow label='Autopay has been turned on for this account:' data='' sx={{ width: '100%' }} />
      <VSpacer spacing={3} />
      <PaymentMethodCard
        accountType={bankAccountType}
        accountNumber={bankAccountNumber}
        routingNumber={bankRoutingNumber}
      />
      <VSpacer spacing={5} />
      <TextBody2>Thank you for enrolling in autopay!</TextBody2>
      <VSpacer spacing={5} />
      <BlueButton onClick={handleClose} sx={{ textAlign: 'center' }}>
        Close
      </BlueButton>
    </>
  );
};
