/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { SectionBox } from 'components/SectionBox';
import { groupPaymentsByYear } from 'helpers/arrayHelpers';
import { useRecoilValue } from 'recoil';
import { paymentsState } from 'store/PaymentStore';
import { NoPaymentHistory } from './NoPaymentHistory';
import { PaymentHistoryTab } from './PaymentHistoryTab';

export const PaymentHistory = () => {
  const paymentsForLoan = useRecoilValue(paymentsState);
  const paymentsGrouped = groupPaymentsByYear(paymentsForLoan);

  return (
    <SectionBox header='PAYMENT HISTORY' xs={12} data-testid='payment-history'>
      {paymentsGrouped.length > 0 ? (
        <>
          {paymentsGrouped.map(({ year, payments }, index) => (
            <PaymentHistoryTab key={year} year={year} payments={payments} expandOnLoad={index === 0} />
          ))}
        </>
      ) : (
        <NoPaymentHistory />
      )}
    </SectionBox>
  );
};
