/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

export const enum PaymentType {
  None = 'None',
  CurrentAmount = 'CurrentAmount',
  TotalAmount = 'TotalAmount',
}

export type PaymentAccountT = {
  paymentAmount: number;
  scheduledDate?: string;
  accountNumber?: string;
  accountNumberConfirm?: string;
  routingNumber?: string;
  accountType?: 'CHECKING' | 'SAVINGS';
  saveAsPrimary?: boolean;
  paymentType: PaymentType;
};
