/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Button, type ButtonProps, styled, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { sidebarState } from 'store/LayoutStore';
import Home from 'assets/home_icon.svg';
import { TextButton2 } from './Typographies';

const $DashboardButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  color: theme.palette.common.black,
  '&:hover': { backgroundColor: 'transparent' },
}));

export const DashboardButton = ({ ...props }: ButtonProps) => {
  const setSidebarOpen = useSetRecoilState(sidebarState);
  const navigate = useNavigate();

  const handleClick = () => {
    setSidebarOpen(false);
    navigate('/dashboard');
  };

  return (
    <$DashboardButton onClick={handleClick} {...props} data-testid='dashboardButton'>
      <Box sx={{ display: 'flex' }}>
        <img style={{ marginRight: '8px' }} src={Home} alt='house_icon' />
        <TextButton2>Dashboard</TextButton2>
      </Box>
    </$DashboardButton>
  );
};
