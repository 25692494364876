/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { VSpacer } from 'components/VSpacer';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { DataRow } from 'components/DataRow';
import { BlueButton, LoadingBlueButton, WhiteButton } from 'components/Buttons';
import { TitleSubTitle } from 'components/TitleSubTitle';
import type { Dispatch } from 'react';
import { useState } from 'react';
import { usePayments } from 'hooks/usePayments';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import { PaymentMethodCard } from './PaymentMethodCard';
import { AutopaySummary } from './AutopaySummary';
import { AutopayStates } from './AutopayStates';

type TurnOffAutopayPropsT = {
  setAutopayState: Dispatch<React.SetStateAction<AutopayStates>>;
};

export const TurnOffAutopay = ({ setAutopayState }: TurnOffAutopayPropsT) => {
  const {
    loanCode,
    paymentInformation: { autopayEnabled, bankAccountNumber, bankAccountType, bankRoutingNumber },
  } = useRecoilValue(selectedLoanState);
  const { turnOffAutopay } = usePayments();
  const [loading, setLoading] = useState(false);

  const handleTurnOffAutopay = () => {
    setLoading(true);
    turnOffAutopay()
      .then(() => setAutopayState(AutopayStates.TURNEDOFF))
      .catch(() => {
        throw new Error('turn off autopay failed');
      })
      .finally(() => setLoading(false));
  };

  const { navigateToParentRoute } = useNavigationHelpers();

  const handleClose = () => {
    navigateToParentRoute();
  };

  return (
    <>
      <TitleSubTitle
        title='Turn Off Autopay'
        subtitle='Monthly loan payments will no longer be automatically withdrawn from your account.'
      />
      <AutopaySummary accountNumber={loanCode} autopayOn={autopayEnabled} linkText='' />
      <DataRow label='Autopay will be turned off for this account:' data='' sx={{ width: '100%' }} />
      <VSpacer spacing={3} />
      <PaymentMethodCard
        accountType={bankAccountType}
        accountNumber={bankAccountNumber}
        routingNumber={bankRoutingNumber}
      />
      <VSpacer spacing={5} />
      {!loading && (
        <BlueButton onClick={handleTurnOffAutopay} sx={{ textAlign: 'center' }}>
          Turn Off
        </BlueButton>
      )}
      {loading && <LoadingBlueButton />}
      <VSpacer spacing={2} />
      <WhiteButton onClick={handleClose} disabled={loading} sx={{ textAlign: 'center' }}>
        Cancel
      </WhiteButton>
    </>
  );
};
