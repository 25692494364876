/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { DataRow } from 'components/DataRow';
import { FormCurrencyField } from 'components/FormComponents/FormCurrencyField';
import { VSpacer } from 'components/VSpacer';
import { minPaymentForAmountDue, minimumPaymentErrorMessage } from 'helpers/smallPaymentHelper';
import type { Control } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import type { PaymentAccountT } from '../PaymentAccountModel';

type PaymentEnterAmountPropsT = {
  control: Control<PaymentAccountT>;
  totalAmountDueCents: number;
};

export const PaymentEnterAmountCurrent = ({ control, totalAmountDueCents }: PaymentEnterAmountPropsT) => {
  const {
    paymentInformation: { totalPayoffCents },
  } = useRecoilValue(selectedLoanState);

  const minAmount = (value: string) => {
    const minPaymentFloat = minPaymentForAmountDue(totalAmountDueCents);
    return Number.parseFloat(value) >= minPaymentFloat || minimumPaymentErrorMessage(totalAmountDueCents);
  };

  return (
    <>
      <DataRow label='Enter Payment Amount:' data='' />
      <VSpacer spacing={3} />
      <FormCurrencyField
        control={control}
        name='paymentAmount'
        inputProps={{ 'data-testid': 'payment-amount' }}
        rules={{
          required: { value: true, message: 'This field is required.' },
          validate: {
            minAmount,
            maxAmount: (value: string) => Number.parseFloat(value) < 50_000 || 'Payment must be less than $50,000.',
            lessThanPayoffAmount: (value: string) =>
              Number.parseFloat(value) <= totalPayoffCents / 100 ||
              'Payment amount cannot be greater than total payoff amount',
          },
        }}
      />
    </>
  );
};
