/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { DividedContainer } from 'components/DividedContainer';
import { SkeletonLoader } from 'components/SkeletonLoader';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { useElementSize } from 'usehooks-ts';
import { SectionBox } from 'components/SectionBox';

const $LeftBox = styled(Box)({
  width: '100%',
  height: '148px',
});

export const LeftSkeleton = () => {
  const [ref, { width }] = useElementSize();

  return (
    <$LeftBox ref={ref}>
      <SkeletonLoader>
        <rect x={width / 2 - 65} y='0' rx='11' ry='11' width='130' height='22' />
        <rect x={width / 2 - 105} y='35' rx='22' ry='22' width='210' height='44' />
        <rect x='0' y='104' rx='8' ry='8' width='100%' height='16' />
        <rect x={width / 2 - 120} y='128' rx='8' ry='8' width='240' height='16' />
      </SkeletonLoader>
    </$LeftBox>
  );
};

const $RightBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '148px',
  [theme.breakpoints.down('lg')]: {
    height: '131px',
  },
}));

export const RightSkeleton = () => {
  const [ref, { width, height }] = useElementSize();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <$RightBox ref={ref}>
      <SkeletonLoader>
        <rect x='0' y={matches ? 0 : 12} rx='12.5' ry='12.5' width='50' height='25' />
        <rect x={width - 75} y={matches ? 0 : 8} rx='16.5' ry='16.5' width='75' height='33' />
        <rect x='0' y={height / 2 - 12.5} rx='12.5' ry='12.5' width='150' height='25' />
        <rect x={width - 117} y={height / 2 - 12.5} rx='12.5' ry='12.5' width='117' height='25' />
        <rect x='0' y={height - 25 - (matches ? 0 : 12)} rx='12.5' ry='12.5' width='150' height='25' />
        <rect x={width - 73} y={height - 25 - (matches ? 0 : 12)} rx='12.5' ry='12.5' width='73' height='25' />
      </SkeletonLoader>
    </$RightBox>
  );
};

export const SnapshotSkeleton = () => (
  <SectionBox header='SNAPSHOT' xs={12} lg={12}>
    <DividedContainer left={<LeftSkeleton />} right={<RightSkeleton />} />
  </SectionBox>
);
