/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { ErrorSubtitle16, TextSubtitle1 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { dateTo } from 'helpers/stringHelpers';
import { useRecoilValue } from 'recoil';
import { isSelectedLoanPastDue, selectedLoanState } from 'store/LoanStore';
import { AutopaySummary } from '../Autopay/AutopaySummary';
import type { PaymentAccountT } from '../PaymentAccountModel';
import { PaymentDueSummary } from '../PaymentDueSummary';
import { OneTimePaymentForm } from './OneTimePaymentForm';

type OneTimePaymentStep1PropsT = {
  onSubmit: (data: PaymentAccountT) => void;
  data: PaymentAccountT;
};

export const OneTimePaymentEntry = (props: OneTimePaymentStep1PropsT) => {
  const {
    paymentInformation: { paymentDueInCents, paymentDueDate, autopayEnabled, paymentToBringAccountCurrentCents },
    loanCode,
  } = useRecoilValue(selectedLoanState);

  const isAccountPastDue = useRecoilValue(isSelectedLoanPastDue);

  return (
    <>
      <PaymentDueSummary isAccountPastDue={isAccountPastDue} dueAmountInCents={paymentDueInCents} />
      {isAccountPastDue && (
        <ErrorSubtitle16>
          Includes past due balance of {toCurrencyFromCents(paymentToBringAccountCurrentCents)}
        </ErrorSubtitle16>
      )}
      {!isAccountPastDue && (
        <TextSubtitle1 sx={(theme) => ({ color: theme.palette.secondary.dark })}>
          Due: {dateTo('short_abbr', paymentDueDate)}
        </TextSubtitle1>
      )}
      <VSpacer spacing={4} />
      <AutopaySummary
        accountNumber={loanCode}
        autopayOn={autopayEnabled}
        linkText={autopayEnabled ? 'Manage' : 'Set Up'}
      />
      <VSpacer />
      <OneTimePaymentForm onSubmit={props.onSubmit} data={props.data} />
    </>
  );
};
