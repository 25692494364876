/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

import { atom } from 'recoil';

export type ToastT = {
  id: number;
  status: 'success' | 'fail';
  message: string;
};

export const toastIdState = atom({
  key: 'toastId',
  default: 0,
});

export const toastsState = atom<ToastT[]>({
  key: 'toasts',
  default: [],
});
