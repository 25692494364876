/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import type { FormControlLabelProps } from '@mui/material';
import { Box, FormControlLabel, Radio } from '@mui/material';

type FormRadioFieldProps = Omit<FormControlLabelProps, 'control'>;

export const FormRadioField = (props: FormRadioFieldProps) => (
  <FormControlLabel
    {...props}
    control={<Radio />}
    label={
      <Box fontSize='16px' fontWeight='500' letterSpacing='.38px' lineHeight='28px'>
        {props.label}
      </Box>
    }
  />
);
