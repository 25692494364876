/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from 'config/authConfig';
import { GeneralError } from 'components/GeneralError';
import { RenderComponent } from './RenderComponent';

export type AuthenticateUserPropsT = {
  children: JSX.Element;
};

export const AuthenticateUser = ({ children }: AuthenticateUserPropsT) => {
  const { inProgress, instance } = useMsal();
  let loadingText = '';

  if (inProgress === InteractionStatus.Login) {
    loadingText = 'Loading...';
  }

  if (inProgress === InteractionStatus.Logout) {
    return <p>Logging out</p>;
  }

  const loading = () => <p>{loadingText}</p>;
  const error = () => {
    instance.loginRedirect().catch((error_) => console.error(error_));
    return <GeneralError />;
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      loadingComponent={loading}
      errorComponent={error}
    >
      <RenderComponent>{children}</RenderComponent>
    </MsalAuthenticationTemplate>
  );
};
