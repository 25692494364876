/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import useMediaQuery from '@mui/material/useMediaQuery';
import ThankYouIconBlue from 'assets/icon-thank-you-blue.svg';
import { styled, Link } from '@mui/material';
import { TextH1 } from 'components/Typographies';
import { Theme } from 'theme';

export const $BoxWithWhiteBackground = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  paddingBottom: '72px',
}));

export const $FormContainerNoTopBar = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  maxWidth: '950px',
  margin: '0 auto',
  padding: '80px 16px 0',
}));

export const $PageTitleNoTopBar = styled(TextH1)({
  fontSize: '22px',
  textAlign: 'center',
  margin: '0 0 40px',
  lineHeight: '28px',
});

const $Text = styled('span')(({ theme }) => ({
  ...theme.typography.data4,
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: 0.16,
  lineHeight: '28px',
}));

export const ThankyouPage = () => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('md'));

  return (
    <$BoxWithWhiteBackground style={{ textAlign: 'center' }}>
      <$FormContainerNoTopBar>
        <$PageTitleNoTopBar>Thank You!</$PageTitleNoTopBar>
        <div style={{ textAlign: 'center', marginBottom: '64px' }}>
          <img src={ThankYouIconBlue} alt='thank you icon' />
        </div>
        <$Text data-testid='donotsell'>
          Your form has been successfully submitted. You may close this window or tab.
          <br />
          <br />
          For further information on our privacy practices,
          <br />
          please review our{' '}
          <Link target='_blank' rel='noopener noreferrer' href='/PrivacyPolicy' sx={{ textDecoration: 'none' }}>
            notice
          </Link>{' '}
          or contact us {isMobile && <br />} at 877-NaluPay /{' '}
          <Link sx={{ color: 'inherit', textDecoration: 'none' }} href='tel:(877) 625-8729'>
            (877) 625-8729.
          </Link>
        </$Text>
      </$FormContainerNoTopBar>
    </$BoxWithWhiteBackground>
  );
};
