// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { DialogContentText, styled } from '@mui/material';

const $DialogContentText = styled(DialogContentText)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.34px',
  color: '#1A1818',
  fontFamily: 'AvenirNext',
  lineHeight: '24px',
  textAlign: 'center',
  paddingTop: '0',
  [theme.breakpoints.up('md')]: {
    paddingRight: '80px',
    paddingLeft: '80px',
  },
  [theme.breakpoints.down('md')]: {
    paddingRight: '20px',
    paddingLeft: '20px',
  },
}));

export const SessionDialogContent = () => (
  <$DialogContentText id='alert-dialog-description'>
    It looks like you are currently inactive on <br />
    NaluPay Customer Portal and will be <br />
    automatically logged out.
  </$DialogContentText>
);
