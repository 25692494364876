/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextH4 } from 'components/Typographies';
import { SkeletonLoader } from 'components/SkeletonLoader';

const $Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 0 25px 0',
  backgroundColor: theme.palette.background.paper,
  height: '123px',
}));

const $Label = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '8px',
  width: '318px',
});

const $Input = styled(Box)({
  width: '320px',
  height: '41px',
});

export const LoanSelectionSkeleton = () => (
  <$Container>
    <$Label id='select-account'>
      <TextH4 color='primary'>YOUR ACCOUNTS:</TextH4>
    </$Label>
    <$Input>
      <SkeletonLoader>
        <rect x='0' y='0' rx='21' ry='21' width='100%' height='100%' />
      </SkeletonLoader>
    </$Input>
  </$Container>
);
