/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { type Loan } from '../store/LoanStore';
import { type Payment } from '../store/PaymentStore';

export const sortLoansByDate = (loanArray: Loan[]) => {
  const arrayToSort = [...loanArray];
  arrayToSort.sort(
    (a, b) =>
      new Date(a.paymentInformation?.paymentDueDate).getTime() -
      new Date(b.paymentInformation?.paymentDueDate).getTime(),
  );
  return arrayToSort;
};

export const groupPaymentsByYear = (payments: Payment[]) => {
  const groupedPayments = new Map<string, Payment[]>();

  for (const payment of [...payments].sort(
    (a, b) => new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime(),
  )) {
    const key = `${new Date(payment.effectiveDate).getFullYear()}`;
    const group = groupedPayments.get(key) ?? [];
    group.push(payment);
    groupedPayments.set(key, group);
  }

  return [...groupedPayments].map((group) => ({ year: group[0], payments: group[1] }));
};
