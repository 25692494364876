/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, type BoxProps } from '@mui/material';

type VSpacerPropsT = {
  spacing?: number;
} & BoxProps;

export const VSpacer = ({ spacing = 1, ...boxProps }: VSpacerPropsT) => (
  <Box sx={(theme) => ({ marginBottom: theme.spacing(spacing) })} {...boxProps} />
);
