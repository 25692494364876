/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { TitleSubTitle } from 'components/TitleSubTitle';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { AutopayExisting } from './AutopayExisting';
import { AutopaySetup } from './AutopaySetup';
import { AutopaySummary } from './AutopaySummary';

type ManageAutopayPropsT = {
  onTurnOff: () => void;
  onUpdatedSuccess: () => void;
};

export const ManageAutopay = ({ onTurnOff, onUpdatedSuccess }: ManageAutopayPropsT) => {
  const {
    loanCode,
    paymentInformation: { autopayEnabled },
  } = useRecoilValue(selectedLoanState);
  const [isModifyingAutopay, setIsModifyingAutopay] = useState(!autopayEnabled);

  return (
    <>
      <TitleSubTitle
        title={autopayEnabled ? 'Manage Autopay' : 'Set Up Autopay'}
        subtitle='An easy and secure way to enroll and manage your automatic loan payments.'
      />
      <AutopaySummary
        accountNumber={loanCode}
        autopayOn={autopayEnabled}
        linkText={autopayEnabled ? 'Turn Off' : ''}
        onClick={onTurnOff}
      />
      {isModifyingAutopay ? (
        <AutopaySetup onUpdatedSuccess={onUpdatedSuccess} defaultToNewAccount={autopayEnabled} />
      ) : (
        <AutopayExisting onEdit={() => setIsModifyingAutopay(true)} />
      )}
    </>
  );
};
