// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { useState, useEffect } from 'react';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import type { CircularProgressWithLabelPropsT } from './CircularProgressWithLabel';

type CountdownTimerPropsT = CircularProgressWithLabelPropsT & {
  onTimeCompleted: () => void;
};

const CountdownTimer = (props: CountdownTimerPropsT) => {
  const [seconds, setSeconds] = useState(props.value);
  const [completed, setCompleted] = useState(false);
  const milliSeconds = 1000;
  let timer: ReturnType<typeof setInterval>;

  useEffect(() => {
    timer = setInterval(() => {
      setSeconds((previousSeconds) => (previousSeconds - 1 > 0 ? previousSeconds - 1 : timeCompleted()));
    }, milliSeconds);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (completed) {
      clearInterval(timer);
      props.onTimeCompleted();
    }
  }, [completed]);

  const timeCompleted = (): number => {
    setCompleted(true);
    return 0;
  };

  return <CircularProgressWithLabel value={seconds} />;
};

export default CountdownTimer;
