// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { IconButton, styled, Tooltip, tooltipClasses } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { type ReactNode, forwardRef } from 'react';

const $IconButton = styled(IconButton)({
  padding: '0 0 0 8px',
});

const $InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  verticalAlign: 'top',
  fontSize: '19px',
}));
const $Tooltip = styled<typeof Tooltip>(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.dark,
    margin: '8px 0 0 0 !important',
    padding: '8px',
    '> *': {
      color: theme.palette.background.default,
    },
  },
}));

export const InfoIcon = forwardRef<SVGSVGElement>((props, reference) => <$InfoIcon {...props} ref={reference} />);

export type InfoTooltipPropsT = { toolTip: NonNullable<ReactNode> };

export const InfoTooltip = ({ toolTip }: InfoTooltipPropsT) => (
  <$Tooltip title={toolTip} enterTouchDelay={200}>
    <$IconButton disableRipple>
      <InfoIcon />
    </$IconButton>
  </$Tooltip>
);
