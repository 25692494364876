/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { Footer } from 'components/Footer/Footer';
import { TagResponseHeader } from 'components/Header/TagResponseHeader';
import { $Container, $ContentBox, $ContentHeadline, $ContentSubtext } from './TagAccepted';

export const TagError = () => (
  <$Container>
    <TagResponseHeader />
    <$ContentBox>
      <$ContentHeadline>Sorry!</$ContentHeadline>
      <$ContentSubtext>An error has occured.</$ContentSubtext>
    </$ContentBox>
    <Footer />
  </$Container>
);
