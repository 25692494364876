/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { Autocomplete, FormControl, TextField, type TextFieldProps } from '@mui/material';
import type { FormRules } from 'helpers/formRulesHelpers';
import { useState } from 'react';
import type { Control, FieldValues, FieldPath } from 'react-hook-form';
import { useController } from 'react-hook-form';

type FormAutocompleteProps<T extends FieldValues> = {
  name: FieldPath<T>;
  options: string[];
  control: Control<T>;
  rules: FormRules;
} & TextFieldProps;

export const FormAutocomplete = <T extends FieldValues>({
  name,
  control,
  options,
  rules,
  ...textProps
}: FormAutocompleteProps<T>) => {
  const [showingError, setShowingError] = useState(false);
  const { field, fieldState, formState } = useController({
    name,
    control,
    rules,
    defaultValue: undefined,
  });

  return (
    <FormControl fullWidth>
      <Autocomplete
        id='state-drop-down'
        data-testid='autocomplete'
        openOnFocus
        options={options}
        value={field.value as string}
        onChange={(_, data) => field.onChange(data)}
        onBlur={() => {
          setShowingError(true);
          field.onBlur();
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textProps}
            inputProps={{ ...params.inputProps, 'data-lpignore': 'true' }}
            helperText={(formState.submitCount > 0 || showingError) && fieldState.error?.message}
            error={(formState.submitCount > 0 || showingError) && !!fieldState.error}
            inputRef={field.ref}
          />
        )}
      />
    </FormControl>
  );
};
