/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { Navigate } from 'react-router-dom';
import useTagStoreFeature from 'hooks/UseTagStoreFeature';
import { getQueryString } from 'helpers/getQueryStringHelpers';
import { TagLoading } from './TagLoading';

const TagWrapper = () => {
  const parsed = getQueryString(window.location.search);
  const { tagData } = useTagStoreFeature(parsed.data);

  return tagData.action && tagData.transaction.merchant.merchantName ? (
    <Navigate to={'/'.concat(tagData.action)} state={{ merchant: tagData.transaction.merchant.merchantName }} />
  ) : (
    <TagLoading />
  );
};

export default TagWrapper;
