// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

export const TransactionStatuses = {
  AUTHORIZED: 'AUTHORIZED',
  DECLINED: 'DECLINED',
  INVALID: 'INVALID',
  OFFSET: 'OFFSET',
  PENDING: 'PENDING',
  POSTED: 'POSTED',
  PROCESSING: 'PROCESSING',
  RETRO_VALID: 'RETRO_VALID',
  REVERSED: 'REVERSED',
  ROLLED: 'ROLLED',
  SETTLED: 'SETTLED',
  SPLIT_INVALID: 'SPLIT_INVALID',
  SPLIT_VALID: 'SPLIT_VALID',
  VALID: 'VALID',
  VOID: 'VOID',
} as const;

export type TransactionStatusesT = Record<
  keyof typeof TransactionStatuses,
  typeof TransactionStatuses[keyof typeof TransactionStatuses]
>;
