// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { styled } from '@mui/material';
import { useState } from 'react';
import type { ReactNode } from 'react';

const $modalStyles = styled('div')({
  zIndex: 1000,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: 'rgba(0, 0, 0, 0.2)',
});

export const useModal = () => {
  const [display, setDisplay] = useState(false);
  const openModal = () => setDisplay(true);
  const closeModal = () => setDisplay(false);
  const toggleModal = () => setDisplay(!display);

  type ModalProps = {
    children: ReactNode;
  };

  const Modal = (props: ModalProps) => (
    <>{display && <$modalStyles onClick={closeModal}>{props.children}</$modalStyles>}</>
  );

  return { openModal, closeModal, toggleModal, Modal, display };
};
