// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { Avatar as MuiAvatar, styled, type AvatarProps as MuiAvatarProps } from '@mui/material';
import { TextH4 } from 'components/Typographies';

const $Initials = styled(TextH4)(({ theme }) => ({
  letterSpacing: '0.34px',
  color: theme.palette.common.white,
}));

export type AvatarPropsT = MuiAvatarProps & {
  initials: string;
};

export const Avatar = ({ initials, ...rest }: AvatarPropsT) => (
  <MuiAvatar {...rest}>
    <$Initials>{initials}</$Initials>
  </MuiAvatar>
);
