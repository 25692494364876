/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { atom, selector } from 'recoil';
import endpointConfigs from 'config/endpointConfig';
import { axios } from 'helpers/axiosInstance';

type OpenIdConfig = {
  userinfo_endpoint: string;
};

export type UserInfo = {
  username: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
};

type UserAuthState = UserInfo & {
  borrowerId: string;
  isAuthenticated: boolean;
};

const defaultUserInfo: UserInfo = {
  username: '',
  firstName: '',
  lastName: '',
};

export const userAuthState = atom<UserAuthState>({
  key: 'userAuth',
  default: {
    ...defaultUserInfo,
    borrowerId: '',
    isAuthenticated: false,
  },
});

export const userInitialsState = selector({
  key: 'userInitials',
  get({ get }) {
    const account = get(userAuthState);
    const initialFirstName = account?.firstName?.charAt(0) ?? '';
    const initialLastName = account?.lastName?.charAt(0) ?? '';
    return `${initialFirstName}${initialLastName}`;
  },
});

export const useUserStore = () => {
  const getUserInfo = async () => {
    const { data } = await axios<OpenIdConfig>(endpointConfigs.AUTHORITY.OPENID_CONFIG);
    return data.userinfo_endpoint ? getUser(data.userinfo_endpoint) : defaultUserInfo;
  };

  const getUser = async (url: string) => {
    const config = {
      url,
      method: 'POST',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    };
    const { data } = await axios<UserInfo>(config);
    return data;
  };

  return {
    getUserInfo,
  };
};
