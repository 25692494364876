// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import type { IconProps } from '@mui/material';
import { Icon, styled } from '@mui/material';

interface ExpandMoreProps extends IconProps {
  expand: boolean;
}

// The expand prop is removed from props because otherwise it is added to the dom
export const ExpandMore = styled(({ expand: _, ...props }: ExpandMoreProps) => <Icon {...props} />)(
  ({ theme, expand }) => ({
    transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }),
);
