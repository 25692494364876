/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { Box, Grid, styled } from '@mui/material';
import { ActionLink } from 'components/ActionLink';
import { FormCheckboxField } from 'components/FormComponents/FormCheckboxField';
import { TextH2, TextSubtitle2 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import type { Control, UseFormGetValues } from 'react-hook-form';
import { AccountBalanceRounded } from '@mui/icons-material';
import { PaymentAccount } from '../PaymentAccount';
import type { PaymentAccountT } from '../PaymentAccountModel';

type OneTimePaymentFormNewPropsT = {
  control: Control<PaymentAccountT>;
  getValues: UseFormGetValues<PaymentAccountT>;
  onCancel: () => void;
};

const $BankIcon = styled(AccountBalanceRounded)(({ theme }) => ({
  color: theme.palette.info.main,
  marginRight: '8px',
}));

const $StyledLabel = styled('div')(() => ({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '28px',
  letterSpacing: '0.38px',
  textAlign: 'left',
}));

const $Subtitle2 = styled(TextSubtitle2)(() => ({
  textAlign: 'left',
  paddingLeft: '34px',
  paddingRight: '',
}));

export const OneTimePaymentFormNew = ({ control, getValues, onCancel }: OneTimePaymentFormNewPropsT) => (
  <>
    <Box textAlign='left'>
      <Grid container direction='row'>
        <$BankIcon />
        <TextH2>Add Payment Method</TextH2>
      </Grid>
      <VSpacer spacing={5} />
      <PaymentAccount control={control} getValues={getValues} />
      <VSpacer spacing={4} />
      <FormCheckboxField
        label={<$StyledLabel>Save as new primary payment method</$StyledLabel>}
        control={control}
        name='saveAsPrimary'
      />
      <$Subtitle2>
        This will replace the current payment method. Only one payment method is saved per account.
      </$Subtitle2>
    </Box>
    <VSpacer spacing={2} />
    <ActionLink onClick={onCancel} style={{ fontSize: '14px' }}>
      Nevermind, use my current payment method
    </ActionLink>
  </>
);
