/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

import { selector } from 'recoil';
import endpointConfigs from 'config/endpointConfig';
import { axios } from 'helpers/axiosInstance';
import { selectedLoanState } from './LoanStore';

export type Statement = {
  id: string;
  fileName: string;
  month: string;
  year: string;
  createdDate: string;
  startDate: string;
  endDate: string;
};

export type AutopayForm = {
  id: string;
  accountId: string;
  applicationCode: string;
  authorizationDate: string;
  fileLocation: string;
  fileName: string;
};

const defaultStatements: Statement[][] = [];
const defaultAutopayForms: AutopayForm[] = [];

export const statementsState = selector<Statement[][]>({
  key: 'statements',
  async get({ get }) {
    const { loanCode } = get(selectedLoanState);
    const config = { ...endpointConfigs.BORROWER_PORTAL.GET_STATEMENT_INFO };
    config.url = config.url?.replace('{loanCode}', loanCode);
    const { data } = await axios<Statement[][]>(config);
    return data ?? defaultStatements;
  },
});

export const autopayState = selector<AutopayForm[]>({
  key: 'autopay',
  async get({ get }) {
    const { loanCode } = get(selectedLoanState);
    const config = { ...endpointConfigs.BORROWER_PORTAL.GET_AUTOPAY_INFO };
    config.url = config.url?.replace('{loanCode}', loanCode);
    const { data } = await axios<AutopayForm[]>(config);
    return data ?? defaultAutopayForms;
  },
});

export const useDocumentStore = () => {
  const getStatementPdf = async (id: string, m: number, y: number) => {
    const config = { ...endpointConfigs.BORROWER_PORTAL.GET_STATEMENT_PDF };
    config.params = { loanCode: id, month: m, year: y };
    const { data } = await axios<Blob>(config);
    return data;
  };

  const getAutopayPdf = async (fileName: string) => {
    const config = { ...endpointConfigs.BORROWER_PORTAL.GET_AUTOPAY_DOCS };
    config.url = config.url?.replace('{fileName}', fileName);
    const { data } = await axios<Blob>(config);
    return data;
  };

  return {
    getStatementPdf,
    getAutopayPdf,
  };
};
