// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { useRecoilValue } from 'recoil';
import { DividedContainer } from 'components/DividedContainer';
import { currentLoanPeriodState } from 'store/LoanStore';
import { LoanPeriods } from 'LoanPeriod';
import { CreditLineDetails } from './SnapshotDetails/CreditLineDetails';
import { CreditLineProgress } from './SnapshotProgress/CreditLineProgress';
import { LoanDetails } from './SnapshotDetails/LoanDetails';
import { PaymentsProgress } from './SnapshotProgress/PaymentProgress';
import { PromoPeriodProgress } from './SnapshotProgress/PromoPeriodProgress';

export const SnapshotStateSelector = () => {
  const currentLoanPeriod = useRecoilValue(currentLoanPeriodState);
  switch (currentLoanPeriod) {
    case LoanPeriods.PURCHASE_WINDOW_EXPIRED_PROMO_ONGOING:
      return <DividedContainer left={<PromoPeriodProgress />} right={<LoanDetails />} />;
    case LoanPeriods.PURCHASE_WINDOW_EXPIRED_PROMO_EXPIRED:
      return <DividedContainer left={<PaymentsProgress />} right={<LoanDetails />} />;
    default: // Purchase window is not expired
      return <DividedContainer left={<CreditLineProgress />} right={<CreditLineDetails />} />;
  }
};
