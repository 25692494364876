/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled } from '@mui/material';
import { useDocumentStore, autopayState } from 'store/DocumentStore';
import { useRecoilValue } from 'recoil';
import { dateTo } from 'helpers/stringHelpers';
import { downloadBlob, openBlob } from 'helpers/blobHelpers';
import { DocumentRow } from './DocumentRow';
import { NoDocuments } from './NoDocuments';

const $Container = styled(Box)({
  width: '100%',
});

export const AutopayDocuments = () => {
  const autopayDocuments = useRecoilValue(autopayState);
  const { getAutopayPdf } = useDocumentStore();
  const showDivider = (index: number) => autopayDocuments.length - 1 !== index;

  const handleDownload = (file: string, date: string) => {
    getAutopayPdf(file)
      .then((response) => {
        downloadBlob(response, `Auth_${dateTo('no_separator', date)}`);
      })
      .catch((_error) => {
        throw new Error('Unable to download autopay document with file name '.concat(file));
      });
  };

  const handleOpen = (file: string) => {
    getAutopayPdf(file)
      .then((response) => {
        openBlob(response);
      })
      .catch((_error) => {
        throw new Error('Unable to open autopay document with file name '.concat(file));
      });
  };

  return autopayDocuments.length > 0 ? (
    <$Container>
      {autopayDocuments.map((autopayDocument, index) => (
        <DocumentRow
          key={`${index}`}
          header='Autopay Authorization Form'
          subHeader={dateTo('numeric', autopayDocument.authorizationDate)}
          {...autopayDocument}
          showDivider={showDivider(index)}
          onDownload={() => handleDownload(autopayDocument.fileName, autopayDocument.authorizationDate)}
          onOpen={() => handleOpen(autopayDocument.fileName)}
        />
      ))}
    </$Container>
  ) : (
    <NoDocuments />
  );
};
