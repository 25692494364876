// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { styled } from '@mui/material';

interface RotatingImageProps {
  variant?: 'normal' | 'rotated';
}

export const RotatingImage = styled('img', {
  shouldForwardProp: (property) => property !== 'variant' && property !== 'sx',
  name: 'RotatingImage',
})<RotatingImageProps>(() => ({}));
