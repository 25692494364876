/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Grid, styled } from '@mui/material';
import { WhiteButton } from 'components/Buttons';
import { MobileAndTab } from 'components/MobileAndTab';
import { SectionBox } from 'components/SectionBox';
import { VSpacer } from 'components/VSpacer';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Statuses } from 'Status';
import { selectedLoanState } from 'store/LoanStore';
import { PaymentMethodCard } from './Autopay/PaymentMethodCard';
import { PayWithCheckCollapse } from './PayWithCheck/PayWithCheckCollapse';

const $Spacing = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
}));

export const PaymentMethod = () => {
  const loan = useRecoilValue(selectedLoanState);
  const loanStatus = loan.loanSummary.accountStatus;
  const navigate = useNavigate();
  const location = useLocation();
  const { bankAccountNumber, bankAccountType, bankRoutingNumber } = loan.paymentInformation;

  const managePaymentHandler = () => navigate(`${location.pathname}/manage`);

  return (
    <SectionBox header='PAYMENT METHOD' xs={12} lg={5}>
      <Grid container alignItems='center' flexDirection='column'>
        <$Spacing>
          <PaymentMethodCard
            accountNumber={bankAccountNumber}
            routingNumber={bankRoutingNumber}
            accountType={bankAccountType}
            border={false}
          />
        </$Spacing>
        {loanStatus !== Statuses.CLOSED && (
          <WhiteButton onClick={managePaymentHandler}>Manage Payment Method</WhiteButton>
        )}
        <VSpacer spacing={5} />
        <MobileAndTab>
          <PayWithCheckCollapse />
        </MobileAndTab>
      </Grid>
    </SectionBox>
  );
};
