/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { VSpacer } from 'components/VSpacer';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { Box } from '@mui/material';
import { BlueButton } from 'components/Buttons';
import { PaymentMethodCurrent } from '../PaymentMethodCurrent';

type ManagePaymentMethodExistingPropsT = {
  onEdit: () => void;
};

export const ManagePaymentMethodExisting = ({ onEdit }: ManagePaymentMethodExistingPropsT) => {
  const loan = useRecoilValue(selectedLoanState);
  const { bankAccountNumber, bankAccountType, bankRoutingNumber } = loan.paymentInformation;

  return (
    <>
      <Box sx={{ width: '100%', alignSelf: 'left' }}>
        <PaymentMethodCurrent
          accountNumber={bankAccountNumber}
          routingNumber={bankRoutingNumber}
          accountType={bankAccountType}
        />
      </Box>
      <VSpacer spacing={5} />
      <BlueButton onClick={onEdit}>Change Payment Method</BlueButton>
      <VSpacer spacing={5} />
    </>
  );
};
