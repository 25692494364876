// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { CircularProgress, styled } from '@mui/material';

const $TextWrapper = styled('span')(({ theme }) => ({
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
}));

const $LoadingIconWrapper = styled('span')({
  transform: 'translateX(16px)',
  display: 'flex',
});

type LoadingTextProps = {
  text: string;
  loading?: boolean;
};

const LoadingText = ({ text, loading }: LoadingTextProps) => (
  <$TextWrapper>
    <span> {text} </span>
    <$LoadingIconWrapper>{loading && <CircularProgress size='18px' color='inherit' />}</$LoadingIconWrapper>
  </$TextWrapper>
);

LoadingText.defaultProps = {
  loading: false,
};

export default LoadingText;
