/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { InteractionRequiredAuthError, type PublicClientApplication } from '@azure/msal-browser';
import { type AxiosRequestConfig } from 'axios';
import { loginRequest } from 'config/authConfig';

export const setAuthorizationHeader = async (config: AxiosRequestConfig, msalInstance: PublicClientApplication) => {
  config.headers ??= {};
  config.headers.Authorization = 'No token found';

  const account = msalInstance.getActiveAccount();
  if (account) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      config.headers.Authorization = `Bearer ${response.accessToken}`;
    } catch (error: unknown) {
      if (error instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect({ ...loginRequest, account });
      }
    }
  }

  return config;
};
