/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Button, Divider, styled } from '@mui/material';
import { Avatar } from 'components/Avatar';
import { Desktop } from 'components/Desktop';
import { TextH35 } from 'components/Typographies';
import { useAuth } from 'hooks/useAuth';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAuthState, userInitialsState } from '../../store/UserStore';

const $Container = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const $Avatar = styled(Avatar)({
  marginRight: '12px',
});

const $NameContainer = styled(Box)({
  display: 'flex',
  alignContent: 'center',
  '& > span': {
    margin: 'auto 0',
  },
});

const $Logout = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  borderRadius: '21px',
  border: `solid 1px ${theme.palette.primary.light}`,
  height: '41px',
}));

export const ProfileMenu = () => {
  const { logout } = useAuth();
  const [account] = useRecoilState(userAuthState);
  const initials = useRecoilValue(userInitialsState);

  return (
    <$Container>
      <Box>
        <$NameContainer>
          <$Avatar initials={initials} sx={{ display: { xs: 'flex', lg: 'none' } }} />
          <TextH35 color={(theme) => theme.palette.common.black}>
            {account.firstName} {account.lastName}
          </TextH35>
        </$NameContainer>
        <Desktop>
          <Divider />
        </Desktop>
      </Box>
      <$Logout variant='outlined' fullWidth onClick={() => logout()}>
        Logout
      </$Logout>
    </$Container>
  );
};
