// Copyright © Bankers Healthcare Group, Inc. All rights reserved.

import { useTagStore } from 'store/TagStore';
import { useEffect } from 'react';

export default function useTagStoreFeature(data: string) {
  const { tagData, getTagResult } = useTagStore();

  useEffect(() => {
    void getTagResult(data);
  }, []);

  return { tagData };
}
