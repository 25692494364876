/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

const minimumPaymentAllowedCents = 1000;
const minimumPaymentAllowedForGreaterThanTotalAmountDue = 10.0;
const minimumPaymentAllowedForLessThanTotalAmountDue = 0.01;
const errorMessageForGreaterThanTotalAmountDue = `Payment must be at least $10.`;
const errorMessageForLessThanTotalAmountDue = `Payment must be more than $0`;

export function minPaymentForAmountDue(totalAmountDueCents: number): number {
  return totalAmountDueCents > minimumPaymentAllowedCents
    ? minimumPaymentAllowedForGreaterThanTotalAmountDue
    : minimumPaymentAllowedForLessThanTotalAmountDue;
}

export function minimumPaymentErrorMessage(totalAmountDueCents: number): string {
  return totalAmountDueCents > minimumPaymentAllowedCents
    ? errorMessageForGreaterThanTotalAmountDue
    : errorMessageForLessThanTotalAmountDue;
}
