// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { Box, styled } from '@mui/material';
import { DataRow } from 'components/DataRow';
import { Status } from 'components/Status';
import type { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';

const $Box = styled(Box)({
  display: 'flex',
  height: '131px',
  width: '100%',
  flexDirection: 'column',
  alignSelf: 'center',
});

type SnapshotDetailsPropsT = {
  data: Record<string, ReactNode>;
  isClosed?: boolean;
};

export const SnapshotDetails = ({ data, isClosed = false }: SnapshotDetailsPropsT) => {
  const {
    loanSummary: { accountStatus },
  } = useRecoilValue(selectedLoanState);

  return (
    <$Box sx={{ justifyContent: isClosed ? 'space-evenly' : 'space-between' }}>
      <DataRow label='Status' data={<Status status={accountStatus} size='medium' />} />
      {Object.keys(data).map((key) => (
        <DataRow label={key} data={data[key]} key={key} />
      ))}
    </$Box>
  );
};
