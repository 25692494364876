/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { SkeletonLoader } from 'components/SkeletonLoader';
import { Box, useMediaQuery } from '@mui/material';
import { useElementSize } from 'usehooks-ts';
import { Theme } from 'theme';

export const AccountOverviewSkeleton = () => {
  const isDesktop = useMediaQuery(Theme.breakpoints.only('lg'));
  const [ref, { width }] = useElementSize();
  const buttonWidth = Math.min(width, 320);
  const desktopButtonWidth = 200;

  return (
    <Box ref={ref}>
      <SkeletonLoader>
        <rect x={isDesktop ? '0' : width / 2 - 39} y='0' rx='16.5' ry='16.5' width='78' height='33' />
        <rect x={isDesktop ? '0' : width / 2 - 125} y='45' rx='13.5' ry='13.5' width='250' height='27' />
        <rect
          x={isDesktop ? width - desktopButtonWidth : width / 2 - buttonWidth / 2}
          y={isDesktop ? 17 : 104}
          rx='20.5'
          ry='20.5'
          width={isDesktop ? desktopButtonWidth : buttonWidth}
          height='41'
        />
      </SkeletonLoader>
    </Box>
  );
};
