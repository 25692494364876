/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { DataRow } from 'components/DataRow';
import { dateTo } from 'helpers/stringHelpers';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import { activeScheduledPayments } from 'store/PaymentStore';

type PaymentDateRowPropsT = {
  AutopayEnabled: boolean;
  PaymentDueDate: string;
  BorrowerDrawnDown: boolean;
};

export const ScheduledPaymentDataRow = ({
  AutopayEnabled,
  PaymentDueDate,
  BorrowerDrawnDown,
}: PaymentDateRowPropsT) => {
  const scheduledPaymentsList = useRecoilValue(activeScheduledPayments);
  const scheduledPaymentsFiltered = scheduledPaymentsList.filter((p) =>
    dayjs(p.effectiveDate).isBefore(PaymentDueDate),
  );
  const displayDueDate = BorrowerDrawnDown ? dateTo('short_abbr', PaymentDueDate) : '———';
  const paymentScheduledBeforeDueDate =
    scheduledPaymentsFiltered?.length > 0 ? scheduledPaymentsFiltered[0].effectiveDate.toString() : '';
  return (
    <DataRow
      label={scheduledPaymentsFiltered?.length || AutopayEnabled ? 'Payment Scheduled' : 'Payment Due Date'}
      data={scheduledPaymentsFiltered?.length ? dateTo('short_abbr', paymentScheduledBeforeDueDate) : displayDueDate}
    />
  );
};
