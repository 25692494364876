/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled } from '@mui/material';
import { TextH35 } from 'components/Typographies';

const $NoPaymentHistory = styled(TextH35)({
  margin: '8px 0 32px 0',
  lineHeight: '28px',
  color: '#1a1818',
});

export const NoPaymentHistory = () => <$NoPaymentHistory>You have no payments.</$NoPaymentHistory>;
