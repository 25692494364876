/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled } from '@mui/material';
import PastDueIcon from 'assets/late_payment_logo.svg';

const $LatePaymentIcon = styled('img')(({ theme }) => ({
  marginRight: '12px',
  [theme.breakpoints.down('lg')]: {
    height: '22px',
    width: '22px',
  },
}));

export const LatePaymentIcon = () => <$LatePaymentIcon src={PastDueIcon} alt='late_payment_icon' />;
