// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import ContentLoader, { type IContentLoaderProps } from 'react-content-loader';
import { Theme } from 'theme';

export const SkeletonLoader = ({ children, width = '100%', height = '100%', ...props }: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    backgroundColor={Theme.palette.secondary.main}
    foregroundColor='#ededed'
    {...props}
  >
    {children}
  </ContentLoader>
);
