/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const { persistAtom: persistedPastDueBannerDismissed } = recoilPersist({
  key: 'persistedPastDueBannerDismissed',
  storage: sessionStorage,
});

export const { persistAtom: persistedAutopayBannerDismissed } = recoilPersist({
  key: 'persistedAutopayBannerDismissed',
  storage: sessionStorage,
});

export const pastDueBannerDismissed = atom<boolean>({
  key: 'pastDueBannerDismissed',
  default: false,
  effects_UNSTABLE: [persistedPastDueBannerDismissed],
});

export const showingPastDueBanner = atom<boolean>({
  key: 'showingPastDueBanner',
  default: false,
});

export const autopayBannerDismissed = atom<boolean>({
  key: 'autopayBannerDismissed',
  default: false,
  effects_UNSTABLE: [persistedAutopayBannerDismissed],
});

export const showingAutopayBanner = atom<boolean>({
  key: 'showingAutopayBanner',
  default: false,
});

export const showingAutopayThankYouBanner = atom<boolean>({
  key: 'showingAutopayThankYouBanner',
  default: false,
});
