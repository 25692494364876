/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { useState } from 'react';
import { Box, Collapse, Divider, Grid } from '@mui/material';
import { ExpandMore } from 'components/ExpandMore';
import ExpandMoreIcon from '@mui/icons-material/ArrowDropDown';
import { PayWithCheckTitle } from './PayWithCheckTItle';
import { PayWithCheckDetails } from './PayWithCheckDetails';

export const PayWithCheckCollapse = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box maxWidth='500px'>
      <Grid container flexDirection='row' onClick={() => setExpanded(!expanded)}>
        <PayWithCheckTitle />
        <ExpandMore expand={expanded}>
          <ExpandMoreIcon />
        </ExpandMore>
      </Grid>
      <Collapse in={expanded}>
        <Divider />
        <PayWithCheckDetails />
      </Collapse>
    </Box>
  );
};
