/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { Footer } from 'components/Footer/Footer';
import { TagResponseHeader } from 'components/Header/TagResponseHeader';
import { $Container, $ContentBox, $ContentHeadline, $ContentSubtext } from './TagAccepted';

export const TagRejected = () => (
  <$Container>
    <TagResponseHeader />
    <$ContentBox>
      <$ContentHeadline>Got it!</$ContentHeadline>
      <$ContentSubtext>No charges will be made against your loan.</$ContentSubtext>
    </$ContentBox>
    <Footer />
  </$Container>
);
