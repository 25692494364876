/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import dayjs, { type Dayjs } from 'dayjs';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import type { MuiPickersAdapter } from '@mui/x-date-pickers/internals';
import { useRecoilValue } from 'recoil';
import { lastBillingCycleDate } from 'store/LoanStore';
import { SchedulePaymentDay } from './SchedulePaymentDay';
import CustomDateAdapter from './CustomDateAdapter';

type SchedulePaymentCalendarProps = {
  selectedDate: Dayjs;
  onSelectedDateChange: (_: Dayjs) => void;
  dueDate: Dayjs;
};

export const SchedulePaymentCalendar = ({
  selectedDate,
  onSelectedDateChange,
  dueDate,
}: SchedulePaymentCalendarProps) => {
  const today = dayjs();
  const lastDayToPay = useRecoilValue(lastBillingCycleDate);

  return (
    <LocalizationProvider
      dateAdapter={CustomDateAdapter as unknown as new (...props: any) => MuiPickersAdapter<unknown>}
    >
      <StaticDatePicker
        disableHighlightToday
        displayStaticWrapperAs='desktop'
        views={['day']}
        minDate={today}
        maxDate={lastDayToPay}
        value={selectedDate}
        onChange={(newValue) => {
          onSelectedDateChange(newValue ?? today);
        }}
        renderInput={(params) => <TextField {...params} />}
        renderDay={(day, _value, DayComponentProps) => (
          <SchedulePaymentDay
            key={day.toString()}
            day={day}
            dayComponentProps={DayComponentProps}
            selectedDate={selectedDate}
            dueDate={dueDate}
          />
        )}
      />
    </LocalizationProvider>
  );
};
