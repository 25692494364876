// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { DialogActions, styled } from '@mui/material';
import { BlueButton } from 'components/Buttons';
import { VSpacer } from 'components/VSpacer';
import { useAuth } from 'hooks/useAuth';
import type { SessionExtensionModalPropsT } from './SessionExtensionModalProps';

const $DialogActions = styled(DialogActions)(() => ({
  justifyContent: 'center',
  padding: '0px',
}));

const $StayLoggedInButton = styled(BlueButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
  fontSize: '16px',
  lineHeight: '28px',
  letterSpacing: '0.38px',
  marginTop: '32px',
  padding: '0px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const $LogoutButton = styled(BlueButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  marginBottom: '56px',
  padding: '0px',
  border: 'solid 1px',
  borderColor: theme.palette.primary.main,
  borderRadius: '21px',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const SessionDialogActions = (props: SessionExtensionModalPropsT) => {
  const { logout } = useAuth();

  const closeModal = () => {
    props.onCloseModal();
  };

  const logoutSubmit = () => {
    logout();
  };

  return (
    <>
      <$DialogActions>
        <$StayLoggedInButton data-testid='more-time' color='primary' onClick={closeModal}>
          I&#39;m still here
        </$StayLoggedInButton>
      </$DialogActions>
      <VSpacer spacing={2.5} />
      <$DialogActions>
        <$LogoutButton data-testid='logout' color='primary' onClick={logoutSubmit}>
          Sign Out
        </$LogoutButton>
      </$DialogActions>
    </>
  );
};
