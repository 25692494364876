/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled, Divider } from '@mui/material';
import { DocumentDownloadButton } from './DocumentDownloadButton';
import { DocumentTitle } from './DocumentTitle';

const $Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export type BoxPropsT = { subHeader?: string };

const $Row = styled(Box)<BoxPropsT>(({ subHeader }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  height: subHeader ? '60px' : '39px',
}));

type DivPropsT = { subHeader?: string };
const $Divider = styled(Divider)<DivPropsT>(({ subHeader }) => ({
  margin: subHeader ? '21px 0 22px 0' : '31px 0',
  width: '100%',
}));

export type DocumentRowPropsT = {
  header: string;
  subHeader?: string;
  showDivider?: boolean;
  onDownload?: () => void;
  onOpen?: () => void;
};

export const DocumentRow = ({ header, subHeader, showDivider = true, onDownload, onOpen }: DocumentRowPropsT) => (
  <$Container>
    <$Row subHeader={subHeader}>
      <DocumentTitle header={header} subHeader={subHeader} onOpen={onOpen} />
      <DocumentDownloadButton onDownload={onDownload} />
    </$Row>
    {showDivider && <$Divider subHeader={subHeader} />}
  </$Container>
);
