/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Divider, Grid, Paper, styled } from '@mui/material';
import { PayWithCheckTitle } from './PayWithCheckTItle';
import { PayWithCheckDetails } from './PayWithCheckDetails';

const $Item = styled(Paper)(({ theme }) => ({
  padding: '24px 32px 16px 32px',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    padding: '24px',
  },
}));

const $PayByCheckBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
  '& > h2': {
    color: theme.palette.secondary.contrastText,
  },
}));

export const PayWithCheckCard = () => (
  <Grid item component={Box} lg={7} sx={{ display: { lg: 'block', xs: 'none' } }}>
    <$Item>
      <$PayByCheckBox>
        <PayWithCheckTitle />
        <Divider sx={{ margin: '24px 0px 16px 0px' }} />
        <PayWithCheckDetails />
      </$PayByCheckBox>
    </$Item>
  </Grid>
);
