/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Grid } from '@mui/material';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isFeatureOn } from 'store/FeatureFlagStore';
import { PaymentHistory } from './PaymentHistory/PaymentHistory';
import { PaymentHistorySkeleton } from './PaymentHistory/PaymentHistorySkeleton';
import { PaymentMethod } from './PaymentMethod';
import { PaymentMethodSkeleton } from './PaymentMethodSkeleton';
import { PaymentSnapshot } from './PaymentSnapshot/PaymentSnapshot';
import { SnapshotSkeleton } from './PaymentSnapshot/SnapshotSkeleton';
import { PayWithCheckCard } from './PayWithCheck/PayWithCheckCard';
import { PayWithCheckSkeleton } from './PayWithCheck/PayWithCheckSkeleton';

export const Payments = () => {
  const scheduledPaymentFF = useRecoilValue(isFeatureOn('SCHEDULE_PAYMENT_FLAG'));

  return (
    <Grid container spacing={2.5} gridTemplateColumns='repeat(4, 1fr)'>
      <Suspense fallback={<SnapshotSkeleton />}>
        <PaymentSnapshot />
      </Suspense>
      <Suspense fallback={<PaymentMethodSkeleton />}>
        <PaymentMethod />
      </Suspense>
      <Suspense fallback={<PayWithCheckSkeleton />}>
        <PayWithCheckCard />
      </Suspense>
      {scheduledPaymentFF && (
        <Suspense fallback={<PaymentHistorySkeleton />}>
          <PaymentHistory />
        </Suspense>
      )}
      <Suspense>
        <Outlet />
      </Suspense>
    </Grid>
  );
};
