/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import type { FormRules } from 'helpers/formRulesHelpers';
import { stateNames } from '../States';

export const required = { value: true, message: 'Field cannot be left blank.' };
const ROUTING_NUMBER_LENGTH = 9;
const ROUTING_9 = `Routing number must be ${ROUTING_NUMBER_LENGTH} digits.`;

const minLength = (min: number) => ({ value: min, message: `Field must be at least ${min} characters.` });
const pattern = (regex: RegExp, name: string) => ({ value: regex, message: `Please enter a valid ${name}.` });

const namePattern = /^[a-z ,.'_-]+$/i;
const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
const phoneNumberPattern = /^\(?([2-9]\d{2})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;

function isValidRoutingNumber(routingNumber: string) {
  if (routingNumber.length !== ROUTING_NUMBER_LENGTH || Number.isNaN(routingNumber)) {
    return false;
  }

  const digits = routingNumber.split('').map(Number);

  const checksumTotal =
    3 * (digits[0] + digits[3] + digits[6]) + // NOSONAR
    7 * (digits[1] + digits[4] + digits[7]) + // NOSONAR
    1 * (digits[2] + digits[5] + digits[8]); // NOSONAR

  return checksumTotal !== 0 && checksumTotal % 10 === 0; // NOSONAR
}

function hasWhitespace(routingNumber: string) {
  return /\s/.test(routingNumber);
}

export const nameValidation = (nameType: string): FormRules => ({
  required,
  minLength: minLength(2), // NOSONAR
  pattern: pattern(namePattern, `${nameType}`),
  validate: (value: string) => value?.trim() !== '' || `Please enter a valid ${nameType}.`,
});

export const emailValidation: FormRules = {
  required,
  pattern: pattern(emailPattern, 'email address'),
};

export const phoneNumberValidation: FormRules = {
  required,
  pattern: pattern(phoneNumberPattern, 'mobile phone number'),
};

export const stateValidation: FormRules = {
  required,
  validate: (value: string) => stateNames.includes(value) || 'Please enter a valid state.',
};

export const routingNumberValidation: FormRules = {
  required: { value: true, message: 'This field is required.' },
  minLength: { value: ROUTING_NUMBER_LENGTH, message: ROUTING_9 },
  maxLength: { value: ROUTING_NUMBER_LENGTH, message: ROUTING_9 },
  validate: {
    invalidRoutingNumber: (value: string) => isValidRoutingNumber(value) || 'Invalid routing number.',
    noWhiteSpaceValidator: (value: string) => !hasWhitespace(value) || 'No Spaces Allowed',
  },
};
