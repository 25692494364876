/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled } from '@mui/material';
import MuiPrintIcon from '@mui/icons-material/Print';
import MuiEmailRounded from '@mui/icons-material/EmailRounded';

export const PrintIcon = styled(MuiPrintIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
  padding: '8px',
  width: '40px',
  height: '40px',
}));

export const EmailIcon = styled(MuiEmailRounded)(({ theme }) => ({
  color: theme.palette.info.main,
  backgroundColor: theme.palette.secondary.light,
  padding: '8px',
  width: '40px',
  height: '40px',
  borderRadius: '16px',
}));
