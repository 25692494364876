/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Grid, Box, styled, Divider, Paper } from '@mui/material';
import { SkeletonLoader } from 'components/SkeletonLoader';
import { PayWithCheckTitle } from './PayWithCheckTItle';

const $Item = styled(Paper)({
  padding: '32px',
  height: '100%',
});

const $PayByCheckBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
  height: '100%',
});

export const PayWithCheckSkeleton = () => (
  <Grid item component={Box} lg={7} sx={{ display: { lg: 'block', xs: 'none' } }}>
    <$Item>
      <$PayByCheckBox>
        <PayWithCheckTitle />
        <Divider />
        <SkeletonLoader>
          <rect x='0' y='0' rx='10' ry='10' width='375' height='20' />
          <rect x='0' y='30' rx='10' ry='10' width='391' height='20' />
          <rect x='0' y='60' rx='10' ry='10' width='108' height='20' />
          <rect x='100.5' y='106' rx='16' ry='16' width='50' height='50' />
          <rect x='174' y='90' rx='9.5' ry='9.5' width='187' height='20' />
          <rect x='174' y='120' rx='7.5' ry='7.5' width='130' height='20' />
          <rect x='174' y='150' rx='7.5' ry='7.5' width='213' height='20' />
          <rect x='0' y='188' rx='7.5' ry='7.5' width='487' height='20' />
          <rect x='0' y='210' rx='7.5' ry='7.5' width='100' height='20' />
        </SkeletonLoader>
      </$PayByCheckBox>
    </$Item>
  </Grid>
);
