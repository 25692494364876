/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { ActionLink } from 'components/ActionLink';
import { TextSubtitle1 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import { Typography } from '@mui/material';
import ClockIcon from 'assets/ClockFull.svg';
import type { Payment } from 'store/PaymentStore';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { dateTo } from 'helpers/stringHelpers';
import { useNavigate } from 'react-router-dom';

type OneTimeScheduledPaymentDetailsPropsT = {
  scheduledPayment: Payment;
};

export const OneTimeScheduledPaymentDetails = ({ scheduledPayment }: OneTimeScheduledPaymentDetailsPropsT) => {
  const { getParentRoute } = useNavigationHelpers();
  const navigate = useNavigate();

  const openPaymentDetailsFlyout = () => {
    const parentRoute = getParentRoute();
    navigate(`${parentRoute || window.location.pathname}/paymentdetails`, {
      state: {
        paymentDate: scheduledPayment.effectiveDate,
        status: scheduledPayment.status,
        amountInCents: scheduledPayment.amountInCents,
        paymentId: scheduledPayment.paymentId,
        displayAccountNumber:
          scheduledPayment.maskedAchPaymentDetails?.bankAccountNumber === undefined
            ? 'Account on file'
            : `Account *${scheduledPayment.maskedAchPaymentDetails?.bankAccountNumber?.slice(-4)}`,
      },
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={ClockIcon} alt='clock_icon' style={{ marginRight: '10px', width: '15px', height: '15px' }} />
        <TextSubtitle1>
          {`Payment of ${toCurrencyFromCents(scheduledPayment.amountInCents)} scheduled for ${dateTo(
            'really_short_abbr',
            scheduledPayment.effectiveDate.toString(),
          )}.`}
        </TextSubtitle1>
      </div>
      <VSpacer spacing={0.5} />
      <div style={{ display: 'flex' }}>
        <ActionLink
          onClick={openPaymentDetailsFlyout}
          style={{ width: '156px', textAlign: 'left' }}
          data-testId='see-details-button'
        >
          <Typography sx={(theme) => ({ fontSize: '12px', color: theme.palette.primary.light })}>
            See More Details
          </Typography>
        </ActionLink>
      </div>
    </div>
  );
};
