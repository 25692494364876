/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { Footer } from 'components/Footer/Footer';
import { TagResponseHeader } from 'components/Header/TagResponseHeader';
import { useLocation } from 'react-router-dom';
import { $Container, $ContentBox, $ContentHeadline, $ContentSubtext } from './TagAccepted';

export const TagInvalid = () => {
  const location = useLocation();
  const state = location.state as Record<string, unknown>;
  const merchant = state ? (state.merchant as string) : '';

  return (
    <$Container>
      <TagResponseHeader />
      <$ContentBox>
        <$ContentHeadline>Sorry!</$ContentHeadline>
        <$ContentSubtext>
          {`You have responded to a message that is no longer valid. If you have any questions, please contact ${merchant}.`}
        </$ContentSubtext>
      </$ContentBox>
      <Footer />
    </$Container>
  );
};
