/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { BlueButton } from 'components/Buttons';
import LoadingText from 'components/LoadingText';
import { VSpacer } from 'components/VSpacer';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { TextSubtitle2 } from 'components/Typographies';
import { Divider } from '@mui/material';
import { isFeatureOn } from 'store/FeatureFlagStore';
import { activeScheduledPayments } from 'store/PaymentStore';
import type { PaymentAccountT } from '../PaymentAccountModel';
import { PaymentType } from '../PaymentAccountModel';
import { SchedulePayment } from '../SchedulePayment/SchedulePayment';
import { OneTimePaymentFormExisting } from './OneTimePaymentFormExisting';
import { OneTimePaymentFormNew } from './OneTimePaymentFormNew';
import { PaymentEnterAmount } from './PaymentEnterAmount';
import { OneTimeScheduledPaymentDetails } from './OneTimeScheduledPaymentDetails';

type OneTimePaymentFormPropsT = {
  onSubmit: (data: PaymentAccountT) => void;
  data: PaymentAccountT;
};

export const OneTimePaymentForm = ({ onSubmit, data }: OneTimePaymentFormPropsT) => {
  const {
    paymentInformation: {
      bankAccountNumber,
      bankAccountType,
      bankRoutingNumber,
      paymentDueInCents,
      paymentToBringAccountCurrentCents,
    },
  } = useRecoilValue(selectedLoanState);

  const scheduledPaymentState = useRecoilValue(activeScheduledPayments);

  const scheduledPaymentFF = useRecoilValue(isFeatureOn('SCHEDULE_PAYMENT_FLAG'));

  const [useExisting, setUseExisting] = useState(!!bankAccountNumber);

  const form = useForm<PaymentAccountT>({
    mode: 'all',
    defaultValues: {
      accountNumber: '',
      accountNumberConfirm: '',
      routingNumber: '',
      accountType: undefined,
      saveAsPrimary: false,
      ...data,
    },
  });

  const handleEditExisting = () => {
    setUseExisting(!useExisting);
  };

  const onSuccessfulSubmit = (data: PaymentAccountT) => {
    if (data.paymentType === PaymentType.CurrentAmount) {
      data.paymentAmount = paymentToBringAccountCurrentCents / 100; // NOSONAR
    } else if (data.paymentType === PaymentType.TotalAmount) {
      data.paymentAmount = paymentDueInCents / 100; // NOSONAR
    } else {
      // keep paymentAmount the same
    }

    onSubmit(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSuccessfulSubmit)} style={{ textAlign: 'center' }} data-testid='onetime-form'>
        <PaymentEnterAmount
          control={form.control}
          isAccountPastDue={paymentToBringAccountCurrentCents > 0}
          totalAmountDueCents={paymentDueInCents}
          paymentToBringAccountCurrentCents={paymentToBringAccountCurrentCents}
        />
        <VSpacer spacing={3} />

        {scheduledPaymentFF && scheduledPaymentState.length === 0 && (
          <SchedulePayment control={form.control} previouslySelectedDate={data.scheduledDate} />
        )}
        {scheduledPaymentFF && scheduledPaymentState.length > 0 && (
          <OneTimeScheduledPaymentDetails scheduledPayment={scheduledPaymentState[0]} />
        )}
        <VSpacer spacing={4} />
        {useExisting ? (
          <OneTimePaymentFormExisting
            accountNumber={bankAccountNumber}
            routingNumber={bankRoutingNumber}
            accountType={bankAccountType}
            onEdit={handleEditExisting}
          />
        ) : (
          <OneTimePaymentFormNew control={form.control} getValues={form.getValues} onCancel={handleEditExisting} />
        )}
        <Divider sx={{ margin: '32px 0' }} />
        <TextSubtitle2 textAlign='left'>
          Payments in excess of the amount due or payments made before the statement cycle date will be applied to
          principal balance and may not count towards the amount due for the following statement cycle.
        </TextSubtitle2>
        <VSpacer spacing={5} />
        <BlueButton type='submit' disabled={!form.formState.isValid}>
          <LoadingText text='Review Payment' loading={form.formState.isSubmitting} data-testid='LoadingText' />
        </BlueButton>
      </form>
    </FormProvider>
  );
};
