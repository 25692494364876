/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { VSpacer } from 'components/VSpacer';
import { useRecoilValue } from 'recoil';
import { Statuses } from 'Status';
import { filteredLoansSelector, openLoansSelector } from 'store/LoanStore';
import { DashboardAccountList } from './DashboardAccountList';

export const DashboardContent = () => {
  const openLoans = useRecoilValue(openLoansSelector);
  const closedLoans = useRecoilValue(filteredLoansSelector(Statuses.CLOSED));
  return (
    <>
      <DashboardAccountList loans={openLoans} type='Accounts' />
      <VSpacer spacing={7} hidden={openLoans.length === 0 || closedLoans.length === 0} />
      <DashboardAccountList loans={closedLoans} type='Closed Accounts' />
    </>
  );
};
