/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import type { Control } from 'react-hook-form';
import type { PaymentAccountT } from '../PaymentAccountModel';
import { PaymentEnterAmountCurrent } from './PaymentEnterAmountCurrent';
import { PaymentEnterAmountDelinquent } from './PaymentEnterAmountDelinquent';

type PaymentEnterAmountPropsT = {
  control: Control<PaymentAccountT>;
  isAccountPastDue: boolean;
  totalAmountDueCents: number;
  paymentToBringAccountCurrentCents: number;
};

export const PaymentEnterAmount = ({
  control,
  isAccountPastDue,
  totalAmountDueCents,
  paymentToBringAccountCurrentCents,
}: PaymentEnterAmountPropsT) => (
  <>
    {isAccountPastDue ? (
      <PaymentEnterAmountDelinquent
        control={control}
        totalAmountDueCents={totalAmountDueCents}
        paymentToBringAccountCurrentCents={paymentToBringAccountCurrentCents}
      />
    ) : (
      <PaymentEnterAmountCurrent control={control} totalAmountDueCents={totalAmountDueCents} />
    )}
  </>
);
