/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { styled } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { showingAutopayThankYouBanner } from 'store/BannerStore';
import AutopayBanner from 'assets/autopayBanner.svg';
import { $ThinBanner } from '../PaymentProcessingBanner';

type StylingPropsT = {
  fadeOut: boolean;
};

const threeSecondTimer = 3000;

const $AutopayOnBanner = styled($ThinBanner)(({ fadeOut }: StylingPropsT) => ({
  backgroundColor: 'rgba(5, 151, 242, 0.96)',
  visibility: fadeOut ? 'visible' : 'hidden',
  opacity: fadeOut ? '1' : '0',
  transition: fadeOut ? '' : 'visibility 0s 2s, opacity 2s linear',
}));

export const AutopayOnBanner = () => {
  const [bannerEnabled, setAutopayThankYouBanner] = useRecoilState(showingAutopayThankYouBanner);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (bannerEnabled) {
        setAutopayThankYouBanner(false);
      }
    }, threeSecondTimer);
    return () => clearTimeout(timer);
  }, [bannerEnabled]);

  return (
    <>
      {bannerEnabled && (
        <$AutopayOnBanner data-testid='autopayOnBanner' fadeOut={bannerEnabled}>
          <img src={AutopayBanner} alt='autopay_icon' style={{ marginRight: '12px' }} />
          Autopay is on! Thank you for enrolling in automatic payments.
        </$AutopayOnBanner>
      )}
    </>
  );
};
