/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { useState, type MouseEvent, type ReactNode } from 'react';
import { IconButton, ClickAwayListener, Box } from '@mui/material';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { Avatar } from 'components/Avatar';
import { ExpandMore } from 'components/ExpandMore';
import { CustomPopper } from '../Popper';

export type AvatarPopupPropsT = {
  initials: string;
  children?: ReactNode;
};

export const AvatarPopup = ({ children = undefined, initials }: AvatarPopupPropsT) => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | undefined>(undefined);

  const handleClickAway = () => {
    if (anchorElement) {
      setAnchorElement(undefined);
    }
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLButtonElement>) => {
    if (anchorElement) {
      setAnchorElement(undefined);
    } else {
      setAnchorElement(event.currentTarget);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <IconButton onClick={handleOpenUserMenu}>
          <Avatar initials={initials} />
          <ExpandMore expand={Boolean(anchorElement)}>
            <KeyboardArrowDownSharpIcon />
          </ExpandMore>
        </IconButton>
        <CustomPopper anchorElement={anchorElement}>{children}</CustomPopper>
      </Box>
    </ClickAwayListener>
  );
};
