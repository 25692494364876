/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import endpointConfig from 'config/endpointConfig';
import { axios } from 'helpers/axiosInstance';
import { selector, selectorFamily } from 'recoil';
import type { TransactionStatusesT } from 'TransactionStatus';
import type { TransactionTypeT } from 'TransactionType';
import { loansRequestId, selectedLoanCodeState } from './LoanStore';

type LineItemOverview = {
  lineItemStatus: string;
  lineItemType: string;
  lineItemDescription: string;
};

type LineItemSummary = {
  originalAmountCents: number;
  balanceCents: number;
  principalCents: number;
  interestBalanceCents: number;
  amInterestBalanceCents: number;
  deferredInterestBalanceCents: number;
  amDeferredInterestBalanceCents: number;
  totalInterestPaidToDateBalanceCents: number;
};

type ExternalField = {
  key: string;
  value: string;
};

export type Transaction = {
  accountId: string;
  lineItemId: string;
  lineItemClientId: string;
  effectiveAt: string;
  createdAt: string;
  productId: string;
  lineItemOverview: LineItemOverview;
  lineItemSummary: LineItemSummary;
  externalFields: ExternalField[];
};

export type LoanTransactions = {
  loanCode: string;
  transactions: Transaction[];
};

const defaultLoanTransactions: LoanTransactions[] = [
  {
    loanCode: '',
    transactions: [
      {
        accountId: '',
        lineItemId: '',
        lineItemClientId: '',
        effectiveAt: '',
        createdAt: '',
        productId: '',
        lineItemOverview: {
          lineItemStatus: '',
          lineItemType: '',
          lineItemDescription: '',
        },
        lineItemSummary: {
          originalAmountCents: 0,
          balanceCents: 0,
          principalCents: 0,
          interestBalanceCents: 0,
          amInterestBalanceCents: 0,
          deferredInterestBalanceCents: 0,
          amDeferredInterestBalanceCents: 0,
          totalInterestPaidToDateBalanceCents: 0,
        },
        externalFields: [
          {
            key: '',
            value: '',
          },
        ],
      },
    ],
  },
];

export const loanTransactionsState = selector<LoanTransactions[]>({
  key: 'transactions',
  async get({ get }) {
    get(loansRequestId);
    const config = endpointConfig.BORROWER_PORTAL.GET_TRANSACTIONS;
    const { data } = await axios<LoanTransactions[]>(config);
    return data ?? defaultLoanTransactions;
  },
});

// Returns loan transactions for current selected loan
export const selectedLoanTransactionsState = selector<LoanTransactions>({
  key: 'selectedLoanTransactionsState',
  get({ get }) {
    const loanTransactions = get(loanTransactionsState);
    const selectedLoanTransactions = loanTransactions?.find((l) => l.loanCode === get(selectedLoanCodeState));
    return selectedLoanTransactions ?? defaultLoanTransactions[0];
  },
});

export type FilterTransactionsT = {
  loanCodes?: string[];
  statuses?: Array<keyof TransactionStatusesT>;
  types?: Array<keyof TransactionTypeT>;
};

//  Takes in an object with optional arrays for loanCodes, statuses and types.
//  Filters out transactions that don't match at least one of the loanCodes, one of the statuses, and one of the types inputted.
//  If any of the optional arrays are undefined it doesn't filter by that property.
export const filteredTransactionSelector = selectorFamily<LoanTransactions[], FilterTransactionsT>({
  key: 'TransactionFilters',
  get:
    (props) =>
    ({ get }) => {
      const loanTransactions = get(loanTransactionsState);
      return transactionFilter(loanTransactions, props.loanCodes, props.statuses, props.types);
    },
});

const transactionFilter = (
  loanTransactions: LoanTransactions[],
  loanCodeFilter?: string[],
  statusFilter?: Array<keyof TransactionStatusesT>,
  typeFilter?: Array<keyof TransactionTypeT>,
) => {
  const result: LoanTransactions[] = [];
  for (const loan of loanTransactions) {
    if (loanCodeFilter === undefined || loanCodeFilter.includes(loan.loanCode)) {
      const filteredTransactions = loan.transactions.filter(
        (t) =>
          (statusFilter === undefined ||
            statusFilter.includes(t.lineItemOverview.lineItemStatus as keyof TransactionStatusesT)) &&
          (typeFilter === undefined || typeFilter.includes(t.lineItemOverview.lineItemType as keyof TransactionTypeT)),
      );

      if (filteredTransactions.length > 0) {
        const filteredLoanTransactions: LoanTransactions = {
          loanCode: loan.loanCode,
          transactions: filteredTransactions,
        };
        result.push(filteredLoanTransactions);
      }
    }
  }

  return result;
};
