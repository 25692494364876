// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { Outlet } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'components/ToastComponents/Toaster';
import { RecoilRoot } from 'recoil';
import { ClientSideNavigation } from 'router/ClientSideNavigation';
import { Theme } from 'theme';
import { startMsal } from 'utils/msal';
import { useAxiosSetup } from 'hooks/useAxiosSetup';
import { FeatureFlagLoader } from 'components/FeatureFlagLoader';

export const msalInstance = startMsal();

const App = ({ children }: { children?: React.ReactNode }) => {
  const { axiosSetup } = useAxiosSetup();
  axiosSetup(msalInstance);

  return (
    <ClientSideNavigation pca={msalInstance}>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={Theme}>
          <CssBaseline />
          <RecoilRoot>
            <FeatureFlagLoader>
              {children ?? <Outlet />}
              <Toaster />
            </FeatureFlagLoader>
          </RecoilRoot>
        </ThemeProvider>
      </MsalProvider>
    </ClientSideNavigation>
  );
};

export type AppType = typeof App;

export default App;
