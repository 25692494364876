/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Divider, Grid, Paper, styled, type GridSize } from '@mui/material';
import { TextH3 } from 'components/Typographies';
import type { ReactNode } from 'react';

const $Item = styled(Paper)(({ theme }) => ({
  padding: '32px',
  [theme.breakpoints.down('md')]: {
    padding: '24px',
  },
  [theme.breakpoints.only('md')]: {
    padding: '32px 64px',
  },
}));

const $Box = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& > h3': {
    color: theme.palette.primary.main,
  },
}));

const $Divider = styled(Divider)(({ theme }) => ({
  width: '78px',
  borderWidth: '1.5px',
  borderColor: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.main,
  margin: '16px 0 24px 0',
}));

export type SectionBoxPropsT = {
  header: string;
  children?: ReactNode;
  xs?: GridSize | boolean;
  sm?: GridSize | boolean;
  md?: GridSize | boolean;
  lg?: GridSize | boolean;
};

export const SectionBox = ({ header, children, xs, sm, md, lg, ...rest }: SectionBoxPropsT) => (
  <Grid {...rest} item xs={xs} sm={sm} md={md} lg={lg}>
    <$Item>
      <$Box>
        <TextH3>{header}</TextH3>
        <$Divider />
        {children}
      </$Box>
    </$Item>
  </Grid>
);

SectionBox.defaultProps = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
};
