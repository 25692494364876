/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import type { ReactNode } from 'react';
import { Box, type BoxProps as MuiBoxProps, styled, Divider } from '@mui/material';
import { InfoTooltip } from 'components/InfoTooltip';
import { VSpacer } from './VSpacer';

const $label = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.secondary.dark,
  ...theme.typography.h3_5,
}));

const $Data = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.common.black,
  ...theme.typography.data4,
}));

export type DataCellPropsT = MuiBoxProps & {
  label: ReactNode;
  data: ReactNode | ReactNode[];
  spacing?: number;
  toolTip?: ReactNode;
  divider?: ReactNode;
  noDivider?: boolean;
};

export const DataCell = ({ label, data, spacing, toolTip, divider, noDivider, ...rest }: DataCellPropsT) => (
  <Box data-testid={label} {...rest}>
    <$label>
      {label}
      {toolTip && <InfoTooltip toolTip={toolTip} />}
    </$label>
    <VSpacer spacing={spacing} />
    <$Data>{data}</$Data>
    {!noDivider && (divider ?? <Divider />)}
  </Box>
);

DataCell.defaultProps = {
  toolTip: null,
  divider: null,
  noDivider: false,
  spacing: 1,
};
