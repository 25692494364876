/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { TextH2, TextSubtitle1 } from 'components/Typographies';
import type { ReactNode } from 'react';

type TitleSubTitlePropsT = {
  title: ReactNode;
  subtitle?: ReactNode;
};

export const TitleSubTitle = ({ title, subtitle }: TitleSubTitlePropsT) => (
  <>
    <TextH2 sx={{ textAlign: 'center', marginBottom: '16px' }}>{title}</TextH2>
    {subtitle && (
      <TextSubtitle1 sx={{ textAlign: 'center', marginBottom: '32px', lineHeight: '24px' }}>{subtitle}</TextSubtitle1>
    )}
  </>
);
