// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { useRecoilValue, useRecoilState } from 'recoil';
import { selectedLoanState, loansRequestId } from 'store/LoanStore';

export const useLoansStateWithRefresh = () => {
  const loan = useRecoilValue(selectedLoanState);
  const [currentRequestId, setCurrentRequestId] = useRecoilState(loansRequestId);

  const refreshLoans = () => setCurrentRequestId(currentRequestId + 1);

  return { loan, refreshLoans };
};
