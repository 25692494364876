/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const $NoDocuments = styled(Typography)(({ theme }) => ({
  margin: '9px 0 34px 0',
  ...theme.typography.h3_5,
}));

export const NoDocuments = () => <$NoDocuments>You have no documents.</$NoDocuments>;
