/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { AsYouType } from 'libphonenumber-js';

export const toSentenceCase = (s?: string) =>
  `${s?.slice(0, 1)?.toUpperCase() ?? ''}${s?.slice(1)?.toLowerCase() ?? ''}`;

export type DateFormatT =
  | 'short_abbr'
  | 'short_abbr_no_year'
  | 'really_short_abbr'
  | 'really_short_abbr_no_year'
  | 'long'
  | 'long_abbr'
  | 'numeric'
  | 'no_separator'
  | 'year';

/**
 * Returns a formatted date as a string.
 * @param format The type of format the date will be in.
 * - short_abbr = Jan 01, 2023
 * - short_abbr_no_year = Jan 01
 * - really_short_abbr = Jan 1, 2023
 * - really_short_abbr_no_year = Jan 1
 * - long = mm/dd/yyyy hh:mm PM
 * - long_abbr = Jan 01, 2023 at 01:00 PM
 * - numeric = mm/dd/yyyy
 * - no_separator = yyyymmdd
 * - year = yyyy
 * @param d The input date as a string.
 */
export const dateTo = (format: DateFormatT, d?: string) => {
  if (!d || Date.parse(d) < jan1st1900) {
    return '';
  }

  switch (format) {
    case 'short_abbr':
      return shortAbbreviationDate(d);
    case 'short_abbr_no_year':
      return shortAbbreviationNoYear(d);
    case 'really_short_abbr':
      return reallyShortAbbreviationDate(d);
    case 'really_short_abbr_no_year':
      return reallyShortAbbreviationNoYear(d);
    case 'long':
      return longDate(d);
    case 'long_abbr':
      return longAbbreviationDate(d);
    case 'numeric':
      return numericDate(d);
    case 'no_separator':
      return noSeparatorDate(d);
    case 'year':
      return fullYear(d);
    default:
      return shortAbbreviationDate(d);
  }
};

const noSeparatorDate = (d: string): string => {
  const date = new Date(d);
  const day = `0${date.getDate()}`.slice(-2); // NOSONAR
  // The extra +1 is because getMonth uses zero-based indexing while getDate does not
  const month = `0${date.getMonth() + 1}`.slice(-2); // NOSONAR
  return `${date.getFullYear()}${month}${day}`;
};

const numericDate = (d: string): string =>
  new Date(d)?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

const shortAbbreviationDate = (d: string): string =>
  new Date(d)?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });

const reallyShortAbbreviationDate = (d: string): string =>
  new Date(d)?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

const shortAbbreviationNoYear = (d: string): string =>
  new Date(d)?.toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
  });

const reallyShortAbbreviationNoYear = (d: string): string =>
  new Date(d)?.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });

const fullYear = (d: string): string => String(new Date(d)?.getFullYear());

const longAbbreviationDate = (d: string) => {
  const [monthday, year, time] = getDateParts(d);
  return `${monthday},${year} at${time}`;
};

const longDate = (d: string) =>
  new Date(d)
    .toLocaleString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(',', '');

const getDateParts = (d: string) =>
  new Date(d)
    .toLocaleString([], {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .split(',');

export const jan1st1900 = -2_208_988_800_000; // C#/mongo min date - often used instead of null

export const toYesOrNo = (flag: boolean) => (flag ? 'Yes' : 'No');

export const toPercentage = (number?: string): string =>
  number ? `${Number(`${Math.round(Number(`${number}e2`))}e-2`)}%` : ''; // NOSONAR

export const parseDigits = (value: string) => (value.match(/\d+/g) ?? []).join('');

export const formatPhone = (value: string): string => {
  let digits = parseDigits(value).slice(0, 11); // NOSONAR
  if (digits.startsWith('1')) {
    digits = digits.slice(1);
  }

  return digits.length > 3 ? new AsYouType('US').input(digits) : digits; // NOSONAR
};

// Helper func for if you want a string list with a different delimited before the last element like "and", "or", or "finally"
export const listToString = (list: string[], delimiter: string, beforeLast: string) => {
  if (list.length === 0) {
    return '';
  }

  if (list.length > 1) {
    return list
      .slice(0, -1)
      .join(delimiter)
      .concat(beforeLast, list[list.length - 1]);
  }

  return list[0];
};
