/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled } from '@mui/material';
import { TextSubtitle2 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';

const $KeyIcon = styled(Box)({
  width: '12px',
  height: '12px',
  flex: '0 0 auto',
  margin: '5px 8px 0 0',
  borderRadius: '50%',
});

export const SchedulePaymentCalendarKey = () => (
  <Box display='flex' flexDirection='column' sx={{ textAlign: 'left' }}>
    <Box display='flex'>
      <$KeyIcon sx={{ backgroundColor: '#abdcfa' }} />
      <TextSubtitle2 sx={{ lineHeight: 1.67 }}>
        Payment Window: payments made during this 10-day window will count towards the amount due.
      </TextSubtitle2>
    </Box>
    <VSpacer spacing={1} />
    <Box display='flex'>
      <$KeyIcon sx={(theme) => ({ border: `solid 2px ${theme.palette.error.main}` })} />
      <TextSubtitle2 sx={{ lineHeight: 1.67 }}>Payment Due Date</TextSubtitle2>
    </Box>
    <VSpacer spacing={1} />
    <Box display='flex'>
      <$KeyIcon sx={(theme) => ({ backgroundColor: theme.palette.primary.light })} />
      <TextSubtitle2 sx={{ lineHeight: 1.67 }}>Selected Payment Date</TextSubtitle2>
    </Box>
    <VSpacer spacing={1} />
    <Box display='flex'>
      <$KeyIcon sx={(theme) => ({ backgroundColor: theme.palette.secondary.main, border: '2px solid #c7d2dc' })} />
      <TextSubtitle2 sx={{ lineHeight: 1.67 }}>
        Outside of Payment Window: payments may only count towards principal balance and not towards amount due for
        billing cycle
      </TextSubtitle2>
    </Box>
  </Box>
);
