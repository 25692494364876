/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Divider } from '@mui/material';
import type { Dayjs } from 'dayjs';
import { SchedulePaymentCalendar } from './SchedulePaymentCalendar';
import { SchedulePaymentCalendarKey } from './SchedulePaymentCalendarKey';

type SchedulePaymentDetailsProps = {
  selectedDate: Dayjs;
  onSelectedDateChange: (_: Dayjs) => void;
  dueDate: Dayjs;
};

export const SchedulePaymentDetails = ({
  selectedDate,
  onSelectedDateChange,
  dueDate,
}: SchedulePaymentDetailsProps) => (
  <Box display='flex' flexDirection='column' alignItems='center'>
    <SchedulePaymentCalendar
      selectedDate={selectedDate}
      onSelectedDateChange={onSelectedDateChange}
      dueDate={dueDate}
    />
    <Box maxWidth='392px' margin='0px 8px'>
      <Divider sx={{ margin: '8px 0px 16px 0px' }} />
      <SchedulePaymentCalendarKey />
    </Box>
  </Box>
);
