// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { Box, styled } from '@mui/material';
import { TextSubtitle1 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';

const $TextSubtitle1 = styled(TextSubtitle1)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  lineHeight: '24px',
  letterSpacing: '.45px',
  textAlign: 'left',
}));

export const AutopaySubmitText = () => (
  <Box textAlign='center'>
    <VSpacer spacing={2} />
    <$TextSubtitle1>
      If you have a promotional deferred interest plan, making minimum payments during the promotional period will not
      satisfy the total amount owed under your loan agreement to qualify for deferred interest.
    </$TextSubtitle1>
    <VSpacer spacing={3} />
    <$TextSubtitle1>
      To avoid paying the Total Deferred Interest Charges, you must pay the total Deferred Interest Purchase Amount in
      full by the Promo Expiration Date.
    </$TextSubtitle1>
  </Box>
);
