/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Accordion } from 'components/Accordion';
import { TextH1 } from 'components/Typographies';
import type { Payment } from 'store/PaymentStore';
import { PaymentRow } from './PaymentRow';

export type PaymentHistoryTabProps = {
  year: string;
  payments: Payment[];
  expandOnLoad: boolean;
};

export const PaymentHistoryTab = ({ year, payments, expandOnLoad }: PaymentHistoryTabProps) => (
  <Accordion
    key={year}
    header={<TextH1>{year}</TextH1>}
    sxSummary={(theme) => ({ backgroundColor: theme.palette.grey[50] })} // NOSONAR
    sxDetails={{ padding: '24px 16px' }}
    onlyCollapseWithArrow
    expandOnLoad={expandOnLoad}
  >
    {payments.map((payment, index) => (
      <PaymentRow
        key={`${year}-${payment.effectiveDate?.toString()}`}
        paymentDate={payment.effectiveDate}
        type={payment.type}
        status={payment.status}
        amountInCents={payment.amountInCents}
        paymentId={payment.paymentId}
        displayAccountNumber={
          payment.maskedAchPaymentDetails?.bankAccountNumber === undefined
            ? 'Account on file'
            : `Account *${payment.maskedAchPaymentDetails?.bankAccountNumber?.slice(-4)}` // NOSONAR
        }
        showDivider={payments.length - 1 !== index}
      />
    ))}
  </Accordion>
);
