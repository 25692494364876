// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { useState, useEffect } from 'react';
import { useNavigate, type NavigateFunction } from 'react-router-dom';
import { type PublicClientApplication, NavigationClient, type NavigationOptions } from '@azure/msal-browser';

type ClientSideNavigationPropsT = {
  pca: PublicClientApplication;
  children: JSX.Element;
};

const navigationClient = (navigate: NavigateFunction) => {
  const nc = new NavigationClient();
  nc.navigateInternal = async (url: string, options: NavigationOptions) => {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      navigate(relativePath, { replace: true });
    } else {
      navigate(relativePath);
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(false);
      }, 0);
    });
  };

  return nc;
};

export const ClientSideNavigation = ({ pca, children }: ClientSideNavigationPropsT) => {
  const [firstRender, setFirstRender] = useState(true);
  const navigate = useNavigate();
  const nc = navigationClient(navigate);
  pca.setNavigationClient(nc);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  return firstRender ? null : children;
};
