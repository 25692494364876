/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { styled } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useToasts } from 'hooks/useToasts';
import type { ToastT } from 'store/ToastStore';
import ClearIcon from 'assets/Cross.svg';

const $MessageBox = styled('div')({
  height: '60px',
  display: 'grid',
  margin: '5px 0',
  gridTemplateColumns: '56px auto 60px',
  borderRadius: '4px',
  alignSelf: 'center',
  boxShadow: '1px 1px 10px 0 rgba(0, 0, 0, 0.15)',
  backgroundColor: '#edf7ed',
  '&.fail': {
    backgroundColor: '#f9ecec',
  },
});

const $ImgContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '56px',
  height: '60px',
  justifyContent: 'center',
  backgroundColor: '#5fb662',
  borderRadius: '4px 0 0 4px',
  '&.fail': {
    backgroundColor: theme.palette.warning.light,
  },
}));

const $MessageArea = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '24px',
  opacity: '0.99',
  justifyContent: 'center',
}));

const $ClearIcon = styled('img')({
  alignSelf: 'center',
  cursor: 'pointer',
  padding: '0 24px',
});

const $CheckIcon = styled(TaskAltIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const $ErrorIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

type ToastPropsT = {
  toast: ToastT;
};

export const Toast = ({ toast }: ToastPropsT) => {
  const { removeToast } = useToasts();
  const icon = toast.status.toLowerCase() === 'success' ? <$CheckIcon /> : <$ErrorIcon />;

  return (
    <$MessageBox className={toast.status.toLowerCase()} key={toast.message} data-testid='toast'>
      <$ImgContainer className={toast.status.toLowerCase()}>{icon}</$ImgContainer>
      <$MessageArea data-testid='message-area'>{toast.message}</$MessageArea>
      <$ClearIcon src={ClearIcon} alt='clearIcon' onClick={() => removeToast(toast.id)} />
    </$MessageBox>
  );
};
