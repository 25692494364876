/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { useRecoilValue } from 'recoil';
import { filteredTransactionSelector } from 'store/TransactionStore';
import { TransactionStatuses } from 'TransactionStatus';
import { TransactionType } from 'TransactionType';

const filterProcessingPayments = {
  statuses: [TransactionStatuses.PENDING, TransactionStatuses.PROCESSING],
  types: [TransactionType.PAYMENT],
};

export const useTransactionHelpers = () => {
  function getProcessingPayments(loanCode?: string) {
    return loanCode
      ? useRecoilValue(filteredTransactionSelector({ ...filterProcessingPayments, loanCodes: [loanCode] }))
      : useRecoilValue(filteredTransactionSelector(filterProcessingPayments));
  }

  return { getProcessingPayments };
};
