/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { SkeletonLoader } from 'components/SkeletonLoader';
import { styled, Box, Divider } from '@mui/material';
import { useElementSize } from 'usehooks-ts';
import { Desktop } from 'components/Desktop';
import { MobileAndTab } from 'components/MobileAndTab';
import { DataCellSkeleton } from 'components/DataCellSkeleton';
import { $AccountDetails } from '../AccountCard/DashboardAccountDetails';

export const AccountDetailsSkeleton = () => (
  <$AccountDetails>
    <MobileAndTab>
      <MobileAccountDetailsSkeleton />
    </MobileAndTab>
    <Desktop>
      <DesktopAccountDetailsSkeleton />
    </Desktop>
  </$AccountDetails>
);

const MobileAccountDetailsSkeleton = () => {
  const [ref, { width, height }] = useElementSize();
  const buttonWidth = Math.min(width, 302);
  return (
    <Box ref={ref} height='100%'>
      <SkeletonLoader>
        <rect x='0' y='0' rx='12.5' ry='12.5' width='130' height='25' />
        <rect x={width - 100} y='0' rx='12.5' ry='12.5' width='100' height='25' />
        <rect x='0' y='57' rx='12.5' ry='12.5' width='100' height='25' />
        <rect x={width - 65} y='57' rx='12.5' ry='12.5' width='65' height='25' />
        <rect x='0' y='114' rx='12.5' ry='12.5' width='130' height='25' />
        <rect x={width - 110} y='114' rx='12.5' ry='12.5' width='110' height='25' />
        <rect x={width / 2 - buttonWidth / 2} y={height - 38} rx='16' ry='16' width={buttonWidth} height='38' />
      </SkeletonLoader>
    </Box>
  );
};

const $DataCellSkeleton = styled(DataCellSkeleton)({
  maxWidth: '160px',
  width: '100%',
});

const $Divider = styled(Divider)({
  width: 0,
  height: '25px',
  margin: 'auto 40px auto 20px',
});

const $ButtonWrapper = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
});

const DesktopAccountDetailsSkeleton = () => {
  const [ref, { width, height }] = useElementSize();
  return (
    <>
      <$DataCellSkeleton spacing={1.5} noDivider />
      <$Divider orientation='vertical' flexItem />
      <$DataCellSkeleton spacing={1.5} noDivider />
      <$Divider orientation='vertical' flexItem />
      <$DataCellSkeleton spacing={1.5} noDivider />
      <$ButtonWrapper ref={ref}>
        <SkeletonLoader>
          <rect x={width - 136} y={height / 2 - 19} rx='16' ry='16' width='136' height='38' />
        </SkeletonLoader>
      </$ButtonWrapper>
    </>
  );
};
