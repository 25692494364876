/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { ActionLink } from 'components/ActionLink';
import { VSpacer } from 'components/VSpacer';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { Box } from '@mui/material';
import { PaymentMethodCurrent } from '../PaymentMethodCurrent';

type AutopayExistingPropsT = {
  onEdit: () => void;
};

export const AutopayExisting = ({ onEdit }: AutopayExistingPropsT) => {
  const loan = useRecoilValue(selectedLoanState);
  const { bankAccountNumber, bankAccountType, bankRoutingNumber } = loan.paymentInformation;

  return (
    <>
      <Box sx={{ width: '100%', alignSelf: 'left' }}>
        <PaymentMethodCurrent
          accountNumber={bankAccountNumber}
          routingNumber={bankRoutingNumber}
          accountType={bankAccountType}
        />
      </Box>
      <VSpacer spacing={5} />
      <ActionLink onClick={onEdit}>Use Another Payment Method</ActionLink>
      <VSpacer spacing={5} />
    </>
  );
};
