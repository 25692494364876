/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DropDownInputBase = styled(InputBase)(({ theme }) => ({
  display: 'block',
  width: '320px',
  height: '41px',
  borderRadius: '21px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '> .MuiSelect-select:focus': {
    backgroundColor: 'transparent',
  },
  '> svg': {
    color: theme.palette.common.white,
    right: '13px',
  },
  '> .MuiSelect-select': {
    textAlign: 'center',
    boxSizing: 'border-box',
    padding: '0 54px !important',
    lineHeight: '41px',
  },
  '> .MuiMenu-root .MuiMenu-paper ul': {
    paddingTop: '12px',
  },
}));
