/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { WhiteButton } from 'components/Buttons';
import { VSpacer } from 'components/VSpacer';
import { usePayments } from 'hooks/usePayments';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedLoanState, submittedDashboardLoan } from 'store/LoanStore';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import { PaymentAccount } from '../PaymentAccount';
import type { PaymentAccountT } from '../PaymentAccountModel';
import { AutopayExisting } from './AutopayExisting';
import { AutopaySubmit } from './AutopaySubmit';
import { AutopaySubmitText } from './AutopaySubmitText';

type AutopaySetupPropsT = {
  onUpdatedSuccess: () => void;
  defaultToNewAccount?: boolean;
};

export const AutopaySetup = ({ onUpdatedSuccess, defaultToNewAccount }: AutopaySetupPropsT) => {
  const loan = useRecoilValue(selectedLoanState);
  const setSubmittedLoan = useSetRecoilState(submittedDashboardLoan);

  const [isNewBankAccount, setIsNewBankAccount] = useState(
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    defaultToNewAccount || !loan.paymentInformation?.bankAccountNumber,
  );

  const form = useForm<PaymentAccountT>({
    mode: 'onChange',
    defaultValues: {
      accountNumber: '',
      accountNumberConfirm: '',
      routingNumber: '',
      accountType: undefined,
    },
  });

  const { handleSubmit, control, getValues } = form;

  const { setupAutopay } = usePayments();

  const [loading, setLoading] = useState(false);

  const onSuccessfulSubmit = (data: PaymentAccountT) => {
    setLoading(true);
    setSubmittedLoan(loan);
    setupAutopay(data)
      .then(() => onUpdatedSuccess())
      .catch((_error) => {
        throw new Error('autopay setup failed');
      })
      .finally(() => setLoading(false));
  };

  const { navigateToParentRoute } = useNavigationHelpers();

  const handleClose = () => {
    navigateToParentRoute();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSuccessfulSubmit)} style={{ textAlign: 'center' }} data-testid='onetime-form'>
        {isNewBankAccount && <PaymentAccount control={control} getValues={getValues} />}
        {!isNewBankAccount && <AutopayExisting onEdit={() => setIsNewBankAccount(true)} />}
        <VSpacer spacing={4} />
        <AutopaySubmit type='submit' loading={loading} />
        <VSpacer spacing={3} />
        <WhiteButton sx={{ textAlign: 'center' }} onClick={handleClose}>
          Cancel
        </WhiteButton>
        <VSpacer spacing={5} />
        <AutopaySubmitText />
      </form>
    </FormProvider>
  );
};
