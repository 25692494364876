// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import type { MouseEventHandler } from 'react';
import type { BoxProps as MuiBoxProps } from '@mui/material';
import { DataRow } from 'components/DataRow';
import { AutopayAction } from 'components/AutopayAction';
import { useNavigate } from 'react-router-dom';
import { useNavigateFromHeadRouteHelpers } from 'hooks/navigateHelper';
import { AutopayButton } from './AutopayButton';

export type AutopayActionPropsT = MuiBoxProps & {
  linkText: string;
  autopayOn: boolean;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
};

export const AutopayRow = ({ autopayOn, linkText, handleClick, ...rest }: AutopayActionPropsT) => {
  const navigate = useNavigate();
  const { isParentRoute, pathName, getParentRoute } = useNavigateFromHeadRouteHelpers();
  const autopayClickHandler =
    handleClick ??
    (() => {
      navigate(isParentRoute ? `${getParentRoute()}/autopay` : `${pathName}/autopay`);
    });

  return (
    <DataRow
      {...rest}
      label={<AutopayAction autopayOn={autopayOn} />}
      data={
        linkText ? (
          <AutopayButton data-testid='autopay-setup-button' linkText={linkText} onClick={autopayClickHandler} />
        ) : null
      }
    />
  );
};
