/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Divider, styled } from '@mui/material';
import NavigationArrow from 'assets/keyboard_arrow_down-24px.svg';
import { TextH2, TextH35 } from 'components/Typographies';
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { dateTo } from 'helpers/stringHelpers';
import { useNavigateFromHeadRouteHelpers } from 'hooks/navigateHelper';
import { useNavigate } from 'react-router-dom';
import type { PaymentDetailProps } from './PaymentHistoryDetails';

const $Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const $Row = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  height: '32px',
}));

const $Divider = styled(Divider)(() => ({
  margin: '16px 0 16px 0',
  width: '100%',
}));

const $DateRow = styled(TextH35)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  letterSpacing: '1.28px',
}));

const $PaymentRow = styled(TextH2)(() => ({
  lineHeight: '28px',
}));

const $StatusRow = styled(TextH35)(() => ({
  fontStyle: 'italic',
  lineHeight: '28px',
  color: '#1a1818',
}));

export type PaymentRowProps = {
  paymentDate: Date;
  type: string;
  status: string;
  amountInCents: number;
  displayAccountNumber?: string;
  showDivider?: boolean;
  paymentId: string;
};

export const getStatus = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'Processing ...';
    case 'VALID':
      return 'Thank you!';
    case 'INVALID':
      return 'Invalid';
    case 'RETRO_VALID':
      return 'Payment Reversal';
    case 'Canceled':
      return 'Canceled';
    case 'Scheduled':
      return 'Scheduled';
    default:
      return '';
  }
};

export const PaymentRow = ({
  paymentDate,
  type,
  status,
  amountInCents,
  displayAccountNumber = undefined,
  showDivider = true,
  paymentId,
}: PaymentRowProps) => {
  const navigate = useNavigate();
  const { isParentRoute, pathName, getParentRoute } = useNavigateFromHeadRouteHelpers();

  const paymentDetails: PaymentDetailProps = {
    paymentDate,
    status,
    amountInCents,
    displayAccountNumber,
    paymentId,
  };
  const clickHandler = () => {
    navigate(isParentRoute ? `${getParentRoute()}/paymentdetails` : `${pathName}/paymentdetails`, {
      state: paymentDetails,
    });
  };

  return (
    <$Container>
      <$Row>
        <$DateRow>{dateTo('really_short_abbr_no_year', paymentDate.toString()).toUpperCase()}</$DateRow>
      </$Row>
      <$Row>
        <$PaymentRow>{type}</$PaymentRow>
        <$PaymentRow style={{ display: 'flex' }}>
          {toCurrencyFromCents(amountInCents)}
          <div onClick={clickHandler}>
            <img src={NavigationArrow} alt='toggle arrow icon' />
          </div>
        </$PaymentRow>
      </$Row>
      <$Row>
        <$StatusRow>{getStatus(status)}</$StatusRow>
      </$Row>
      {showDivider && <$Divider />}
    </$Container>
  );
};
