// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { useRecoilValue } from 'recoil';
import { toCurrencyFromCents, percentage, toCurrency } from 'helpers/numberHelpers';
import { selectedLoanState } from 'store/LoanStore';
import { useGetOriginalLoanAmount } from 'hooks/useGetOriginalLoanAmount';
import { SnapshotProgress } from './SnapshotProgress';

export const PromoPeriodProgress = () => {
  const originalLoanAmountCents = useGetOriginalLoanAmount();
  const {
    paymentsCredits,
    paymentInformation: { totalPayoffCents },
  } = useRecoilValue(selectedLoanState);

  return (
    <SnapshotProgress
      header='Unpaid Deferred Interest Balance'
      amount={toCurrencyFromCents(totalPayoffCents)}
      progressPercentage={percentage(paymentsCredits, originalLoanAmountCents / 100)}
      caption={`Payments & Credits: ${toCurrency(paymentsCredits)} of ${toCurrencyFromCents(originalLoanAmountCents)}`}
    />
  );
};
