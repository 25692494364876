/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled, Tab, Tabs, Divider } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { $Center } from './Content';

export const TabNames = {
  Overview: 'overview',
  Payments: 'payments',
  Documents: 'documents',
} as const;

const $TabContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const $TabCenter = styled($Center)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const $Divider = styled(Divider)({
  margin: 0,
});

export const NavigationTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabNames = Object.values(TabNames);
  const pathParts = location.pathname.split('/');
  const currentTab = tabNames.find((tab) => tab === pathParts[1]) ?? TabNames.Overview;
  const { loanCode } = useParams();

  return (
    <>
      <$TabContainer>
        <$TabCenter maxWidth='lg'>
          <Tabs value={currentTab} aria-label='Loan info tabs'>
            {tabNames.map((tab) => (
              <Tab
                label={tab}
                onClick={() => navigate(`/${tab}/${loanCode ?? ''}`)}
                id={`${tab}/${loanCode ?? ''}`}
                value={tab}
                key={`${tab}/${loanCode ?? ''}`}
              />
            ))}
          </Tabs>
        </$TabCenter>
      </$TabContainer>
      <$Divider />
    </>
  );
};
