/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { SkeletonLoader } from 'components/SkeletonLoader';
import { Box, styled } from '@mui/material';
import { useElementSize } from 'usehooks-ts';
import { SectionBox } from 'components/SectionBox';

const $Container = styled(Box)({
  width: '100%',
  height: '223px',
});

export const UpcomingPaymentSkeleton = () => {
  const [ref, { width, height }] = useElementSize();
  return (
    <SectionBox header='UPCOMING PAYMENT' xs={12} lg={4.5}>
      <$Container ref={ref}>
        <SkeletonLoader>
          <rect x='0' y='0' rx='12.5' ry='12.5' width='100' height='25' />
          <rect x={width - 55} y='0' rx='12.5' ry='12.5' width='55' height='25' />
          <rect x='0' y='57.5px' rx='12.5' ry='12.5' width='150' height='25' />
          <rect x={width - 100} y='57.5px' rx='12.5' ry='12.5' width='100' height='25' />
          <rect x='0' y='115' rx='12.5' ry='12.5' width='150' height='25' />
          <rect x={width - 50} y='115' rx='12.5' ry='12.5' width='50' height='25' />
          <rect x={width / 2 - 141.75} y={height - 41} rx='20.5' ry='20.5' width='283.5' height='41' />
        </SkeletonLoader>
      </$Container>
    </SectionBox>
  );
};
