// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { styled } from '@mui/material';
import LinearProgress, { type LinearProgressProps as MuiLinearProgressProps } from '@mui/material/LinearProgress';

const $ProgressBar = styled(LinearProgress)({
  marginBottom: '8px',
});

export const ProgressBar = ({ value, variant = 'determinate', ...rest }: MuiLinearProgressProps) => (
  <$ProgressBar {...rest} variant={variant} value={value} />
);
