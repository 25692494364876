/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Grid, Paper, styled } from '@mui/material';
import { Statuses } from 'Status';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { selectedLoanCodeState, type Loan } from 'store/LoanStore';
import { DashboardAccountDetails } from './DashboardAccountDetails';
import { DashboardAccountOverview } from './DashboardAccountOverview';

export const $CardWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '24px',
  [theme.breakpoints.only('md')]: {
    padding: '32px 64px',
  },
}));

interface DashboardAccountCardContainerProps {
  variant?: 'open' | 'closed';
}

export const DashboardAccountCardContainer = styled(Box, {
  shouldForwardProp: (property) => property !== 'variant' && property !== 'sx',
  name: 'DashboardAccountCardContainer',
})<DashboardAccountCardContainerProps>(({ variant, theme }) => ({
  display: 'grid',
  gridTemplateRows: '50% 50%',
  width: '100%',
  height: '191px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateRows: variant === 'open' ? '162px 249px' : '104px 249px',
    height: variant === 'open' ? '411px' : '353px',
  },
}));

type DashboardAccountPropsT = {
  loan: Loan;
};

export const DashboardAccount = ({ loan }: DashboardAccountPropsT) => {
  const setSelectedLoanCode = useSetRecoilState(selectedLoanCodeState);
  const navigate = useNavigate();
  const wrapperVariant = loan.loanSummary.accountStatus === Statuses.CLOSED ? 'closed' : 'open';

  const handleClick = () => {
    setSelectedLoanCode(loan.loanCode);
    navigate(`/overview/${loan.loanCode}`);
    window.scrollTo(0, 0);
  };

  return (
    <Grid item xs={12} onClick={handleClick} data-testid='dashboard-account'>
      <$CardWrapper>
        <DashboardAccountCardContainer variant={wrapperVariant}>
          <DashboardAccountOverview loan={loan} />
          <DashboardAccountDetails loan={loan} />
        </DashboardAccountCardContainer>
      </$CardWrapper>
    </Grid>
  );
};
