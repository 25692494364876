/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import type { RadioGroupProps } from '@mui/material';
import { RadioGroup } from '@mui/material';
import { TextError } from 'components/Typographies';
import type { FormRules } from 'helpers/formRulesHelpers';
import type { Control, FieldValues, FieldPath } from 'react-hook-form';
import { useController } from 'react-hook-form';

export type FormRadioGroupFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  rules?: FormRules;
} & RadioGroupProps;

export const FormRadioGroupField = <T extends FieldValues>({
  control,
  name,
  rules,
  ...props
}: FormRadioGroupFieldProps<T>) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  return (
    <>
      <RadioGroup onChange={field.onChange} value={field.value} {...props} />
      {fieldState.error && <TextError>{fieldState.error.message}</TextError>}
    </>
  );
};
