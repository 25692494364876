// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { PublicClientApplication, EventType, type EventMessage, type AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from '../config/authConfig';

export const startMsal = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const { account } = event.payload as AuthenticationResult;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};
