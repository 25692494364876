// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { useState, useRef, type ChangeEvent } from 'react';
import { useController, type Control, type FieldValues, type FieldPath } from 'react-hook-form';
import { type TextFieldProps } from '@mui/material/TextField';
import type { FormRules } from 'helpers/formRulesHelpers';
import { IconButton, InputAdornment, useTheme, TextField } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';

interface State {
  value: string;
  maskedValue: string;
}

type FormMaskFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  rules?: FormRules;
  onPostChange?: () => void;
} & TextFieldProps;

export const FormMaskField = <T extends FieldValues>({
  control,
  name,
  rules,
  onPostChange,
  ...textProps
}: FormMaskFieldProps<T>) => {
  const [showingError, setShowingError] = useState(false);
  const newInputReference = useRef<HTMLInputElement | undefined>(undefined);
  const [showValue, setShowValue] = useState(false);
  const [values, setValues] = useState<State>({
    value: '',
    maskedValue: '',
  });

  const { field, fieldState, formState } = useController({ name, control, rules });

  const theme = useTheme();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/[^\d.]+/g, '');
    field.onChange(event);
    setValues({
      value: event.target.value,
      maskedValue: event.target.value.replace(/\d(?=\d{4})/g, '*'),
    });

    if (onPostChange) {
      onPostChange();
    }
  };

  const handleFocus = () => setShowValue(true);
  const handleBlur = () => {
    setShowingError(true);
    setShowValue(false);
    field.onBlur();
  };

  return (
    <TextField
      fullWidth
      label=''
      value={showValue ? values.value : values.maskedValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      name={name}
      id='masked-text-field'
      variant='outlined'
      inputRef={newInputReference}
      helperText={(formState.submitCount > 0 || showingError) && fieldState.error?.message}
      error={(formState.submitCount > 0 || showingError) && !!fieldState.error}
      {...textProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle accountNumber visibility'
              onClick={() => (showValue ? newInputReference?.current?.blur() : newInputReference?.current?.focus())}
            >
              {showValue ? (
                <VisibilityOffOutlined sx={{ color: theme.palette.secondary.dark }} />
              ) : (
                <VisibilityOutlined sx={{ color: theme.palette.secondary.dark }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
        inputProps: { ...textProps.inputProps, 'data-lpignore': 'true' },
      }}
    />
  );
};
