/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { Box } from '@mui/material';
import type { DataRowPropsT } from 'components/DataRow';
import { DataRow } from 'components/DataRow';
import { FormCurrencyField } from 'components/FormComponents/FormCurrencyField';
import { FormRadioField } from 'components/FormComponents/FormRadioField';
import { FormRadioGroupField } from 'components/FormComponents/FormRadioGroupField';
import { VSpacer } from 'components/VSpacer';
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import type { Control } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import type { PaymentAccountT } from '../PaymentAccountModel';
import { PaymentType } from '../PaymentAccountModel';

type PaymentEnterAmountPropsT = {
  control: Control<PaymentAccountT>;
  totalAmountDueCents: number;
  paymentToBringAccountCurrentCents: number;
};

export type RadioLabelProps = DataRowPropsT & {
  isChecked: boolean;
  label: string;
};

export const PaymentEnterAmountDelinquent = ({
  control,
  totalAmountDueCents,
  paymentToBringAccountCurrentCents,
}: PaymentEnterAmountPropsT) => {
  const {
    paymentInformation: { totalPayoffCents },
  } = useRecoilValue(selectedLoanState);
  const { setValue, clearErrors } = useFormContext();
  const { field: paymentTypeField } = useController({ control, name: 'paymentType' });
  const handleRadioFocus = () => {
    setValue('paymentAmount', 0);
    clearErrors();
  };

  const handleOtherFocus = () => {
    setValue('paymentType', PaymentType.None);
  };

  const verifyMinimumAmountAllowed = (value: string) => {
    const numberValue = Number.parseFloat(value);
    const minimumPaymentAllowedCents = 1000;
    const minimumPaymentAllowedDollars = minimumPaymentAllowedCents / 100;
    if (totalAmountDueCents <= minimumPaymentAllowedCents && numberValue <= minimumPaymentAllowedDollars) {
      return true;
    }

    if (numberValue >= minimumPaymentAllowedDollars) {
      return true;
    }

    return false;
  };

  return (
    <Box textAlign='left'>
      <DataRow label='Select Payment Amount:' data='' />
      <VSpacer spacing={2} />
      <FormRadioGroupField control={control} name='paymentType' onFocus={handleRadioFocus}>
        <FormRadioField
          label={
            <DataRow
              label='Amount to Bring Account Current'
              data={toCurrencyFromCents(paymentToBringAccountCurrentCents)}
            />
          }
          value={PaymentType.CurrentAmount}
        />
        <FormRadioField
          label={<DataRow label='Total Amount Due' data={toCurrencyFromCents(totalAmountDueCents)} />}
          value={PaymentType.TotalAmount}
        />
      </FormRadioGroupField>
      <VSpacer spacing={3} />
      <DataRow label='Other Payment Amount:' data='' />
      <VSpacer spacing={2} />
      <FormCurrencyField
        control={control}
        name='paymentAmount'
        inputProps={{
          'data-testid': 'other-payment-amount',
        }}
        onFocus={handleOtherFocus}
        rules={{
          validate: {
            minAmount: (value: string) =>
              paymentTypeField.value !== PaymentType.None ||
              verifyMinimumAmountAllowed(value) || // NOSONAR
              'Payment must be at least $10.',
            maxAmount: (value: string) =>
              paymentTypeField.value !== PaymentType.None ||
              Number.parseFloat(value) < 50_000 || // NOSONAR
              'Payment must be less than $50,000.',
            lessThanPayoffAmount: (value: string) =>
              paymentTypeField.value !== PaymentType.None ||
              Number.parseFloat(value) <= totalPayoffCents / 100 || // NOSONAR
              'Payment amount cannot be greater than total payoff amount',
          },
        }}
      />
      <VSpacer spacing={3} />
    </Box>
  );
};
