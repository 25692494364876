/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { DividedContainer } from 'components/DividedContainer';
import { SkeletonLoader } from 'components/SkeletonLoader';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { useElementSize } from 'usehooks-ts';
import { SectionBox } from 'components/SectionBox';

const $LeftBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '195px',
  [theme.breakpoints.only('lg')]: {
    marginTop: '26px',
  },
}));

export const LeftSkeleton = () => {
  const [ref, { width }] = useElementSize();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <$LeftBox ref={ref}>
      <SkeletonLoader>
        <rect x={width / 2 - 65} y='0' rx='11' ry='11' width='130' height='25' />
        <rect x={width / 2 - 56} y='37' rx='28' ry='28' width='112' height='55' />
        <rect x={width / 2 - 160} y={isMobile ? 116 : 132} rx='22' ry='22' width='320' height='41' />
      </SkeletonLoader>
    </$LeftBox>
  );
};

const $RightBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '195px',
  [theme.breakpoints.only('lg')]: {
    marginBottom: '32px',
  },
}));

export const RightSkeleton = () => {
  const [ref, { width, height }] = useElementSize();
  const spaceBetween = (height - 108) / 3;

  return (
    <$RightBox ref={ref}>
      <SkeletonLoader>
        <rect x='0' y='4' rx='12.5' ry='12.5' width='50' height='25' />
        <rect x={width - 75} y='0' rx='16.5' ry='16.5' width='75' height='33' />
        <rect x='0' y={33 + spaceBetween} rx='12.5' ry='12.5' width='150' height='25' />
        <rect x={width - 45} y={33 + spaceBetween} rx='12.5' ry='12.5' width='45' height='25' />
        <rect x='0' y={58 + spaceBetween * 2} rx='12.5' ry='12.5' width='150' height='25' />
        <rect x={width - 117} y={58 + spaceBetween * 2} rx='12.5' ry='12.5' width='117' height='25' />
        <rect x='0' y={height - 25} rx='12.5' ry='12.5' width='150' height='25' />
        <rect x={width - 117} y={height - 25} rx='12.5' ry='12.5' width='117' height='25' />
      </SkeletonLoader>
    </$RightBox>
  );
};

export const SnapshotSkeleton = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('lg'));
  return (
    <SectionBox header='PAYMENT SNAPSHOT' xs={12}>
      <DividedContainer left={<LeftSkeleton />} right={<RightSkeleton />} noDivider={!isDesktop} />
    </SectionBox>
  );
};
