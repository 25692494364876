/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Grid } from '@mui/material';
import { usePayments } from 'hooks/usePayments';
import { useState } from 'react';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import { useLoansStateWithRefresh } from 'hooks/useLoanStateWithRefresh';
import type { PaymentAccountT } from '../PaymentAccountModel';
import { PaymentType } from '../PaymentAccountModel';
import { OneTimePaymentEntry } from './OneTimePaymentEntry';
import { OneTimePaymentReview } from './OneTimePaymentReview';
import { OneTimePaymentSummary } from './OneTimePaymentSummary';

export const OneTimePayment = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState<PaymentAccountT>({
    paymentAmount: 0,
    paymentType: PaymentType.None,
  });
  const { makePayment } = usePayments();
  const [loading, setLoading] = useState(false);
  const { refreshLoans } = useLoansStateWithRefresh();

  const onSubmit = (data: PaymentAccountT) => {
    if (!data.paymentAmount) {
      throw new Error(`didn't set payment amount somehow`);
    }

    setStep(step + 1);
    setData(data);
  };

  const { navigateToParentRoute } = useNavigationHelpers();
  const close = () => navigateToParentRoute();

  const handleBack = () => {
    const emptyData: PaymentAccountT = {
      paymentAmount: data.paymentAmount,
      paymentType: PaymentType.None,
      scheduledDate: data.scheduledDate,
    };
    setData(emptyData);
    setStep(0);
  };

  const handlePaymentSubmit = () => {
    setLoading(true);
    makePayment(data)
      .then(() => {
        setStep(step + 1);
        refreshLoans();
      })
      .catch(() => {
        throw new Error(`payment failed`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container flexDirection='column' alignItems='center'>
      {step === 0 && <OneTimePaymentEntry data={data} onSubmit={onSubmit} />}
      {step === 1 && (
        <OneTimePaymentReview data={data} submitPayment={handlePaymentSubmit} onBack={handleBack} loading={loading} />
      )}
      {step === 2 && <OneTimePaymentSummary data={data} onClose={close} />}
    </Grid>
  );
};
