/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled, Box, type BoxProps as MuiBoxProps } from '@mui/material';
import CloseBrowser from 'assets/CloseImage.svg';

const $Container = styled(Box)({
  textAlign: 'center',
});

const $SubHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '16px 0',
  textAlign: 'center',
  borderBottom: '1px solid rgba(0, 56, 101, 0.1)',
  marginBottom: '56px',
}));

const $PageTitle = styled(Box)(({ theme }) => ({
  ...theme.typography.h1,
  fontSize: '24px',
  letterSpacing: 0.24,
  [theme.breakpoints.down('sm')]: {
    fontSize: '22px',
    color: '#1a1818',
  },
}));

const CloseBrowserIcon = (props: MuiBoxProps) => (
  <Box {...props} component='img' src={CloseBrowser} alt='Close Browser Icon' />
);

const $Box = styled(Box)(({ theme }) => ({
  ...theme.typography.h2,
  maxWidth: '560px',
  margin: '56px auto',
  padding: '0 16px',
}));

export const GeneralError = () => (
  <$Container>
    <$SubHeader>
      <$PageTitle data-testid='GeneralErrorTitle'>This isn&apos;t the norm...</$PageTitle>
    </$SubHeader>
    <CloseBrowserIcon />
    <$Box data-testid='GeneralErrorText'>
      NaluPay is temporarily unavailable due to several reasons which may include system maintenance. The good news, we
      are on it! The site will be up and running soon!
    </$Box>
  </$Container>
);
