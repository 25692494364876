// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { VSpacer } from 'components/VSpacer';
import { useRecoilValue } from 'recoil';
import { isSelectedLoanPastDue, selectedLoanState } from 'store/LoanStore';
import { MakePaymentButton } from '../MakePaymentButton';
import { PaymentDueSummary } from '../PaymentDueSummary';

const $Box = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.only('lg')]: {
    height: '195px',
    marginTop: '26px',
  },
}));

export const PaymentDue = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const loan = useRecoilValue(selectedLoanState);
  const isAccountPastDue = useRecoilValue(isSelectedLoanPastDue);
  const dueAmountInCents = loan.paymentInformation?.paymentDueInCents ?? 0;
  const totalPayoffCents = loan.paymentInformation?.totalPayoffCents ?? 0;

  return (
    <$Box>
      <PaymentDueSummary isAccountPastDue={isAccountPastDue} dueAmountInCents={dueAmountInCents} />
      <VSpacer spacing={isMobile ? 2 : 4} />
      <MakePaymentButton disabled={totalPayoffCents <= 0} isAccountPastDue={isAccountPastDue} />
      {isMobile && <VSpacer spacing={5} />}
    </$Box>
  );
};
