/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled, Typography } from '@mui/material';

const $InfoCard = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0 0 16px 0',
  textAlign: 'center',
  borderBottom: '1px solid rgba(0, 56, 101, 0.1)',
}));

const $FormContainer = styled('div')({
  maxWidth: '858px',
  margin: '30px auto',
  flexGrow: 1,
  padding: '0 16px',
  opacity: 0.99,
});

const $FormSubHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  margin: '16px 0',
}));

const $PageTitle = styled('div')(({ theme }) => ({
  ...theme.typography.h1,
  letterSpacing: 0.24,
  [theme.breakpoints.down('sm')]: {
    fontSize: '22px',
    color: '#1a1818',
  },
}));

const ListItem = ({ children }: { children: React.ReactNode }) => (
  <$ListItemTitle>
    <$ListItemParagraph>{children}</$ListItemParagraph>
  </$ListItemTitle>
);

const $ListItemTitle = styled('li')(({ theme }) => ({
  ...theme.typography.h6,
}));

const $ListItemParagraph = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
}));

const $LargeFormSubHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  margin: '16px 0',
}));

export const Terms = () => (
  <div>
    <$InfoCard>
      <br />
      <$PageTitle data-testid='TermsTitleText'>Terms &amp; Conditions</$PageTitle>
    </$InfoCard>
    <$FormContainer>
      <Typography variant='body2' component='span' data-testid='Terms'>
        These terms and conditions (&quot;Terms and Conditions&quot;) create a contract between you
        (&quot;Consumer&quot;) and NALUPAY (&quot;NaluPay&quot;) (the &quot;Agreement&quot;). Please read carefully. By
        accessing and using our NaluPay Customer Portal (the &quot;Customer Portal&quot;), you understand and accept the
        terms of this Agreement. The Consumer may also be referred to as &quot;Customer&quot;, &quot;I&quot;,
        &quot;user&quot;, &quot;you&quot; and &quot;your&quot; within this Agreement. NALUPAY may also be referred to as
        &quot;our&quot; or &quot;we&quot; within this Agreement.
        <br />
        <ol type='A'>
          <ListItem>
            <$LargeFormSubHeader>INTRODUCTION TO OUR CUSTOMER PORTAL</$LargeFormSubHeader>
            This Agreement governs your use of the Customer Portal, through which you can set up ACH payments, view
            statements, enroll in autopay and view your remaining line of credit (the &quot;Services&quot;). To use our
            Services, you need compatible hardware, software and Internet access.
          </ListItem>
          <ListItem>
            <$LargeFormSubHeader>USING OUR CUSTOMER PORTAL</$LargeFormSubHeader>
            <$FormSubHeader>Account Creation</$FormSubHeader>
            Using our Customer Portal and accessing your account information requires a customer log-in. You are
            entirely responsible for maintaining the confidentiality of your password and account. NaluPay reserves the
            right to deny or restrict access to the Customer Portal at its own discretion and without explanation.
            <$FormSubHeader>Privacy</$FormSubHeader>
            Information collected by NaluPay is used to better understand how Customers use the portal, as well as to
            assist us with fraud detection and for other security purposes. We collect the minimum amount of personal
            information that is necessary and we do not sell it to third parties, although information may be shared
            with third parties in order to complete your transaction.
            <br />
            <br />
            To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have
            put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the
            information we collect online.
            <$FormSubHeader>Third Party</$FormSubHeader>
            Your information may be shared with third party partners to the extent necessary to provide and improve
            portal services or other communications to users. All third parties who receive user information are
            prohibited from using or sharing user information for any purpose other than providing services to the user.
            <br />
            <br />
            NaluPay may also provide your information to third parties in circumstances where we believe that doing so
            is necessary or appropriate to: satisfy any applicable law, regulation, legal process or governmental
            request; detect, prevent or otherwise address fraud, security or technical issues; or protect the rights,
            property or safety of us, our users or others.
            <$FormSubHeader>Services and Content Usage</$FormSubHeader>
            Your use of the Customer Portal must follow the rules set forth in this section. Any other use of the
            Customer Portal is a material breach of this Agreement.
            <br />
            <br />
            Use of NaluPay&apos;s Customer Portal is for Customers only.
            <br />
            <br />
            You may not tamper with or circumvent any security technology included with the Customer Portal.
            <br />
            <br />
            You are responsible for all costs and charges associated with accessing the Customer Portal, including but
            not limited to internet and cellular data costs and charges.
            <br />
            <br />
            You may use the NaluPay Customer Portal to:
            <br />
            <br />
            Set up an Automated Clearing House (&quot;ACH&quot;) method of payment.
            <br />
            <br />
            Enroll in Autopay.
            <br />
            <br />
            View historical and current statements.
            <br />
            <br />
            View your remaining line of credit and original loan amount.
            <br />
            <br />
            View the number of remaining days left to spend your line of credit.
            <br />
            <br />
            View and print your account documentation: Autopay Authorization Form (if applicable) and statements
          </ListItem>

          <ListItem>
            <$LargeFormSubHeader>AUTHORIZATION AND RESPONSIBILITY</$LargeFormSubHeader>
            You authorize NaluPay to act on any instructions received through the Customer Portal, where those
            instructions are attributable to your user name and password details.
            <br />
            <br />
            You are responsible for any action carried out by NaluPay in accordance with the instructions received by
            NaluPay through the Customer Portal, including costs incurred, where those actions are attributable to your
            user name and password details.
          </ListItem>
          <ListItem>
            <$LargeFormSubHeader>WARRANTIES</$LargeFormSubHeader>
            By uploading data, information or bank account information into the Customer Portal, you represent and
            warrant that you are an individual and of legal age in your jurisdiction to enter into a binding agreement
            and that all the data or information you upload is accurate, complete and not misleading or deceptive. You
            warrant that the data or information you upload is free of any viruses, worms, malware or any other form of
            corruptive code which may cause damage or disruption to the Customer Portal, or NaluPay&apos;s business
            generally.
          </ListItem>
          <ListItem>
            <$LargeFormSubHeader>DISCLAIMER OF WARRANTIES; LIABILITY LIMITATION</$LargeFormSubHeader>
            NALUPAY DOES NOT WARRANT, GUARANTEE OR MAKE ANY REPRESENTATIONS AS TO THE RELIABILTY, ACCURACY OR
            COMPLETENESS OF THE DATA OR INFORMATION PROVIDED TO YOU THROUGH THE CUSTOMER PORTAL. NALUPAY DOES NOT ACCEPT
            ANY RESPONSIBILITY OR LIABILITY ARISING IN ANY WAY (INCLUDING BY REASON OF NEGLIGENCE) FOR ERRORS OR
            OMISSIONS IN THE DATA OR INFORMATION PROVIDED TO YOU THROUGH THE CUSTOMER PORTAL.
            <br />
            <br />
            NALUPAY DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE CUSTOMER PORTAL WILL BE UNINTERRUPTED
            OR ERROR-FREE, AND YOU AGREE THAT FROM TIME TO TIME NALUPAY MAY REMOVE ACESS TO THE CUSTOMER PORTAL FOR
            INDEFINITE PERIODS OF TIME, OR OTHERWISE LIMIT OR DISABLE YOUR ACCESS TO THE CUSTOMER PORTAL WITHOUT NOTICE
            TO YOU.
            <br />
            <br />
            NALUPAY SHALL USE REASONABLE EFFORTS TO PROTECT INFORMATION SUBMITTED BY YOU IN CONNECTION WITH THE CUSTOMER
            PORTAL, BUT YOU AGREE THAT YOUR SUBMISSION OF SUCH INFORMATION IS AT YOUR SOLE RISK, AND YOU HEREBY RELEASE
            NALUPAY FROM ANY AND ALL LIABLITY TO YOU FOR ANY LOSS OR LIABILITY RELATING TO SUCH INFORMATION IN ANY WAY.
            <br />
            <br />
            NALUPAY DOES NOT REPRESENT OR GUARANTEE THAT THE CUSTOMER PORTAL WILL BE FREE FROM ERROR, LOSS, CORRUPTION,
            ATTACK, VIRUSES, INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSIONS BY THIRD PARTIES OR OTHERWISE, AND YOU
            HEREBY RELEASE NALUPAY FROM ANY AND ALL LIABILITY RELATING THERETO.
          </ListItem>
          <ListItem>
            <$LargeFormSubHeader>WAIVER AND INDEMNITY</$LargeFormSubHeader>
            YOU FULLY INDEMNIFY NALUPAY, ITS EMPLOYEES, OFFICERS, DIRECTORS, AFFILIATES AND ASSIGNEES, AGAINST ANY
            EXPENSES, LOSS, DAMAGE, CLAIMS, CAUSES OF ACTION, COSTS, DEMANDS OR LIABILITIES ARISING OUT OF OR IN
            CONNECTION WITH THE USE (INCLUDING ANY IMPROPER OR UNAUTHORIZED USE) OF THE CUSTOMER PORTAL OR YOUR USERNAME
            AND PASSWORD DETAILS.
          </ListItem>
          <ListItem>
            <$LargeFormSubHeader>SEVERABILITY</$LargeFormSubHeader>
            If any of these Terms and Conditions is deemed unlawful, void or for any reason unenforceable, that
            provision shall be deemed severable and shall not affect the validity and enforceability of the remaining
            Terms and Conditions.
          </ListItem>
        </ol>
      </Typography>
    </$FormContainer>
  </div>
);
