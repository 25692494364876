/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Button, styled } from '@mui/material';

export const AutopayActionLink = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontWeight: 600,
  textDecoration: 'none',
  marginLeft: '3px',
  maxHeight: '41px',
  padding: '8px 24px',
  border: '2px solid',
  borderRadius: '20.5px',
}));
