/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { styled, Typography } from '@mui/material';

const $Header = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  margin: '19px 0',
}));

const $InfoCard = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0 0 16px 0',
  textAlign: 'center',
  borderBottom: '1px solid rgba(0, 56, 101, 0.1)',
}));

const $FormContainer = styled('div')({
  maxWidth: '858px',
  margin: '30px auto',
  flexGrow: 1,
  padding: '0 16px',
  opacity: 0.99,
});

const $FormSubHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  margin: '16px 0',
}));

const $PageTitle = styled('div')(({ theme }) => ({
  ...theme.typography.h1,
  letterSpacing: 0.24,
  [theme.breakpoints.down('sm')]: {
    fontSize: '22px',
    color: '#1a1818',
  },
}));

export const PrivacyPolicy = () => (
  <div>
    <$InfoCard>
      <br />
      <$PageTitle data-testid='PrivacyPolicyText'>Privacy Policy</$PageTitle>
    </$InfoCard>
    <$FormContainer>
      <Typography variant='body2' component='span' data-testid='PrivacyPolicy'>
        <$Header>Privacy Statement</$Header>
        <p>
          The privacy statement discloses the privacy practices for Bankers Healthcare Group, LLC. (BHG) and its
          affiliates. BHG is committed to your privacy. BHG hereby notifies you of:
        </p>
        <ul>
          <li>What personally identifiable information of yours is collected from you through this website.</li>
          <li>How the information may be shared.</li>
          <li>How we protect your information.</li>
          <li>How you can correct any inaccuracies in the information.</li>
        </ul>
        <$FormSubHeader>BROWSING OUR SITE</$FormSubHeader>
        <p>
          You can browse our sites anonymously by choosing not to provide us with any personally identifiable
          information, such as your name or email address, during your visits to our sites. When you browse this way, we
          won&#39;t be able to link your online activity with the accounts you have with us. Browsing anonymously after
          you&#39;ve identified yourself involves setting your browser to disable cookies and similar files or deleting
          the cookies and similar files that you have accepted from us. To do this, you&#39;ll need to follow your
          browser&#39;s instructions for disabling cookies or deleting cookies. *Please note that the use of certain
          online products do require the acceptance of cookies or similar files.
        </p>
        <$FormSubHeader>COOKIES AND SIMILAR FILE</$FormSubHeader>
        <p>
          Cookies are pieces of information stored directly on the computer you are using. In all cases, cookies and
          similar files that we place on your computer do not contain any personal information unless encrypted, and we
          never use cookies or other files to track your use of other websites. We may place cookies or similar files on
          your hard drive for security purposes, to facilitate site navigation, and to personalize your experience while
          visiting our site. Our cookies do not identify you as an individual or by account number. If you do not accept
          these cookies or similar files, you may experience some inconvenience in your use of some online products.
        </p>
        <$FormSubHeader>WEB BROWSING COOKIES</$FormSubHeader>
        <p>
          Cookies used for web browsing can contain a variety of information, such as a simple count of how often you
          visit a website or information that allows customization of a website for your use. BHG uses cookies to
          display information more effectively to you and to gather data about the usage of our website.
          <br />
          <br />
          Cookies allow us to collect technical and navigational information such as browser type, time spent on our
          site, and the pages visited. They do not contain or capture customer specific data. We may also use cookies in
          online advertising to track consumer responses to advertisement. This provides us the capability to monitor
          the use of our site so we can continually improve the design and functionality to better serve you.
        </p>
        <$Header>WHAT PERSONALLY IDENTIFIABLE INFORMATION OF YOURS IS COLLECTED</$Header>
        <$FormSubHeader>INFORMATION COLLECTION AND USE</$FormSubHeader>
        <p>
          We collect and use various types of information about you and your accounts to save you time and money, better
          respond to your needs, and manage our business and risks. Information that is submitted may or may not be
          secured or encrypted. While BHG attempts to protect all collected information, its security capabilities can
          at times be limited.
        </p>
        <$FormSubHeader>IP ADDRESS AND OTHER STANDARD INFORMATION</$FormSubHeader>
        <p>
          As is true of many websites, we or our third-party web analytics partners may gather certain information
          automatically and store it in log files or systems used by us. This information may include internet protocol
          (IP) addresses, device type, browser type, internet service provider (ISP), referring/exit pages, operating
          system, geo-location, date/time stamp, and/or clickstream data. We may combine this automatically collected
          log information with other information we collect about you. We do this to improve services we offer you as
          well as our marketing, analytics and site functionality and to analyze trends and gather demographic
          information about our user base as a whole. We may also receive reports based on the use of these technologies
          by our web analytics partners on an individual as well as aggregated basis.
          <br />
          <br />
          We collect your location-based (geo-location) information for the purpose of optimizing your website
          experience. We will share this information with our mapping provider for the purpose of providing and
          optimizing this service for our visitors. You may opt out of location-based services at any time by editing
          the setting on your device.
        </p>
        <$FormSubHeader>CUSTOMER INFORMATION IS CATEGORIZED IN THE FOLLOWING SIX WAYS:</$FormSubHeader>
        <ul>
          <li>
            Identification Information — information that identifies you such as name, address, telephone number, tax
            identification numbers and Social Security number.
          </li>
          <li>
            Application Information — information you provide to us on applications and through other means that will
            help us determine if you are eligible for products you request. Examples include assets, income and debt.
          </li>
          <li>
            Transaction and Experience Information — information about transactions and account experience, as well as
            information about our communications with you. Examples include account balances, payment history, account
            usage, and your inquiries and our responses.
          </li>
          <li>
            Consumer Report Information — information from a consumer report. Examples include credit score and credit
            history.
          </li>
          <li>
            Information from Outside Sources — information from outside sources regarding employment, credit and other
            relationships that will help us determine if you are eligible for products you request. Examples include
            employment history, loan balances, credit card balances, property insurance coverage and other
            verifications.
          </li>
          <li>
            Other General Information — information from outside sources, such as data from public records, that is not
            assembled or used for the purpose of determining eligibility for a product or service.
          </li>
        </ul>
        <p>
          As required by the USA PATRIOT Act, we also collect information and take actions necessary to verify your
          identification.
        </p>
        <$FormSubHeader>REGISTRATION</$FormSubHeader>
        <p>
          During the collection of information on our website, users are required to give their contact information
          (such as name and email address). This information may be used to contact the users about the services on our
          site for which they have expressed interest.
        </p>
        <$FormSubHeader>CHILDREN&#39;S AND MINOR&#39;S PRIVACY</$FormSubHeader>
        <p>
          BHG strongly encourages parents and guardians to supervise the online activities of their minor children and
          consider using parental control tools available from online services and software manufacturers to help
          provide a child-friendly online environment. These tools can also prevent minors from disclosing their name,
          address, and other personally identifiable information online without parental permission. Although the BHG
          site is not intended for use by minors, BHG respects the privacy of minors who may use the Internet. Whenever
          BHG becomes aware that a user is under the age of 18 years, the following additional terms, conditions and
          notices apply to use of the BHG site:
          <br />
          <br />
          Users under 18 years of age may not submit information through the BHG site. BHG will not collect any
          personally identifiable information about a person who BHG determines is under age 18, except with written
          consent from the person&#39;s parent after informing the parent about the types of information BHG will
          collect, how it will be used, and under what circumstances it will be disclosed.
          <br />
          <br />
          Personally identifiable information collected from minors may include any of the information collected on the
          website, including their contact information (such as name and email address), and may be used by BHG for the
          same purposes as adult information, as described in this privacy statement.
          <br />
          <br />A minor&#39;s parent or legal guardian may request BHG to provide a description of the personally
          identifiable information that BHG has collected from the minor, as well as instruct BHG to cease further use,
          maintenance and collection of personally identifiable information from the minor.
        </p>
        <$FormSubHeader>OPT OUT PROCEDURES</$FormSubHeader>
        <p>
          Users of this website may opt out of receiving communications from BHG at the point where BHG requests
          personally identifiable information about the use. Contact us to be removed from an email list. If you are
          using an email forwarding service or other similar service please make sure to include the correct email
          address or addresses.
        </p>
        <$Header>HOW INFORMATION MAY BE SHARED</$Header>
        <$FormSubHeader>HOW WE SHARE INFORMATION</$FormSubHeader>
        <p>
          We share information with our affiliates (members of our corporate family) or companies outside our corporate
          family that perform services on our behalf. We share information to process transactions, respond to your
          inquiries, or provide information about the products or services that we offer. We share information with
          companies that we have a special relationship with, such as joint marketers. We share information with
          non-affiliates (companies outside our organization) that would like to offer you their products or services
          that might be of interest to you. We share data provided by customers; credit history data; and any verifying
          data collected in the application process.
          <br />
          <br />
          The types of companies that we share information with include financial service providers such as mortgage
          bankers; securities brokers-dealers; insurance agents; other financial service providers.
          <br />
          <br />
          In addition, we share information with non-financial companies such as direct marketers. The law permits us to
          share information about our current and former customers with government agencies or authorized third parties
          under certain circumstances. For example, we may be required to share such information in response to
          subpoenas or to comply with certain laws.
        </p>
        <$FormSubHeader>FORMER CUSTOMERS</$FormSubHeader>
        <p>
          We share information with our affiliates (members of our corporate family) or companies outside our corporate
          family that perform services on our behalf. We share information to process transactions, respond to your
          inquiries, or provide information about the products or services that we offer. We share information with
          companies that we have a special relationship with, such as joint marketers.
          <br />
          <br />
          We share information with non-affiliates (companies outside our organization) that would like to offer you
          their products or services that might be of interest to you. We share data provided by customers; credit
          history data; and any verifying data collected in the application process. The types of companies that we
          share information with include financial service providers such as mortgage bankers; securities
          brokers-dealers; insurance agents; other financial service providers.
          <br />
          <br />
          In addition, we share information with non-financial companies such as direct marketers. The law permits us to
          share information about our current and former customers with government agencies or authorized third parties
          under certain circumstances. For example, we may be required to share such information in response to
          subpoenas or to comply with certain laws.
        </p>
        <$FormSubHeader>LEGAL REQUESTS</$FormSubHeader>
        <p>
          BHG cooperates with law enforcement inquiries, as well as other third parties, to enforce laws and third-party
          rights, such as intellectual property rights and laws relating to fraud. Therefore, in response to a verified
          request by law enforcement or other government officials relating to a criminal investigation or alleged
          illegal activity, or other activity that may expose us or you to legal liability, we can (and you authorize us
          to) disclose your personal data and other information about you as we, in our sole discretion, believe
          necessary or appropriate.
        </p>
        <$Header>CORRECTING INACCURACIES</$Header>
        <$FormSubHeader>CONFIDENTIALITY AND SECURITY</$FormSubHeader>
        <p>
          BHG takes all necessary precautions to protect users&#39; personal information both online and offline.
          <br />
          <br />
          We protect your personal information offline. Access to your personal information is limited to entities whom
          BHG reasonably believes will need that information to provide services to you.
          <br />
          <br />
          However, BHG is not responsible for the confidentiality, security or distribution of your own personal
          information by our partners and third parties outside the scope of our agreement with such partners and third
          parties.
        </p>
        <$FormSubHeader>LINKS</$FormSubHeader>
        <p>
          This website and associated sites may contain links to other sites. The linked sites are not necessarily under
          the control of BHG. Please be aware that BHG is not responsible for the privacy practices of such other sites.
          We encourage our users to be aware when they leave our site and to read the privacy statements of each and
          every website that collects personally identifiable information. If you decide to access any of the
          third-party sites linked to this site, you do this entirely at your own risk. Any links to any partner of the
          site should be the responsibility of the linking party, and BHG shall not be responsible for notification of
          any change in name or location of any information of the site.
        </p>
        <$Header>HOW WE PROTECT YOUR INFORMATION</$Header>
        <$FormSubHeader>CORRECTING/UPDATING PERSONAL INFORMATION</$FormSubHeader>
        <p>
          Keeping your account information accurate and up to date is very important. You have access to your account
          information, which includes your contact information, account balances and transactions and similar
          information, which we provide to you through various means and in response to specific requests. If your
          account information is incomplete, inaccurate or not current, please call or write to us at the telephone
          number or appropriate address for such changes listed on your account statement or other account materials. We
          will promptly update or correct any erroneous information.
        </p>
        <$FormSubHeader>NOTIFICATION OF CHANGES</$FormSubHeader>
        <p>
          If we decide to change our privacy policy, we will post those changes on default so our users are always aware
          of what information we collect, how we use it, and under what circumstances, if any, we disclose it. If at any
          point we decide to use personally identifiable information in a manner different from that stated at the time
          it was collected, we will notify users by way of an email. Users who have provided personally identifiable
          information before the changes in our privacy policy will have a choice as to whether or not we use their
          information in this different manner. If the user chooses that we not use his/her personally identifiable
          information in accordance with the new privacy policy, we will use information in accordance with the privacy
          policy under which the information was originally collected.
          <br />
          <br />
          We may make changes to this policy at any time and will inform you of changes, as required by law.
        </p>
        <p>Version 1.2.1 | Revised 12/01/2020</p>
      </Typography>
    </$FormContainer>
  </div>
);
