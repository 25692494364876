// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { styled, Container, type BoxProps as MuiBoxProps, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from 'components/Buttons';
import { TextBody2, TextH1 } from 'components/Typographies';
import { Footer } from 'components/Footer/Footer';
import NaluLogo from '../../../assets/nalupay_logo.svg';

const $Container = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  minWidth: '360px',
  backgroundColor: `${theme.palette.background.default}`,
}));

const $PageContainer = styled('div')(({ theme }) => ({
  backgroundColor: `${theme.palette.background.default}`,
}));

const $ContentContainer = styled('div')({
  maxWidth: '320px',
  height: '531px',
  margin: '0 auto',
  textAlign: 'center',
});

const $PageTitle = styled(TextH1)({
  fontSize: '20px',
  color: '#003865',
  textAlign: 'center',
  marginBottom: '16px',
  lineHeight: '36px',
  letterSpacing: '0.4px',
});

const $Text = styled(TextBody2)(() => ({
  textAlign: 'center',
  lineHeight: '24px',
}));

const $SignIn = styled(BlueButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
  fontSize: '16px',
  lineHeight: '28px',
  letterSpacing: '0.38px',
  marginTop: '40px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Logo = (props: MuiBoxProps) => <Box {...props} component='img' src={NaluLogo} alt='NaluLogo' />;

const $Logo = styled(Logo)(() => ({
  height: '45px',
  marginTop: '64px',
  width: 'inherit',
  marginBottom: '32px',
}));
export const SessionExpiredPage = () => {
  const navigate = useNavigate();

  const signIn = () => {
    navigate('/');
  };

  return (
    <$Container disableGutters maxWidth={false}>
      <$Logo />
      <$PageContainer>
        <$ContentContainer>
          <$PageTitle>Session Timeout</$PageTitle>
          <$Text>Due to inactivity and for your security, we logged you out of your session.</$Text>
          <$SignIn data-testid='signIn' color='primary' onClick={signIn}>
            Back to Sign In
          </$SignIn>
        </$ContentContainer>
      </$PageContainer>
      <Footer />
    </$Container>
  );
};
