/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import endpointConfigs from 'config/endpointConfig';
import { axios } from 'helpers/axiosInstance';
import { selector } from 'recoil';
import { loansRequestId, selectedLoanState } from './LoanStore';

export type PaymentList = {
  count: number;
  results: Payment[];
};

export type Payment = {
  loanCode: string;
  amountInCents: number;
  effectiveDate: Date;
  status: string;
  type: string;
  paymentId: string;
  maskedAchPaymentDetails?: {
    bankAccountNumber?: string;
    bankRoutingNumber?: string;
  };
};

const defaultPayments: Payment[] = [];
const PAYMENT_TYPE = 'Payment';

export const paymentsState = selector<Payment[]>({
  key: 'payments',
  get({ get }) {
    get(loansRequestId);
    const payments = get(existingPayments).concat(get(scheduledPayments));
    return payments;
  },
});

export const activeScheduledPayments = selector<Payment[]>({
  key: 'activeScheduledPayments',
  get({ get }) {
    const futurePayments = get(scheduledPayments);
    const filteredPayments: Payment[] = futurePayments.filter((p) => p.status === 'Scheduled');
    return filteredPayments;
  },
});

const existingPayments = selector<Payment[]>({
  key: 'existingPayments',
  async get({ get }) {
    const { loanCode } = get(selectedLoanState);
    const config = { ...endpointConfigs.BORROWER_PORTAL.GET_PAYMENTS_EXISTING };
    config.url = config.url?.replace('{loanCode}', loanCode);

    const { data } = await axios<PaymentList>(config);
    const payments = data.results ?? defaultPayments;
    for (const payment of payments) {
      payment.type = PAYMENT_TYPE;
    }

    return payments;
  },
});

export const scheduledPayments = selector<Payment[]>({
  key: 'scheduledPayments',
  async get({ get }) {
    const { loanCode } = get(selectedLoanState);
    const config = { ...endpointConfigs.BORROWER_PORTAL.GET_PAYMENTS_SCHEDULED };
    config.url = config.url?.replace('{loanCode}', loanCode);

    const { data } = await axios<PaymentList>(config);
    const payments = data.results ?? defaultPayments;
    for (const payment of payments) {
      payment.type = PAYMENT_TYPE;
    }

    return payments;
  },
});
