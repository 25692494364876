/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled, Box, Divider } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { DashboardButton } from 'components/DashboardButton';
import { ProfileMenu } from '../Sidebar/ProfileMenu';
import { userInitialsState } from '../../store/UserStore';
import { AvatarPopup } from './AvatarPopup';

const $Divider = styled(Divider)({
  height: '24px',
  margin: 'auto 16px auto 8px',
});

export const HeaderMenuItems = () => {
  const initials = useRecoilValue(userInitialsState);
  return (
    <Box display='flex' sx={{ zIndex: '20' }}>
      <DashboardButton />
      <$Divider />
      <AvatarPopup initials={initials}>
        <ProfileMenu />
      </AvatarPopup>
    </Box>
  );
};
