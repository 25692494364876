/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { userAuthState } from 'store/UserStore';

const $DashboardHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  width: '100%',
  height: '91px',
  ...theme.typography.h1,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
}));

export const DashboardHeader = () => {
  const { firstName } = useRecoilValue(userAuthState);
  const welcomeText = firstName ? `Welcome, ${firstName}!` : 'Welcome!';
  return <$DashboardHeaderBox>{welcomeText}</$DashboardHeaderBox>;
};
