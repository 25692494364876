/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { Footer } from 'components/Footer/Footer';
import { TagResponseHeader } from 'components/Header/TagResponseHeader';
import { $Container, $ContentBox, $ContentHeadline } from './TagAccepted';

export const TagLoading = () => (
  <$Container>
    <TagResponseHeader />
    <$ContentBox>
      <$ContentHeadline>Loading...</$ContentHeadline>
    </$ContentBox>
    <Footer />
  </$Container>
);
