// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { useIdleTimer } from 'react-idle-timer';
import type { IIdleTimerProps } from 'react-idle-timer';
import { useAuth } from 'hooks/useAuth';
import { useModal } from './Modal';
import { SessionExtensionModal } from './SessionExtensionModal';

const Session = () => {
  const { openModal, closeModal, display } = useModal();
  const { logoutWithRedirect } = useAuth();
  const milliSeconds = 1000;
  const seconds = 60;
  const totalIdleMinutes = 14;
  const sessionModalWaitMinutes = 1;

  const expireSession = () => {
    const currentRoute = `${window.location.origin}/timeout`;
    logoutWithRedirect(currentRoute);
  };

  const idleTimerProps: IIdleTimerProps = {
    timeout: (totalIdleMinutes - sessionModalWaitMinutes) * seconds * milliSeconds,
    onIdle: openModal,
  };

  useIdleTimer(idleTimerProps);

  return (
    <>
      {!!display && (
        <div data-testid='session-modal'>
          <SessionExtensionModal
            waitSeconds={sessionModalWaitMinutes * seconds}
            onTimeExpire={expireSession}
            onCloseModal={closeModal}
          />
        </div>
      )}
    </>
  );
};

export default Session;
