// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { styled, Chip, type ChipProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Statuses, type StatusesType } from 'Status';

type StatusStyle = {
  backgroundcolor: string;
  fontcolor: string;
};

type StatusT = StatusStyle & {
  label: string;
};

type StatusesT = Record<keyof StatusesType, StatusT>;

const $Status = styled(Chip)<StatusStyle>(({ backgroundcolor, fontcolor }) => ({
  color: fontcolor,
  backgroundColor: backgroundcolor,
}));

export type StatusPropsT = ChipProps & {
  status: keyof StatusesType;
};

export const Status = ({ status, ...props }: StatusPropsT) => {
  const theme = useTheme();
  const statuses: StatusesT = {
    [Statuses.ACTIVE]: {
      label: 'Active',
      backgroundcolor: theme.palette.success.light,
      fontcolor: theme.palette.success.main,
    },
    [Statuses.SUSPENDED]: {
      label: 'Past Due',
      backgroundcolor: theme.palette.error.light,
      fontcolor: theme.palette.error.main,
    },
    [Statuses.CLOSED]: {
      label: 'Closed',
      backgroundcolor: 'rgba(117, 120, 123, 0.12)',
      fontcolor: theme.palette.secondary.dark,
    },
    [Statuses.UNKNOWN]: {
      label: 'Unknown',
      backgroundcolor: theme.palette.grey[50],
      fontcolor: theme.palette.common.black,
    },
  } as const;

  const { label, backgroundcolor, fontcolor } = statuses[status] ?? statuses.UNKNOWN;

  return <$Status label={label} backgroundcolor={backgroundcolor} fontcolor={fontcolor} {...props} />;
};
