// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { styled, IconButton, type IconButtonProps as MuiIconButtonProps } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const $Button = styled(IconButton)({
  width: '24px',
  height: '24px',
});

export const Hamburger = ({ ...props }: MuiIconButtonProps) => (
  <$Button {...props} data-testid='hamburger-button'>
    <MenuRoundedIcon />
  </$Button>
);
