/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { styled, Typography } from '@mui/material';
import LoadingText from 'components/LoadingText';
import SubmitButton from 'components/SubmitButton';
import { promiseHandler } from 'helpers/promiseHelpers';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'hooks/useToasts';
import endpointConfig from 'config/endpointConfig';
import { axios } from 'helpers/axiosInstance';
import type { PrivacyData } from './PrivacyData';
import { PrivacyFormFields } from './PrivacyFormFields';

const $FormWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: '1px solid rgba(0, 56, 101, 0.1)',
  borderRadius: '4px',
  padding: '32px 72px 40px',
  marginTop: '40px',
  [theme.breakpoints.down('md')]: {
    padding: '32px 32px 40px',
  },
}));

const $FormTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.data4,
  color: theme.palette.primary.main,
  letterSpacing: 'normal',
}));

const $FormFieldsWrapper = styled('div')({
  maxWidth: '408px',
  margin: '0 auto',
});

const $AllFieldsRequiredText = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
  letterSpacing: 'normal',
  textAlign: 'left',
  margin: '24px 0 18px',
}));

export const PrivacyForm = () => {
  const { handleSubmit, control, formState } = useForm<PrivacyData>({ mode: 'onChange' });
  const navigate = useNavigate();
  const { pushToast } = useToasts();

  const onSubmit: SubmitHandler<PrivacyData> = async (data: PrivacyData) => {
    try {
      const config = { ...endpointConfig.PRIVACY.DO_NOT_SELL_MY_PERSONAL_INFO };
      config.data = data;
      await axios.request(config);
      navigate('/thankyou');
    } catch {
      pushToast('fail', 'Form not submitted successfully.');
    }
  };

  return (
    <$FormWrapper>
      <$FormTitle>
        Formally record your preference to not have your personal information shared by filling out this form
      </$FormTitle>
      <$FormFieldsWrapper>
        <$AllFieldsRequiredText>All Fields Required</$AllFieldsRequiredText>
        <form onSubmit={promiseHandler(handleSubmit(onSubmit))} style={{ textAlign: 'center' }}>
          <PrivacyFormFields control={control} />
          <SubmitButton disabled={!formState.isValid} sx={{ marginTop: '32px' }}>
            <LoadingText text='Submit' loading={formState.isSubmitting} />
          </SubmitButton>
        </form>
      </$FormFieldsWrapper>
    </$FormWrapper>
  );
};
