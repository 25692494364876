// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { Box, styled } from '@mui/material';
import { ProgressBar } from 'components/ProgressBar';
import type { ReactNode } from 'react';

const $header = styled(Box)(({ theme }) => ({
  ...theme.typography.h3_5,
  color: theme.palette.secondary.dark,
  textAlign: 'center',
  marginBottom: '12px',
}));

const $Amount = styled(Box)(({ theme }) => ({
  ...theme.typography.data2,
  color: theme.palette.primary.main,
  marginBottom: '24px',
  textAlign: 'center',
}));

const $ProgressCaption = styled(Box)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.primary.main,
  textAlign: 'center',
  marginBottom: '1px',
}));

export type SnapshotProgressPropsT = {
  header: ReactNode;
  amount: ReactNode;
  progressPercentage: number;
  caption: ReactNode;
};

export const SnapshotProgress = ({ header, amount, progressPercentage, caption }: SnapshotProgressPropsT) => (
  <Box width='100%'>
    <$header>{header}</$header>
    <$Amount>{amount}</$Amount>
    <ProgressBar value={progressPercentage} />
    <$ProgressCaption>{caption}</$ProgressCaption>
  </Box>
);
