// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { dateTo } from 'helpers/stringHelpers';
import type { ReactNode } from 'react';
import { useGetOriginalLoanAmount } from 'hooks/useGetOriginalLoanAmount';
import { SnapshotDetails } from './SnapshotDetails';

export const LoanDetails = () => {
  const originalLoanAmountCents = useGetOriginalLoanAmount();
  const {
    loanSummary: { accountStatus },
    paymentInformation: { paymentDueDate },
  } = useRecoilValue(selectedLoanState);

  const statusClosed = accountStatus.toLowerCase() === 'closed';
  const data: Record<string, ReactNode> = {
    'Original Loan Amount': toCurrencyFromCents(originalLoanAmountCents),
    'Payment Due Date': dateTo('short_abbr', paymentDueDate),
  };

  return <SnapshotDetails data={statusClosed ? {} : data} isClosed={statusClosed} />;
};
