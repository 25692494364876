// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SectionBox } from 'components/SectionBox';
import { DividedContainer } from 'components/DividedContainer';
import { PaymentDue } from './PaymentDue';
import { PaymentDetails } from './PaymentDetails';

export const PaymentSnapshot = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('lg'));
  return (
    <SectionBox header='PAYMENT SNAPSHOT' xs={12}>
      <DividedContainer left={<PaymentDue />} right={<PaymentDetails />} noDivider={!isDesktop} />
    </SectionBox>
  );
};
