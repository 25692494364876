/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, type ButtonProps as MuiButtonProps, styled, Typography } from '@mui/material';
import { AutopayIconWithTooltip } from './AutopayIconWithTooltip';

const $Box = styled(Box)({
  display: 'flex',
});

const $label = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  ...theme.typography.h3_5,
  alignSelf: 'center',
  paddingRight: '8px',
}));

const $OnOff = styled(Box)(({ theme }) => ({
  display: 'flex',
  ...theme.typography.data3,
  color: theme.palette.common.black,
  paddingLeft: '8px',
}));

export type AutopayActionPropsT = MuiButtonProps & {
  autopayOn: boolean;
};

export const AutopayAction = ({ autopayOn }: AutopayActionPropsT) => (
  <$Box>
    <$label>Autopay</$label>
    <AutopayIconWithTooltip autopayOn={autopayOn} />
    <$OnOff>{autopayOn ? 'On' : 'Off'}</$OnOff>
  </$Box>
);
