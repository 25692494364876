/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Divider } from '@mui/material';
import { AutopayRow } from 'components/AutopayRow';
import { DataRow } from 'components/DataRow';
import { VSpacer } from 'components/VSpacer';
import type { MouseEventHandler } from 'react';

type AutopaySummaryPropsT = {
  accountNumber: string;
  autopayOn: boolean;
  linkText: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const AutopaySummary = ({ accountNumber, autopayOn, linkText, onClick }: AutopaySummaryPropsT) => (
  <Box sx={{ width: '100%' }}>
    <DataRow label='Account Number' data={accountNumber} />
    <VSpacer spacing={3} />
    <AutopayRow autopayOn={autopayOn} linkText={linkText} handleClick={onClick} />
    <Divider sx={{ margin: '23px 0px' }} />
  </Box>
);
