/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled, Divider } from '@mui/material';
import { DataCell } from 'components/DataCell';
import { DataRow } from 'components/DataRow';
import { Desktop } from 'components/Desktop';
import { MobileAndTab } from 'components/MobileAndTab';
import { VSpacer } from 'components/VSpacer';
import { type ReactNode } from 'react';
import { dashboardData } from './DashboardHelpers';

const $Divider = styled(Divider)({
  width: 0,
  height: '25px',
  margin: 'auto 40px auto 20px',
});

const $DataCell = styled(DataCell)({
  width: '160px',
});

type DashboardFieldPropsT = {
  label: ReactNode;
  data: ReactNode;
  spacing?: number;
  divider?: boolean;
  error?: boolean;
};

export const DashboardField = ({ label, data, spacing, divider, error }: DashboardFieldPropsT) => (
  <>
    <MobileAndTab>
      <DataRow label={label} data={data} error={error} data-testid='mobile-field' />
      <VSpacer spacing={spacing} />
    </MobileAndTab>

    <Desktop>
      <$DataCell label={label} data={dashboardData(data, error)} spacing={1.5} noDivider data-testid='desktop-field' />
      <$Divider orientation='vertical' flexItem hidden={!divider} />
    </Desktop>
  </>
);

DashboardField.defaultProps = {
  spacing: 0,
  divider: false,
  error: false,
};
