// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { Buffer } from 'buffer';
import type { AxiosError } from 'axios';

const obfuscateAllAttributes = (data: Record<string, unknown>) => {
  for (const key of Object.keys(data)) {
    const value = data[key];
    if (value && (typeof value === 'string' || typeof value === 'number')) {
      data[key] = (typeof value === 'string' ? value : value.toString())
        .replace(/\d/g, '|#|')
        .replace(/[A-Za-z]/g, '|a|');
    } else if (value && typeof value === 'object') {
      data[key] = obfuscateAllAttributes(value as Record<string, unknown>);
    }
  }

  return data;
};

const obfuscateProperty = (prop: unknown) => {
  const isString = typeof prop === 'string';
  try {
    const stringProperty = isString ? prop : JSON.stringify(prop);
    const data = JSON.parse(stringProperty) as Record<string, unknown>;
    return isString ? JSON.stringify(obfuscateAllAttributes(data)) : obfuscateAllAttributes(data);
  } catch (error: unknown) {
    return error ? `Error obfuscating: ${JSON.stringify(error)}` : `Error obfuscating`;
  }
};

export const obfuscateData = (error: AxiosError) => {
  if (error.config?.data) {
    error.config.data = obfuscateProperty(error.config.data);
  }

  if (error.config?.params) {
    error.config.params = obfuscateProperty(error.config.params);
  }

  return Buffer.from(JSON.stringify(error)).toString('base64');
};
