/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Typography, type TypographyProps } from '@mui/material';

export const TextH1 = (props: TypographyProps) => <Typography {...props} variant='h1' />;
export const TextH2 = (props: TypographyProps) => <Typography {...props} variant='h2' />;
export const TextH25 = (props: TypographyProps) => <Typography {...props} variant='h2_5' />;
export const TextH3 = (props: TypographyProps) => <Typography {...props} variant='h3' />;
export const TextH35 = (props: TypographyProps) => <Typography {...props} variant='h3_5' />;
export const FadedTextH35 = (props: TypographyProps) => (
  <Typography {...props} color={(theme) => theme.palette.secondary.dark} variant='h3_5' />
);
export const TextH4 = (props: TypographyProps) => <Typography {...props} variant='h4' />;
export const TextSubtitle1 = (props: TypographyProps) => <Typography {...props} variant='subtitle1' />;
export const TextSubtitle16 = (props: TypographyProps) => <Typography {...props} variant='subtitle1_6' />;
export const TextSubtitle2 = (props: TypographyProps) => <Typography {...props} variant='subtitle2' />;
export const TextData1 = (props: TypographyProps) => <Typography {...props} variant='data1' />;
export const TextData2 = (props: TypographyProps) => <Typography {...props} variant='data2' />;
export const TextData3 = (props: TypographyProps) => <Typography {...props} variant='data3' />;
export const TextData4 = (props: TypographyProps) => <Typography {...props} variant='data4' />;
export const TextBody1 = (props: TypographyProps) => <Typography {...props} variant='body1' />;
export const TextBody2 = (props: TypographyProps) => <Typography {...props} variant='body2' />;
export const TextBody3 = (props: TypographyProps) => <Typography {...props} variant='body3' />;
export const TextError = (props: TypographyProps) => <Typography {...props} variant='error' />;
export const TextButton = (props: TypographyProps) => <Typography {...props} variant='button' />;
export const TextButton2 = (props: TypographyProps) => <Typography {...props} variant='button2' />;
export const TextLink = (props: TypographyProps) => <Typography {...props} variant='link' />;

export const ErrorSubtitle16 = (props: TypographyProps) => (
  <TextSubtitle16 {...props} color={(theme) => theme.palette.error.main} />
);
