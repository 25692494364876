// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import type { ReactNode } from 'react';
import { SnapshotDetails } from './SnapshotDetails';

export const CreditLineDetails = () => {
  const {
    loanSummary: { totalLineOfCreditCents, daysLeftToSpend },
  } = useRecoilValue(selectedLoanState);

  const data: Record<string, ReactNode> = {
    'Total Line of Credit': toCurrencyFromCents(totalLineOfCreditCents),
    'Days Left to Spend': `${daysLeftToSpend + 1} days`,
  };

  return <SnapshotDetails data={data} />;
};
