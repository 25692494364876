/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Button, type ButtonProps, styled, CircularProgress } from '@mui/material';

const $Primary = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.background.default,
  '&:hover': {
    backgroundColor: theme.palette.success.dark,
  },
}));

const $Blue = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.background.default,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const $Secondary = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.light,
  border: 'solid 1px',
}));

const $Grey = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(0, 56, 101, 0.08)',
  color: theme.palette.primary.main,
}));

const $Red = styled(Button)(({ theme }) => ({
  backgroundColor: '#a81d36',
  color: theme.palette.background.default,
  '&:hover': {
    backgroundColor: '#a81d36',
  },
}));

export const GreenButton = (props: ButtonProps) => <$Primary {...props} data-testid='GreenButton' />;
export const WhiteButton = (props: ButtonProps) => <$Secondary {...props} data-testid='WhiteButton' />;
export const BlueButton = (props: ButtonProps) => <$Blue {...props} data-testid='BlueButton' />;
export const GreyButton = (props: ButtonProps) => <$Grey {...props} data-testid='GreyButton' />;
export const RedButton = (props: ButtonProps) => <$Red {...props} data-testid='RedButton' />;
export const LoadingBlueButton = (props: ButtonProps) => (
  <BlueButton {...props}>
    <CircularProgress size='18px' color='inherit' />
  </BlueButton>
);
