/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { useState } from 'react';
import { ManagePaymentMethod } from '../ManagePaymentMethod/ManagePaymentMethod';
import { AutopayStates } from './AutopayStates';
import { AutopayTurnedOff } from './AutopayTurnedOff';
import { AutopayUpdated } from './AutopayUpdated';
import { ManageAutopay } from './ManageAutopay';
import { TurnOffAutopay } from './TurnOffAutopay';

export const AutopayStateSelector = () => {
  const [autopayState, setAutopayState] = useState<AutopayStates>(AutopayStates.MANAGE);

  const handleTurnOff = () => setAutopayState(AutopayStates.TURNINGOFF);
  const handleUpdatedSuccess = () => setAutopayState(AutopayStates.UPDATED);

  switch (autopayState) {
    case AutopayStates.MANAGE:
      return <ManageAutopay onTurnOff={handleTurnOff} onUpdatedSuccess={handleUpdatedSuccess} />;
    case AutopayStates.PAYMENTMANAGE:
      return <ManagePaymentMethod />;
    case AutopayStates.UPDATED:
      return <AutopayUpdated />;
    case AutopayStates.TURNINGOFF:
      return <TurnOffAutopay setAutopayState={setAutopayState} />;
    case AutopayStates.TURNEDOFF:
      return <AutopayTurnedOff />;
    default:
      return <ManageAutopay onTurnOff={handleTurnOff} onUpdatedSuccess={handleUpdatedSuccess} />;
  }
};
