/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled } from '@mui/material';
import { ActionLink } from 'components/ActionLink';
import { BlueButton, LoadingBlueButton } from 'components/Buttons';
import { DataRow } from 'components/DataRow';
import { TitledNumber } from 'components/TitledNumber';
import { TitleSubTitle } from 'components/TitleSubTitle';
import { TextH35, TextSubtitle1 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import dayjs from 'dayjs';
import { toCurrency } from 'helpers/numberHelpers';
import { dateTo } from 'helpers/stringHelpers';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import type { PaymentAccountT } from '../PaymentAccountModel';

type OneTimePaymentStep2PropsT = {
  data: PaymentAccountT;
  submitPayment: () => void;
  onBack: () => void;
  loading: boolean;
};

const $Subtitle = styled('div')({
  fontSize: '16px',
  lineHeight: '28px',
  letterSpacing: '0.38px',
});

const $title = styled(TextH35)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  lineHeight: '28px',
}));

export const OneTimePaymentReview = ({ data, submitPayment, onBack, loading }: OneTimePaymentStep2PropsT) => {
  const loan = useRecoilValue(selectedLoanState);
  const { bankAccountNumber } = loan.paymentInformation;
  const currentDate = new Date().toLocaleDateString();
  const isScheduledPayment = data.scheduledDate && !dayjs(data.scheduledDate).isSame(dayjs(), 'day');

  const accountToDisplay = () => {
    if (!data.accountNumber) {
      return 'Account on file';
    }

    return data.accountNumber === bankAccountNumber ? 'Account on file' : `Account *${data?.accountNumber?.slice(-4)}`;
  };

  const dateToDisplay = () =>
    isScheduledPayment ? dateTo('short_abbr', data.scheduledDate) : dateTo('short_abbr', currentDate);

  return (
    <>
      <TitleSubTitle
        title='Review & Submit Payment'
        subtitle={
          !isScheduledPayment && (
            <$Subtitle> Please note you cannot edit or cancel your payment once submitted </$Subtitle>
          )
        }
      />
      <VSpacer />
      <Box sx={(theme) => ({ color: theme.palette.common.black })}>
        <TitledNumber title={<$title>Your Payment Amount</$title>} amount={toCurrency(data.paymentAmount)} />
      </Box>
      <Box width='100%'>
        <VSpacer spacing={3} />
        <DataRow label='Account Number' data={loan.loanCode} />
        <VSpacer spacing={3} />
        <DataRow label='Day of Payment' data={dateToDisplay()} />
        <VSpacer spacing={3} />
        <DataRow label='Payment Method' data={accountToDisplay()} />
      </Box>
      <VSpacer spacing={5} />
      <ActionLink onClick={onBack}>Edit Payment Method</ActionLink>
      <VSpacer spacing={5} />
      <TextSubtitle1>Payments may take up to 5 business days to process.</TextSubtitle1>
      <VSpacer spacing={5} />
      {loading ? <LoadingBlueButton /> : <BlueButton onClick={submitPayment}>Submit Payment</BlueButton>}
    </>
  );
};
