import { useLocation } from 'react-router-dom';
import { selectedLoanCodeState } from 'store/LoanStore';
import { useRecoilState } from 'recoil';

export const RouteNames = {
  Overview: 'overview',
  Payments: 'payments',
  Documents: 'documents',
  Dashboard: 'dashboard',
} as const;

export const useLoanCodeInRoute = () => {
  const [selectedLoanCode, setSelectedLoanCode] = useRecoilState(selectedLoanCodeState);
  const routeNames = Object.values(RouteNames);
  const location = useLocation();
  const [, pathRoute, loanCode] = location.pathname.split('/');
  const currentRoute = routeNames.find((route) => route === pathRoute) ?? RouteNames.Overview;
  if (currentRoute !== RouteNames.Dashboard && loanCode !== selectedLoanCode) {
    setSelectedLoanCode(loanCode);
  }
};
