/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled, Box, useMediaQuery } from '@mui/material';
import { TextH1 } from 'components/Typographies';
import type { Loan } from 'store/LoanStore';
import { selectedLoanCodeState } from 'store/LoanStore';
import { Status } from 'components/Status';
import { VSpacer } from 'components/VSpacer';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Theme } from 'theme';
import { MakePaymentButton } from '../../Payments/MakePaymentButton';

const $AccountOverview = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const $LeftColumn = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const $ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'right',
  width: '100%',
  paddingTop: '17px',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
    padding: '0px',
  },
}));

type DashboardAccountPropsT = {
  loan: Loan;
};

export const DashboardAccountOverview = ({ loan }: DashboardAccountPropsT) => {
  const setSelectedLoanCode = useSetRecoilState(selectedLoanCodeState);
  const isAccountPastDue = loan.paymentInformation.paymentToBringAccountCurrentCents > 0;
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(Theme.breakpoints.only('lg'));

  const handleClickMakeAPayment = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelectedLoanCode(loan.loanCode);
    navigate(`/dashboard/${loan.loanCode}/onetime`);
  };

  return (
    <$AccountOverview>
      <$LeftColumn>
        <Status status={loan.loanSummary.accountStatus} />
        <VSpacer spacing={1.5} />
        <TextH1>{loan.loanSummary.merchantName}</TextH1>
      </$LeftColumn>
      <VSpacer spacing={4} hidden={isDesktop} />
      <$ButtonWrapper>
        {loan.isAccountPayable && (
          <MakePaymentButton
            sx={{ maxWidth: isDesktop ? '200px' : '320px' }}
            disabled={loan.paymentInformation.totalPayoffCents <= 0}
            isAccountPastDue={isAccountPastDue}
            onClick={handleClickMakeAPayment}
          />
        )}
      </$ButtonWrapper>
    </$AccountOverview>
  );
};
