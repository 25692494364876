// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { styled, Grid, useMediaQuery } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { selectedLoanState, selectedPlanState } from 'store/LoanStore';
import { useTheme } from '@mui/material/styles';
import { DataCell } from 'components/DataCell';
import { dateTo } from 'helpers/stringHelpers';
import { SectionBox } from 'components/SectionBox';
import { Statuses } from 'Status';

const $Grid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only('lg')]: {
    height: '253px',
  },
  [theme.breakpoints.only('md')]: {
    height: '272px',
  },
}));

export const LoanDetails = () => {
  const theme = useTheme();
  const notMobile = useMediaQuery(theme.breakpoints.up('md'));
  const {
    loanCode,
    loanSummary: { accountStatus },
    planInformation: { term, apr, purchaseWindowEndDate },
  } = useRecoilValue(selectedLoanState);
  const planTypeName = useRecoilValue(selectedPlanState);
  const purchaseWindowEndDateFormatted = dateTo('long_abbr', purchaseWindowEndDate);
  const lgCardSize = accountStatus === Statuses.CLOSED ? 12 : 7.5; // NOSONAR

  return (
    <SectionBox header='LOAN DETAILS' xs={12} lg={lgCardSize}>
      <Grid container spacing={{ xs: 0, md: 6, lg: 3.75 }}>
        <$Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <DataCell label='Plan Type' data={planTypeName} />
            </Grid>
            <Grid item xs={12}>
              <DataCell label='Term' data={`${term} months`} toolTip='Total repayment period' />
            </Grid>
            <Grid item xs={12}>
              <DataCell
                label='APR %'
                data={`${apr}%`}
                toolTip='The cost to obtain the line of credit expressed as an annual interest rate'
                noDivider={notMobile}
              />
            </Grid>
          </Grid>
        </$Grid>
        <$Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <DataCell label='Purchase Window End Date' data={purchaseWindowEndDateFormatted} />
            </Grid>
            <Grid item xs={12}>
              <DataCell label='Account Number' data={loanCode} noDivider />
            </Grid>
          </Grid>
        </$Grid>
      </Grid>
    </SectionBox>
  );
};
