/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

import { useRecoilState } from 'recoil';
import { toastIdState, toastsState, type ToastT } from 'store/ToastStore';

export function useToasts() {
  const [toasts, setToasts] = useRecoilState(toastsState);
  const [toastId, setToastId] = useRecoilState(toastIdState);

  const pushToast = (status: 'success' | 'fail', message: string) => {
    const newToast: ToastT = { id: toastId, status, message };
    setToastId((previousId) => previousId + 1);
    setToasts((previousItems) => [newToast, ...previousItems]);
  };

  const removeToast = (id: number) => {
    setToasts((previousState) => {
      const newState = previousState.filter((toast) => toast.id !== id);
      return newState;
    });
  };

  return { toasts, pushToast, removeToast };
}
