/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { Box, styled, IconButton, Fade } from '@mui/material';
import AutopayBanner from 'assets/autopayBanner.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { type Loan, selectedLoanCodeState, selectedLoanState, selectedLoanStateAutopayOff } from 'store/LoanStore';
import { autopayBannerDismissed, showingAutopayBanner } from 'store/BannerStore';
import { Desktop } from 'components/Desktop';
import { TextSubtitle16, TextBody3 } from 'components/Typographies';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { MobileAndTab } from 'components/MobileAndTab';
import { $BannerWrapper, $BannerDivider } from '../BannerHelpers';
import { SetUpButton } from './SetUpButton';

const $AutopaySetUpBanner = styled($BannerWrapper)(({ theme }) => ({
  display: 'flex',
  backgroundColor: 'rgba(5, 151, 242, 0.96)',
  color: theme.palette.background.default,
  flexDirection: 'column',
  padding: '32px 40px',
  [theme.breakpoints.down('lg')]: {
    padding: '16px',
  },
}));

const $Title = styled(Box)(({ theme }) => ({
  ...theme.typography.h1,
  lineHeight: '1.8',
  color: 'inherit',
  [theme.breakpoints.down('lg')]: {
    ...theme.typography.data4,
    lineHeight: '1.75',
    flexGrow: 1,
  },
}));

const $IconButton = styled(IconButton)({
  margin: '0 -5px 0 35px',
  color: 'inherit',
  padding: 0,
});

type AutopaySetUpBannerPropsT = {
  dashboard: boolean;
  payableLoans: Loan[];
};

const AUTOPAY_LABEL =
  'Life gets busy sometimes. Set Up Autopay and never worry about missing a payment towards your loan!';

export const AutopaySetUpBanner = ({ dashboard, payableLoans }: AutopaySetUpBannerPropsT) => {
  const { paymentInformation } = useRecoilValue(selectedLoanState);
  const setBannerDismissed = useSetRecoilState(autopayBannerDismissed);
  const setBannerShowing = useSetRecoilState(showingAutopayBanner);

  const location = useLocation();
  const isDashboardPage = location.pathname.toLowerCase().includes('dashboard');
  const loan = useRecoilValue(isDashboardPage ? selectedLoanStateAutopayOff : selectedLoanState);
  const setSelectedLoan = useSetRecoilState(selectedLoanCodeState);
  const showBannerDashboard = payableLoans.length === 1 && !payableLoans[0].paymentInformation.autopayEnabled;
  const showBannerSelectedLoan = !paymentInformation.autopayEnabled && loan.isAccountPayable;

  const showBanner = (dashboard && showBannerDashboard) || (!dashboard && showBannerSelectedLoan);

  const handleDismissBanner = () => {
    setBannerDismissed(true);
    setBannerShowing(false);
  };

  useEffect(() => {
    setBannerShowing(showBanner);
  }, [showBanner]);

  const handleAutopaySetupFromDashboard = () => {
    setSelectedLoan(loan.loanCode);
  };

  return (
    <>
      {showBanner && (
        <Fade in timeout={1000}>
          <$AutopaySetUpBanner data-testid='autopayBanner'>
            <Box display='flex' alignItems='center'>
              <img src={AutopayBanner} alt='autopay_icon' style={{ marginRight: '12px' }} />
              <$Title>Autopay</$Title>
              <Desktop>
                <$BannerDivider orientation='vertical' />
                <TextSubtitle16 sx={{ lineHeight: 1.71, flexGrow: 1 }}>{AUTOPAY_LABEL}</TextSubtitle16>
                <SetUpButton onClick={handleAutopaySetupFromDashboard} sx={{ marginLeft: '32px' }} />
              </Desktop>
              <$IconButton onClick={handleDismissBanner} data-testid='close_button_autopay'>
                <CloseIcon sx={{ color: 'inherit' }} data-testid='CloseIcon' />
              </$IconButton>
            </Box>
            <MobileAndTab>
              <TextBody3 sx={{ lineHeight: 2, margin: '12px 0 20px 0' }}>{AUTOPAY_LABEL}</TextBody3>
              <SetUpButton onClick={handleAutopaySetupFromDashboard} sx={{ alignSelf: 'center' }} />
            </MobileAndTab>
          </$AutopaySetUpBanner>
        </Fade>
      )}
    </>
  );
};
