/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { VSpacer } from 'components/VSpacer';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Content } from '../Content';
import { DashboardSkeleton } from './Skeleton/DashboardSkeleton';
import { DashboardContent } from './DashboardContent';
import { DashboardHeader } from './DashboardHeader';

export const Dashboard = () => (
  <>
    <DashboardHeader />
    <VSpacer spacing={4} />
    <Content>
      <Suspense fallback={<DashboardSkeleton />}>
        <DashboardContent />
      </Suspense>
    </Content>
    <Suspense>
      <Outlet />
    </Suspense>
  </>
);
