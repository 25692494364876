/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Divider } from '@mui/material';
import { BlueButton } from 'components/Buttons';
import { DataRow } from 'components/DataRow';
import { TitleSubTitle } from 'components/TitleSubTitle';
import { VSpacer } from 'components/VSpacer';
import { toCurrency } from 'helpers/numberHelpers';
import { dateTo, toSentenceCase } from 'helpers/stringHelpers';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { PaymentSummaryToPrint } from 'components/PaymentSummaryToPrint';
import { PrintIt } from 'components/PrintIt';
import dayjs from 'dayjs';
import type { PaymentAccountT } from '../PaymentAccountModel';

type OneTimePaymentStep3PropsT = {
  data: PaymentAccountT;
  onClose: () => void;
};

export const OneTimePaymentSummary = ({ data, onClose }: OneTimePaymentStep3PropsT) => {
  const loan = useRecoilValue(selectedLoanState);
  const { bankAccountType, bankAccountNumber } = loan.paymentInformation;
  const isScheduledPayment = data.scheduledDate && !dayjs(data.scheduledDate).isSame(dayjs(), 'day');
  const paymentDate = data.scheduledDate ? new Date(data.scheduledDate) : new Date();

  return (
    <>
      <TitleSubTitle
        title={isScheduledPayment ? `You're all set, thank you!` : `You're all set, thank you for your payment!`}
        subtitle=''
      />
      <VSpacer spacing={4} />

      <Box width='100%'>
        {isScheduledPayment ? (
          <DataRow
            label='Payment Method'
            data={`Account ${data.accountNumber ? `*${data.accountNumber.slice(-4)}` : 'on file'}`}
          />
        ) : (
          <DataRow
            label='Payment Type'
            data={`${toSentenceCase(data.accountType ?? bankAccountType)} ACH *${
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              (data.accountNumber || bankAccountNumber)?.slice(-4) ?? ''
            }`}
          />
        )}
        <Divider />
        <DataRow label='Payment Amount' data={toCurrency(data.paymentAmount)} />
        <Divider />
        <DataRow label='Payment Date' data={dateTo('short_abbr', paymentDate.toLocaleDateString())} />
        <Divider />
      </Box>
      <VSpacer spacing={5} />

      <PrintIt linkText='Print Summary'>
        <PaymentSummaryToPrint data={data} data-testid='SummaryToPrint' />
      </PrintIt>
      <VSpacer spacing={5} />

      <BlueButton onClick={onClose}>Close</BlueButton>
    </>
  );
};
