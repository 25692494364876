// Copyright © Bankers Healthcare Group, Inc. All rights reserved.

declare global {
  interface Window {
    server_config: Record<string, string>;
  }
}

export default {
  API_DOMAINS: {
    AUTHORITY_DOMAIN: window.server_config?.AUTHORITY ?? '',
    PRIVACY_DOMAIN: window.server_config?.PRIVACY_DOMAIN ?? '',
    FEATURE_DOMAIN: window.server_config?.FEATURE_MANAGER_DOMAIN ?? '',
    BORROWER_PORTAL_DOMAIN: window.server_config?.BORROWER_PORTAL_DOMAIN ?? '',
  },
  SUPPORT_INFO: {
    Phone_Number: window.server_config?.Support_Phone_Number ?? '',
    Email: window.server_config?.Support_Email ?? '',
    Address: window.server_config?.Support_Address ?? '',
    Corporate_HQ_Address: window.server_config?.Corporate_HQ_Address ?? '',
    Financial_HQ_Address: window.server_config?.Financial_HQ_Address ?? '',
  },
};
