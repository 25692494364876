/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { EmailIcon } from 'components/Icons';
import { useRecoilValue } from 'recoil';
import { TextH35, TextData4 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import { selectedLoanCodeState } from 'store/LoanStore';

export const PayWithCheckDetails = () => {
  const loanCode = useRecoilValue<string>(selectedLoanCodeState);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box data-testid='paybycheck' color={(theme) => theme.palette.common.black}>
      <TextH35>Make check payable to: </TextH35>
      {isMobileOrTablet && <VSpacer />}
      <TextData4>Pinnacle Bank - NaluPay</TextData4>
      <VSpacer spacing={2} />
      <TextH35>Include Account Number </TextH35>
      <TextData4>{loanCode}</TextData4>
      <TextH35> in the memo.</TextH35>
      <VSpacer spacing={2} />
      <TextH35>Mail check to: </TextH35>
      <VSpacer spacing={2} />
      <Grid container flexDirection='row' justifyContent='center'>
        <Grid item component={EmailIcon} alignSelf='center' />
        <Grid item component={Box} sx={(theme) => ({ paddingLeft: theme.spacing(3) })}>
          <TextData4>Pinnacle Bank - NaluPay</TextData4>
          <VSpacer spacing={2} />
          <TextData4>PO BOX 306378</TextData4>
          <VSpacer spacing={2} />
          <TextData4>Nashville, TN 37230 - 6378 </TextData4>
        </Grid>
      </Grid>
      <VSpacer spacing={2} />
      <TextH35 sx={(theme) => ({ color: theme.palette.secondary.dark })}>
        Please allow enough time for your check to arrive on or before the due date.
      </TextH35>
    </Box>
  );
};
