/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { styled, Button, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const $DownloadButton = styled(Button)(({ theme }) => ({
  height: '38px',
  maxWidth: '129px',
  padding: '8px 12px',
  backgroundColor: theme.palette.secondary.light,
  borderRadius: '19px',
  boxShadow: theme.shadows[0],
  ...theme.typography.button2,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '38px',
    width: '38px',
    '> span': {
      margin: 0,
    },
  },
}));

const $Download = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  ...theme.typography.button2,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const $DownloadIcon = styled(FileDownloadOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: '22px !important',
}));

type DocumentDownloadPropsT = {
  onDownload?: () => void;
};

export const DocumentDownloadButton = ({ onDownload }: DocumentDownloadPropsT) => (
  <$DownloadButton variant='contained' onClick={onDownload} startIcon={<$DownloadIcon />}>
    <$Download>Download</$Download>
  </$DownloadButton>
);
