/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import type { PublicClientApplication } from '@azure/msal-browser';
import { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { setAuthorizationHeader } from 'helpers/axiosHelpers';
import { axios } from 'helpers/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { logFrontendError } from '../utils/logging';

declare module 'axios' {
  export interface AxiosError {
    data?: unknown;
  }
}

export const useAxiosSetup = () => {
  const navigate = useNavigate();

  const axiosSetup = (msalInstance: PublicClientApplication) => {
    axios.interceptors.request.use(async (config) =>
      config?.useAuth ? setAuthorizationHeader(config, msalInstance) : config,
    );

    const RETRY_TIMES = [0, 1000, 2000, 4000, 8000, 16_000];
    const RETRY_TYPES = new Set([AxiosError.ERR_NETWORK, AxiosError.ERR_BAD_REQUEST, AxiosError.ERR_BAD_RESPONSE]);

    axiosRetry(axios, {
      retries: 5,
      retryDelay: (count) => RETRY_TIMES[count],
      retryCondition: (error: AxiosError) => (error.config?.useRetry ?? false) && RETRY_TYPES.has(error.code ?? ''),
    });

    axios.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        error.data = {};
        if (error.config?.useGlobalError) {
          logFrontendError(error as Error | AxiosError, msalInstance);
          navigate('/error');
        }

        return Promise.reject(error);
      },
    );

    window.addEventListener('error', ({ error }) => {
      logFrontendError(error as Error | AxiosError, msalInstance);
    });
  };

  return { axiosSetup };
};
