/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import type { Configuration } from '@azure/msal-browser';

const origin = window.location.origin.toString();

export const msalConfig: Configuration = {
  auth: {
    clientId: window.server_config.APP_ID,
    authority: window.server_config.AUTHORITY,
    knownAuthorities: window.server_config.KNOWN_AUTHORITIES.split(','),
    redirectUri: origin,
    postLogoutRedirectUri: origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [window.server_config.SCOPE],
};
