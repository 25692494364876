/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Grid, Box } from '@mui/material';
import { SkeletonLoader } from 'components/SkeletonLoader';
import { SectionBox } from 'components/SectionBox';
import { VSpacer } from 'components/VSpacer';
import { MobileAndTab } from 'components/MobileAndTab';

export const PaymentMethodSkeleton = () => (
  <SectionBox header='PAYMENT METHOD' xs={12} lg={5}>
    <Grid container alignItems='center' flexDirection='column'>
      <VSpacer spacing={5} />
      <SkeletonLoader width='189' height='87'>
        <rect x='0' y='15' rx='16' ry='16' width='51' height='51' />
        <rect x='59' y='15' rx='9.5' ry='9.5' width='100' height='19' />
        <rect x='59' y='38' rx='7.5' ry='7.5' width='130' height='15' />
        <rect x='59' y='55' rx='7.5' ry='7.5' width='130' height='15' />
      </SkeletonLoader>
      <VSpacer spacing={10} />
      <SkeletonLoader width='320' height='41'>
        <rect x='0' y='0' rx='20.5' ry='20.5' width='320' height='41' />
      </SkeletonLoader>
      <VSpacer spacing={5} />
      <MobileAndTab>
        <Box sx={{ marginBottom: '25px' }} />
      </MobileAndTab>
    </Grid>
  </SectionBox>
);
