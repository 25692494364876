// Copyright © Bankers Healthcare Group, Inc. All rights reserved.
import { useRecoilValue } from 'recoil';
import { toCurrencyFromCents, percentage } from 'helpers/numberHelpers';
import { selectedLoanState } from 'store/LoanStore';
import { SnapshotProgress } from './SnapshotProgress';

export const CreditLineProgress = () => {
  const {
    loanSummary: { totalLineOfCreditCents, creditUsedCents, creditRemainingCents },
  } = useRecoilValue(selectedLoanState);

  return (
    <SnapshotProgress
      header='You Can Spend'
      amount={toCurrencyFromCents(creditRemainingCents)}
      progressPercentage={percentage(creditUsedCents, totalLineOfCreditCents)}
      caption={`Credit Used: ${toCurrencyFromCents(creditUsedCents)} of ${toCurrencyFromCents(totalLineOfCreditCents)}`}
    />
  );
};
