/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { stateNames } from 'States';
import { Box } from '@mui/material';
import { emailValidation, nameValidation, stateValidation } from 'validation/validators';
import type { Control } from 'react-hook-form';
import { FormPhoneField } from 'components/FormComponents/FormPhoneField';
import { FormAutocomplete } from '../FormComponents/FormAutocomplete';
import { FormTextField } from '../FormComponents/FormTextField';
import type { PrivacyData } from './PrivacyData';

type PrivacyFormFieldsProps = {
  control: Control<PrivacyData>;
};

export const PrivacyFormFields = ({ control }: PrivacyFormFieldsProps) => (
  <Box display='flex' flexDirection='column' gap='16px'>
    <FormTextField
      name='FirstName'
      label='First Name'
      placeholder='John'
      control={control}
      rules={nameValidation('first name')}
      inputProps={{ maxLength: 255 }}
      data-testid='firstName'
    />
    <FormTextField
      name='LastName'
      label='Last Name'
      placeholder='Smith'
      control={control}
      rules={nameValidation('last name')}
      inputProps={{ maxLength: 255 }}
      data-testid='lastName'
    />
    <FormTextField
      name='UserEmail'
      label='Email'
      placeholder='jane.doe@email.com'
      control={control}
      rules={emailValidation}
      inputProps={{ maxLength: 100 }}
      data-testid='email'
    />
    <FormPhoneField name='Phone' label='Mobile Phone' control={control} data-testid='phoneNumber' />
    <FormAutocomplete
      name='State'
      label='State'
      options={stateNames}
      control={control}
      rules={stateValidation}
      data-testid='stateDropdown'
    />
  </Box>
);
