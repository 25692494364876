// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import CircularProgress from '@mui/material/CircularProgress';
import type { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { styled } from '@mui/material';

export type CircularProgressWithLabelPropsT = CircularProgressProps & {
  value: number;
};

const $ContainerBox = styled(Box)({
  position: 'relative',
  display: 'inline-flex',
});

const $LabelBox = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 45,
});

const $Typography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '18px',
  component: 'div',
  variant: 'caption',
}));

const $CircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

let initialValue: number;
const normaliseValueModifier = 100;

const CircularProgressWithLabel = (props: CircularProgressWithLabelPropsT) => {
  const normalise = (value: number) => (value * normaliseValueModifier) / initialValue;

  useEffect(() => {
    initialValue = props.value;
  }, []);

  return (
    <$ContainerBox>
      <$CircularProgress variant='determinate' value={normalise(props.value)} thickness={2} size={45} />
      <$LabelBox>
        <$Typography>{`${Math.round(props.value)}`}</$Typography>
      </$LabelBox>
    </$ContainerBox>
  );
};

export default CircularProgressWithLabel;
