// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { TextH2, TextH35, TextSubtitle1 } from 'components/Typographies';
import { BlueButton } from 'components/Buttons';
import { DataRow } from 'components/DataRow';
import { styled, Divider, Box } from '@mui/material';
import { VSpacer } from 'components/VSpacer';
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import { dateTo } from 'helpers/stringHelpers';
import { useState, Suspense } from 'react';
import { usePayments } from 'hooks/usePayments';
import { useLoansStateWithRefresh } from 'hooks/useLoanStateWithRefresh';
import { type PaymentDetailProps } from './PaymentHistoryDetails';

const $Title = styled(TextH2)({
  textAlign: 'center',
  marginTop: '5px',
  marginBottom: '16px',
  lineHeight: '28px',
});

const $Subtitle = styled(TextSubtitle1)({
  textAlign: 'center',
  lineHeight: '24px',
  marginBottom: '32px',
});

const $Label = styled(TextH35)({
  lineHeight: '28px',
});

const $Data = styled('span')({
  fontSize: '18px',
  lineHeight: '28px',
  letterSpacing: '0.43px',
});

const $Divider = styled(Divider)({
  margin: '16px 0px 16px 0px',
  width: '100%',
  justifyContent: 'center',
});

const $DataRow = styled(DataRow)({
  marginLeft: '8px',
});

export const CancelPayment = ({ paymentDate, amountInCents, paymentId }: PaymentDetailProps) => {
  const { navigateToParentRoute } = useNavigationHelpers();
  const close = () => navigateToParentRoute();
  const [canceled, setCanceled] = useState(false);
  const { cancelPayment } = usePayments();
  const { refreshLoans } = useLoansStateWithRefresh();

  const processCancelPayment = () => {
    cancelPayment(paymentId)
      .then(() => {
        setCanceled(true);
        refreshLoans();
      })
      .catch(() => {
        throw new Error('cancel payment failed');
      });
  };

  return (
    <Suspense>
      <$Title>{canceled ? 'Cancelation Confirmation' : 'Verify Payment Cancelation'}</$Title>
      <$Subtitle>
        {canceled ? 'Your payment has been canceled.' : 'Are you sure you want to cancel this payment?'}
      </$Subtitle>
      <$DataRow
        label={<$Label>Payment Date</$Label>}
        data={<$Data>{dateTo('really_short_abbr', paymentDate.toString())}</$Data>}
      />
      <$Divider />
      <$DataRow label={<$Label>Amount</$Label>} data={<$Data>{toCurrencyFromCents(amountInCents)}</$Data>} />
      <VSpacer spacing={6} />
      <Box textAlign='center'>
        {canceled && <BlueButton onClick={close}>Close</BlueButton>}
        {!canceled && <BlueButton onClick={processCancelPayment}>Cancel Payment</BlueButton>}
      </Box>
    </Suspense>
  );
};
