/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Container, Box, styled } from '@mui/material';
import { BannerHouse } from 'components/BannerComponents/BannerHouse';
import { type ReactNode, Suspense, useState } from 'react';

const $BodyContainer = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'relative',
  top: 0,
  left: 0,
});

const $ContentContainer = styled(Box)({
  display: 'flex',
  marginTop: '20px',
});

export const $Center = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0 16px !important',
  },
  [theme.breakpoints.only('md')]: {
    padding: '0 40px !important',
  },
  [theme.breakpoints.only('lg')]: {
    padding: '0 160px !important',
  },
}));

const $ContentCenter = styled($Center)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

export const Content = ({ children }: { children?: ReactNode }) => {
  const [bannerHeight, setBannerHeight] = useState(0);
  const contentBottomMargin = bannerHeight > 92 ? bannerHeight + 28 : 120; // NOSONAR

  const onHeightChange = (height: number) => {
    setBannerHeight(height);
  };

  return (
    <$BodyContainer>
      <$ContentContainer mb={`${contentBottomMargin}px`}>
        <$ContentCenter maxWidth='lg'>{children}</$ContentCenter>
      </$ContentContainer>
      <Suspense>
        <BannerHouse onHeightChange={onHeightChange} />
      </Suspense>
    </$BodyContainer>
  );
};
