// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { Dialog, styled } from '@mui/material';
import { DesktopAndTab } from 'components/DesktopAndTab';
import { Mobile } from 'components/Mobile';
import type { ChangeEvent } from 'react';
import { SessionCountdown } from './SessionCountdown';
import { SessionDialogActions } from './SessionDialogActions';
import { SessionDialogContent } from './SessionDialogContent';
import { SessionDialogTitle } from './SessionDialogTitle';
import type { SessionExtensionModalPropsT } from './SessionExtensionModalProps';

const $Dialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    color: '#FAFAFA',
    [theme.breakpoints.up('md')]: {
      maxWidth: '450px',
    },
  },
}));

export const SessionExtensionModal = (props: SessionExtensionModalPropsT) => {
  const handleClose = (_event: ChangeEvent<HTMLInputElement>) => {
    props.onCloseModal();
  };

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <DesktopAndTab>
        <$Dialog
          data-testid='dialog'
          open
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <SessionCountdown {...props} />
          <SessionDialogTitle />
          <SessionDialogContent />
          <SessionDialogActions {...props} />
        </$Dialog>
      </DesktopAndTab>
      <Mobile>
        <$Dialog
          data-testid='dialog'
          open
          fullScreen
          fullWidth
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <SessionCountdown {...props} />
          <SessionDialogTitle />
          <SessionDialogContent />
          <SessionDialogActions {...props} />
        </$Dialog>
      </Mobile>
    </div>
  );
};
