/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

export const openBlob = (file: Blob) => {
  const blob = new Blob([file], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(blob);
  window.open(fileURL);
  URL.revokeObjectURL(fileURL);
};

export const downloadBlob = (file: Blob, fileName: string) => {
  const blob = new Blob([file], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(fileURL);
};
