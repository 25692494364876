/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Tooltip } from '@mui/material';
import AutopayOn from 'assets/autopayOn.svg';
import AutopayOff from 'assets/autopayOff.svg';

type AutopayIconPropsT = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  autopayOn: boolean;
};

export const AutopayIconWithTooltip = ({ autopayOn, ...props }: AutopayIconPropsT) => (
  <Box display='flex' alignItems='center'>
    <Tooltip title={autopayOn ? 'Autopay On' : 'Sign up and never miss a payment'}>
      <img {...props} src={autopayOn ? AutopayOn : AutopayOff} alt='AutoPayIcon' />
    </Tooltip>
  </Box>
);
