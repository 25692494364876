// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

export const TransactionType = {
  CHARGE: 'CHARGE',
  PAYMENT: 'PAYMENT',
  CREDIT_OFFSET: 'CREDIT_OFFSET',
  DEBIT_OFFSET: 'DEBIT_OFFSET',
  MANUAL_FEE: 'MANUAL_FEE',
  LOAN: 'LOAN',
} as const;

export type TransactionTypeT = Record<
  keyof typeof TransactionType,
  typeof TransactionType[keyof typeof TransactionType]
>;
