/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { useState } from 'react';
import dayjs, { type Dayjs } from 'dayjs';
import { useController, type Control } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { firstPaymentWindowDate, selectedLoanState } from 'store/LoanStore';
import { FormContainerStatuses, FormOutlinedContainer } from 'components/FormComponents/FormOutlinedContainer';
import { FormControl } from '@mui/material';
import { Accordion } from 'components/Accordion';
import type { PaymentAccountT } from '../PaymentAccountModel';
import { SchedulePaymentHeader } from './SchedulePaymentHeader';
import { SchedulePaymentDetails } from './SchedulePaymentDetails';

type SchedulePaymentProps = {
  control: Control<PaymentAccountT>;
  previouslySelectedDate: string | undefined;
};

export const SchedulePayment = ({ control, previouslySelectedDate }: SchedulePaymentProps) => {
  const loan = useRecoilValue(selectedLoanState);
  const today = dayjs();
  const paymentWindowStartDate = useRecoilValue(firstPaymentWindowDate);
  const defaultSelectedDate = today.isBefore(paymentWindowStartDate) ? paymentWindowStartDate : today;
  const initialSelectedDate = previouslySelectedDate ? dayjs(previouslySelectedDate) : defaultSelectedDate;
  const [selectedDate, setSelectedDate] = useState<Dayjs>(initialSelectedDate);

  const { field, fieldState } = useController({
    control,
    name: 'scheduledDate',
    defaultValue: initialSelectedDate.format('MM/DD/YYYY'),
  });

  const onSelectedDateChange = (date: Dayjs) => {
    setSelectedDate(date);
    field.onChange(date.format('MM/DD/YYYY'));
  };

  return (
    <FormControl fullWidth>
      <FormOutlinedContainer status={fieldState.error ? FormContainerStatuses.ERROR : FormContainerStatuses.NORMAL}>
        <Accordion
          header={<SchedulePaymentHeader selectedDate={selectedDate} />}
          sxSummary={{ minHeight: '54px' }}
          sxDetails={{ padding: '0 0 24px 0' }}
        >
          <SchedulePaymentDetails
            selectedDate={selectedDate}
            onSelectedDateChange={onSelectedDateChange}
            dueDate={dayjs(loan.paymentInformation.paymentDueDate)}
          />
        </Accordion>
      </FormOutlinedContainer>
    </FormControl>
  );
};
