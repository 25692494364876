/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Grid, styled } from '@mui/material';
import { AutopayRow } from 'components/AutopayRow';
import { MakePaymentButton } from 'components/Content/Payments/MakePaymentButton';
import { DataRow } from 'components/DataRow';
import { SectionBox } from 'components/SectionBox';
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { useRecoilValue } from 'recoil';
import { Statuses } from 'Status';
import { isSelectedLoanPastDue, selectedLoanState } from 'store/LoanStore';
import { ScheduledPaymentDataRow } from 'components/Content/Overview/UpcomingPayment/ScheduledPaymentDataRow';
import { isFeatureOn } from 'store/FeatureFlagStore';
import { dateTo } from 'helpers/stringHelpers';

const $Container = styled(Grid)({
  display: 'flex',
  width: '100%',
  height: '223px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const $Box = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  alignSelf: 'center',
  height: '140px',
});

export const UpcomingPayment = () => {
  const {
    loanSummary: { creditUsedCents, accountStatus },
    paymentInformation: { paymentDueInCents, paymentDueDate, autopayEnabled, totalPayoffCents },
  } = useRecoilValue(selectedLoanState);

  const scheduledPaymentFF = useRecoilValue(isFeatureOn('SCHEDULE_PAYMENT_FLAG'));
  const hasBorrowerDrawnDown = creditUsedCents !== 0;
  const isAccountClosed = accountStatus === Statuses.CLOSED;
  const isAccountPastDue = useRecoilValue(isSelectedLoanPastDue);
  const displayDueDate = hasBorrowerDrawnDown ? dateTo('short_abbr', paymentDueDate) : '———';

  return isAccountClosed ? null : (
    <SectionBox header='UPCOMING PAYMENT' xs={12} lg={4.5}>
      <$Container container direction={hasBorrowerDrawnDown ? 'column' : 'row'}>
        <$Box item>
          <DataRow
            label={isAccountPastDue ? 'Total Amount Due' : 'Amount Due'}
            data={toCurrencyFromCents(paymentDueInCents)}
            error={isAccountPastDue}
          />
          {scheduledPaymentFF ? (
            <ScheduledPaymentDataRow
              AutopayEnabled={autopayEnabled}
              PaymentDueDate={paymentDueDate}
              BorrowerDrawnDown={hasBorrowerDrawnDown}
            />
          ) : (
            <DataRow label={autopayEnabled ? 'Payment Scheduled' : 'Payment Due Date'} data={displayDueDate} />
          )}
          <AutopayRow autopayOn={autopayEnabled} linkText={autopayEnabled ? 'Manage' : 'Set Up'} />
        </$Box>
        {hasBorrowerDrawnDown && (
          <MakePaymentButton disabled={totalPayoffCents <= 0} isAccountPastDue={isAccountPastDue} />
        )}
      </$Container>
    </SectionBox>
  );
};
