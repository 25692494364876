/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Fade, Popper, styled } from '@mui/material';
import type { ReactNode, Ref } from 'react';

const $Popper = styled(Popper)({
  width: '320px',
  height: '160px',
  '&[data-popper-placement^="bottom"] > #arrow': {
    top: '-11px',
  },
  padding: '24px 24px',
  boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#ffffff',
  borderRadius: '4px',
});

const $Arrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '18px',
  height: '18px',
  background: `conic-gradient(at 50% 1%,transparent 135deg,${theme.palette.background.paper} 0,${theme.palette.background.paper} 225deg, transparent 0)`,
}));

type CustomPopperProps = {
  anchorElement?: HTMLButtonElement;
  ref?: Ref<HTMLDivElement>;
  children: ReactNode;
};

export const CustomPopper = ({ anchorElement, ref, children }: CustomPopperProps) => (
  <$Popper
    data-testid='popper'
    open={Boolean(anchorElement)}
    ref={ref}
    anchorEl={anchorElement}
    keepMounted
    disablePortal
    modifiers={[
      {
        name: 'offset',
        options: {
          offset: [0, 11], // NOSONAR
        },
      },
    ]}
  >
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350}>
        <>
          <$Arrow id='arrow' data-popper-arrow />
          {children}
        </>
      </Fade>
    )}
  </$Popper>
);
