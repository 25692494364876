/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled } from '@mui/material';
import { AutopayIconWithTooltip } from 'components/AutopayIconWithTooltip';
import { TextH2 } from 'components/Typographies';
import { dateTo } from 'helpers/stringHelpers';
import { type ReactNode } from 'react';

const AutopayIcon = styled(AutopayIconWithTooltip)({
  width: '20px',
  height: '18.5px',
  marginLeft: '8px',
});

export const amountDueLabels: Record<string, string> = {
  ACTIVE: 'Amount Due',
  SUSPENDED: 'Total Amount Due',
  CLOSED: 'Final Loan Amount',
  UNKNOWN: 'Amount Due',
};

export function getDueDateLabel(isAccountClosed: boolean, autopayEnabled: boolean, isDesktop: boolean) {
  if (isAccountClosed) {
    return 'Final Payment Date';
  }

  if (autopayEnabled) {
    return (
      <Box display='flex'>
        Payment Scheduled <AutopayIcon hidden={isDesktop} autopayOn />
      </Box>
    );
  }

  return 'Payment Due Date';
}

export function getDueDateValue(dueDate: string, autopayEnabled: boolean, isMobile: boolean, isDesktop: boolean) {
  if (isMobile) {
    return dateTo('numeric', dueDate);
  }

  return (
    <Box display='flex'>
      {dashboardData(dateTo('short_abbr', dueDate))}
      <AutopayIcon hidden={!autopayEnabled || !isDesktop} autopayOn={autopayEnabled} />
    </Box>
  );
}

export const dashboardData = (node: ReactNode, error = false) => (
  <TextH2 sx={(theme) => ({ color: error ? theme.palette.error.main : theme.palette.common.black })}>{node}</TextH2>
);
