/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { useRef } from 'react';
import type { ReactNode } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PrintIcon } from 'components/Icons';
import { ActionLink } from './ActionLink';

type PrintItPropsT = {
  children?: ReactNode;
  linkText: string | ReactNode;
};

export const PrintIt = ({ children, linkText }: PrintItPropsT) => {
  const componentReference = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentReference.current,
  });

  return (
    <>
      <ActionLink onClick={handlePrint}>
        <PrintIcon />
        {linkText}
      </ActionLink>
      <div style={{ display: 'none' }} data-testid='printDiv'>
        <div ref={componentReference}>{children}</div>
      </div>
    </>
  );
};
