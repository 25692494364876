/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled } from '@mui/material';
import { AutopayRow } from 'components/AutopayRow';
import { DataRow } from 'components/DataRow';
import { Status } from 'components/Status';
import { VSpacer } from 'components/VSpacer';
import { dateTo } from 'helpers/stringHelpers';
import { useRecoilValue } from 'recoil';
import { isSelectedLoanPastDue, selectedLoanState } from 'store/LoanStore';

const $Box = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '195px',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignSelf: 'center',
  [theme.breakpoints.only('lg')]: {
    marginBottom: '32px',
  },
}));

export const PaymentDetails = () => {
  const {
    loanSummary: { accountStatus },
    paymentInformation: { paymentDueDate, billingCycleEndDate, autopayEnabled },
  } = useRecoilValue(selectedLoanState);

  const isAccountPastDue = useRecoilValue(isSelectedLoanPastDue);

  const handleBillingCycleEndDate = () => {
    if (billingCycleEndDate) {
      return (
        <DataRow
          label='Billing Cycle End Date'
          data={billingCycleEndDate ? dateTo('short_abbr', billingCycleEndDate) : ''}
        />
      );
    }

    return <VSpacer />;
  };

  return (
    <$Box>
      <DataRow label='Status' data={<Status status={accountStatus} />} />
      <AutopayRow autopayOn={autopayEnabled} linkText={autopayEnabled ? 'Manage' : 'Set Up'} />
      <DataRow
        label={autopayEnabled ? 'Payment Scheduled' : 'Payment Due Date'}
        data={dateTo('short_abbr', paymentDueDate)}
        error={isAccountPastDue}
      />
      {handleBillingCycleEndDate()}
    </$Box>
  );
};
