/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { useState } from 'react';
import serviceEndpoints from 'config/endpointConfig';
import { axios } from 'helpers/axiosInstance';

export interface TagData {
  action: string;
  transaction: {
    applicationCode: string;
    applicationId: string;
    merchant: {
      merchantId: string;
      merchantName: string;
    };
    transactionCode: string;
    transactionId: string;
  };
}

export type TagStoreT = {
  tagData: TagData;
  getTagResult: (dataString: string) => void;
};

const defaultData: TagData = {
  action: '',
  transaction: {
    applicationCode: '',
    applicationId: '',
    merchant: {
      merchantId: '',
      merchantName: '',
    },
    transactionCode: '',
    transactionId: '',
  },
};

export const tagStoreInitialState: TagStoreT = {
  tagData: defaultData,
  getTagResult: () => defaultData,
};

export function useTagStore() {
  const [tagData, setTagData] = useState(tagStoreInitialState.tagData);

  const errorData: TagData = {
    action: 'tagError',
    transaction: {
      applicationCode: '',
      applicationId: '',
      merchant: {
        merchantId: '',
        merchantName: 'tagError',
      },
      transactionCode: '',
      transactionId: '',
    },
  };

  const getTagResult = async (dataString: string) => {
    try {
      const config = { ...serviceEndpoints.BORROWER_PORTAL.TAG_SUCCESS };
      config.params = { data: dataString };
      config.data = dataString;
      const { data } = await axios<TagData>(config);

      switch (data.action) {
        case 'None': {
          data.action = 'invalid';
          break;
        }

        case 'Approved': {
          data.action = 'accepted';
          break;
        }

        case 'Rejected': {
          data.action = 'rejected';
          break;
        }

        default:
      }

      setTagData(data);
    } catch (error: unknown) {
      console.error(error); // NOSONAR
      setTagData(errorData);
    }
  };

  return {
    tagData,
    getTagResult,
  };
}
