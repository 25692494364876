// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { Box, type BoxProps } from '@mui/material';
import { SkeletonLoader } from 'components/SkeletonLoader';
import { Theme } from 'theme';

export type DataCellPropsT = {
  noDivider?: boolean;
  spacing?: number;
} & BoxProps;

export const DataCellSkeleton = ({ noDivider, spacing = 1, ...rest }: DataCellPropsT) => {
  const gap = Number(Theme.spacing(spacing).slice(0, -2));
  const height = noDivider ? 44 + gap : 76 + gap;
  return (
    <Box {...rest}>
      <SkeletonLoader height={height}>
        <rect x='0' y='0' rx='11' ry='11' width='130' height='22' />
        <rect x='0' y={22 + gap} rx='11' ry='11' width='130' height='22' />
        {!noDivider && <rect x='0' y={59 + gap} rx='1' ry='1' width='100%' height='2' />}
      </SkeletonLoader>
    </Box>
  );
};
