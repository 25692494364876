/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { AccountBalanceRounded } from '@mui/icons-material';
import { Box, Grid, styled } from '@mui/material';
import { TextBody3, TextH1, TextH3 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import { toSentenceCase } from 'helpers/stringHelpers';

const $BankIcon = styled(AccountBalanceRounded)(({ theme }) => ({
  color: theme.palette.success.main,
  backgroundColor: theme.palette.success.light,
  padding: '10px',
  width: '51px',
  height: 'auto',
  borderRadius: '16px',
}));

const $Container = styled(Grid)({
  borderRadius: '16px',
  padding: '12px 4px',
  width: '100%',
});

type PaymentMethodSummaryPropsT = {
  accountType: string;
  accountNumber: string;
  routingNumber: string;
  border?: boolean;
};

export const PaymentMethodCard = ({
  accountNumber,
  accountType,
  routingNumber,
  border = true,
}: PaymentMethodSummaryPropsT) => {
  if (accountNumber) {
    return (
      <$Container
        container
        columnSpacing={1}
        direction='row'
        justifyContent={border ? 'flex-start' : 'center'}
        sx={{ border: border ? 'solid 1px #E0E0E0' : '', margin: '0px' }}
      >
        <Grid item alignSelf='center' paddingRight='4px'>
          <$BankIcon />
        </Grid>
        <Grid item>
          <Box sx={{ textAlign: 'left' }}>
            <TextH1 sx={{ marginBottom: '4px' }}>{toSentenceCase(accountType)}</TextH1>
            <TextBody3>Routing #: {routingNumber}</TextBody3>
            <br />
            <TextBody3>Account #: {accountNumber}</TextBody3>
          </Box>
        </Grid>
      </$Container>
    );
  }

  return (
    <>
      <VSpacer spacing={4} />
      <TextH3 sx={{ textAlign: 'center' }}>You do not have a stored payment method.</TextH3>
      <VSpacer spacing={4} />
    </>
  );
};
