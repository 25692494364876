/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Suspense } from 'react';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { SectionBox } from '../../SectionBox';
import { AutopayDocuments } from './AutopayDocuments';
import { Statements } from './Statements';

const loading = <div>Loading...</div>;

export const Documents = () => (
  <Grid container spacing={2.5}>
    <SectionBox header='STATEMENTS' xs={12}>
      <Suspense fallback={loading}>
        <Statements />
      </Suspense>
    </SectionBox>
    <SectionBox header='AUTOPAY' xs={12}>
      <Suspense fallback={loading}>
        <AutopayDocuments />
      </Suspense>
    </SectionBox>
    <Suspense>
      <Outlet />
    </Suspense>
  </Grid>
);
