/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Drawer, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import type { ReactNode } from 'react';

const DRAWER_WIDTH = '520px';

const $Drawer = styled(Drawer)(({ theme }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    padding: '32px 40px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '32px 16px',
    },
  },
}));

type PlainFlyoutPropsT = {
  children: ReactNode;
};

export const PlainFlyout = ({ children }: PlainFlyoutPropsT) => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <$Drawer variant='temporary' anchor='right' open onClose={handleClose}>
        {children}
      </$Drawer>
    </Box>
  );
};
