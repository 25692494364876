/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { createTheme, type ThemeOptions } from '@mui/material/styles';
import type { Shadows } from '@mui/material/styles/shadows';
import { createBreakpoints } from '@mui/system';
import type { CSSProperties } from 'react';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: false;
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    h2_5: CSSProperties;
    h3_5: CSSProperties;
    data1: CSSProperties;
    data2: CSSProperties;
    data3: CSSProperties;
    data4: CSSProperties;
    body3: CSSProperties;
    error: CSSProperties;
    button2: CSSProperties;
    link: CSSProperties;
    subtitle1_6: CSSProperties;
  }
  interface TypographyVariantsOptions {
    h2_5: CSSProperties;
    h3_5: CSSProperties;
    data1: CSSProperties;
    data2: CSSProperties;
    data3: CSSProperties;
    data4: CSSProperties;
    body3: CSSProperties;
    error: CSSProperties;
    button2: CSSProperties;
    link: CSSProperties;
    subtitle1_6: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h2_5: true;
    h3: true;
    h3_5: true;
    h4: true;
    data1: true;
    data2: true;
    data3: true;
    data4: true;
    body1: true;
    body2: true;
    body3: true;
    error: true;
    button: true;
    button2: true;
    link: true;
    subtitle1_6: true;
  }
}

const shadows = [
  'none',
  '0 1px 6px 0 rgba(0, 0, 0, 0.12)',
  '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
  '0 2px 20px 0 rgba(0, 0, 0, 0.16)',
  ...Array<string>(21).fill('none'), // NOSONAR
] as Shadows;

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 360,
    md: 768,
    lg: 1280,
  },
});

const palette = {
  common: {
    black: '#1a1818',
    white: '#ffffff',
  },
  background: {
    default: '#fafafa',
    paper: '#ffffff',
  },
  primary: {
    light: '#0597f2',
    main: '#003865',
    dark: '#002884',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#ebf7fe',
    main: '#e5eaef',
    dark: '#75787b',
    contrastText: '#1a1818',
  },
  success: {
    light: '#e3f4ef',
    main: '#1fa67a',
    dark: '#128963',
    contrastText: '#ffffff',
  },
  error: {
    light: '#fcefef',
    main: '#e52b4c',
    dark: '#b00f20',
    contrastText: '#ffffff',
  },
  warning: {
    light: '#e26262',
    main: '#fff796',
    dark: '#e65100',
  },
  info: {
    light: '#4fc3f7',
    main: '#00c1d4',
    dark: '#0288d1',
    contrastText: '#1a1818',
  },
  grey: {
    50: '#f4f6f8',
    100: '#b1b3b3',
    200: '#e0e0e0',
  },
};

const typography = {
  fontFamily: 'AvenirNext',
  h1: {
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0.4px',
    lineHeight: '27px',
  },
  h2: {
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.43px',
    lineHeight: '25px',
  },
  h2_5: {
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0.43px',
    lineHeight: '25px',
  },
  h3: {
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '1.28px',
    lineHeight: '19px',
  },
  h3_5: {
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.38px',
    lineHeight: '22px',
  },
  h4: {
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '1.12px;',
    lineHeight: '19px',
  },
  subtitle1: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.34px',
    lineHeight: '19px',
  },
  subtitle1_6: {
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0.34px',
    lineHeight: '24px',
  },
  subtitle2: {
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0.38px',
    lineHeight: '24px',
  },
  data1: {
    fontSize: '40px',
    fontWeight: '600',
    letterSpacing: '0.8px',
    lineHeight: '55px',
  },
  data2: {
    fontSize: '32px',
    fontWeight: '600',
    letterSpacing: '0.64px',
    lineHeight: '44px',
  },
  data3: {
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.43px',
    lineHeight: '25px',
  },
  data4: {
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.38px',
    lineHeight: '22px',
  },
  body1: {
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '1.12px',
    lineHeight: '19px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.34px',
    lineHeight: '19px',
  },
  body3: {
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '0.38px',
    lineHeight: '1.67',
    textTransform: 'none',
  },
  error: {
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0.3px',
    lineHeight: '1.66',
    textTransform: 'none',
    color: palette.error.main,
  },
  button: {
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.43px',
    lineHeight: '25px',
  },
  button2: {
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.38px',
    lineHeight: '22px',
  },
  link: {
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.38px',
    lineHeight: '22px',
    textDecoration: 'underline',
  },
  allVariants: {
    color: '#1a1818',
    textTransform: 'none',
  },
};

export const Theme = createTheme({
  breakpoints,
  typography,
  palette,
  shadows: [...shadows],
  components: {
    RotatingImage: {
      variants: [
        {
          props: { variant: 'rotated' },
          style: {
            transform: 'rotate(180deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
        {
          props: { variant: 'normal' },
          style: {
            transform: 'rotate(0deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...typography.button,
          boxShadow: shadows[0],
          borderRadius: '21px',
          height: '41px',
          maxWidth: '320px',
          width: '100%',
          '&.Mui-disabled': {
            backgroundColor: palette.secondary.main,
            color: palette.common.white,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiAccordionSummary-root': {
            minHeight: '50px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...typography.h2_5,
          '&.MuiOutlinedInput-root': {
            backgroundColor: palette.common.white,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...typography.h3_5,
          color: '#b7c1c7',
          '&.MuiInputLabel-shrink': {
            color: palette.common.black,
          },
          '&.MuiInputLabel-shrink.Mui-error': {
            color: palette.error.main,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...typography.error,
          margin: '2px 0px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${palette.info.main}`,
          },
          '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${palette.info.main}`,
          },
          '&.Mui-error.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${palette.error.main}`,
          },
          '&.Mui-error.Mui-focused  .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${palette.error.main}`,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${palette.error.main}`,
          },
          '& .MuiInputLabel-root, fieldset': {
            fontSize: '1rem',
          },
        },
        notchedOutline: {
          borderColor: 'rgba(0, 56, 101, 0.1)',
        },
        input: {
          background: palette.common.white,
          height: '1.1876em',
          padding: '16px 16px',
          '&:-webkit-autofill': {
            WebkitBoxShadow: 'inset 0 0 0 30px white !important',
          },
          '&::placeholder': {
            opacity: 1,
            ...typography.h3_5,
            color: palette.secondary.dark,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          ...typography.h2_5,
          '&[aria-selected="true"]': {
            backgroundColor: 'rgba(0, 193, 212, 0.05) !important',
          },
          '&[data-focus="true"]': {
            backgroundColor: '#f5f7f9',
          },
        },
        root: {
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '9.5px 6px',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.primary.light,
          textDecorationColor: palette.primary.light,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: palette.common.black,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.main,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          border: '1px solid',
          borderRadius: '2px',
          backgroundColor: palette.secondary.main,
          borderColor: palette.secondary.main,
          margin: '15px 0',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '52px',
          '& .MuiTabs-indicator': {
            height: '4px',
            borderRadius: '12px 12px 0 0',
            backgroundColor: palette.info.main,
            marginBottom: '-1px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...typography.h3_5,
          color: palette.common.black,
          textTransform: 'capitalize',
          maxWidth: '114px',
          minHeight: '50px',
          '&.Mui-selected': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '414px',
          padding: '28px 56px 120px 56px',
          [breakpoints.down('md')]: {
            width: '100vw',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '16px',
          width: '100%',
          borderRadius: '50px',
          border: `2px solid ${palette.secondary.main}`,
          '&.MuiLinearProgress-colorPrimary': {
            backgroundColor: palette.secondary.main,
          },
          '& .MuiLinearProgress-bar': {
            borderRadius: '8px',
            height: '100%',
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          width: '100%',
          '&:before': {
            display: 'none',
          },
          ':not(.Mui-expanded)': {
            marginBottom: '24px',
          },
          ':not(.Mui-expanded):last-of-type': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '43px',
          borderRadius: '4px',
          '> .MuiAccordionSummary-content': {
            margin: '8px 0',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          textAlign: 'right',
          textTransform: 'capitalize',
        },
        sizeSmall: {
          height: '24px',
        },
        labelSmall: {
          ...typography.body3,
          lineHeight: '24px',
        },
        sizeMedium: {
          height: '33px',
        },
        labelMedium: {
          ...typography.h2,
          lineHeight: '33px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...typography.body3,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '8px',
          color: palette.grey[200],
          '& .MuiSvgIcon-root': {
            color: 'inherit',
          },
          '& .Mui-checked': {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&:has(.MuiRadio-root)': {
            margin: 0,
            marginLeft: '2px',
            marginTop: '2px',
            marginBottom: '2px',
            '& .MuiBox-root': {
              ...typography.h3_5,
            },
          },
          '&:has(.Mui-checked.MuiRadio-root)': {
            border: `solid 2px ${palette.primary.main}`,
            borderRadius: '16px',
            backgroundColor: palette.secondary.light,
            marginLeft: '0px',
            marginTop: '0px',
            marginBottom: '0px',
            '& .MuiBox-root': {
              fontWeight: 600,
            },
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '&[role=radiogroup]': {
            '& .MuiFormControlLabel-root': {
              padding: '8px',
              '& .MuiTypography-body1': {
                width: '100%',
              },
            },
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: '4px',
          margin: 0,
          color: palette.common.black,
          '&.Mui-selected': {
            backgroundColor: palette.primary.light,
            color: palette.background.default,
          },
          '&:focus.Mui-selected': {
            backgroundColor: palette.primary.light,
          },
          '&.Mui-disabled': {
            color: palette.grey[100],
            fontWeight: 500,
          },
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        weekContainer: {
          margin: 0,
          padding: '2px 0 2px 0',
        },
        weekDayLabel: {
          color: palette.secondary.dark,
          fontWeight: 500,
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          fontWeight: 600,
        },
      },
    },
  },
} as ThemeOptions);
