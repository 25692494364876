/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import type { FormControlLabelProps } from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';
import type { FieldValues, Control, FieldPath } from 'react-hook-form';
import { useController } from 'react-hook-form';

export type FormCheckboxFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
} & Omit<FormControlLabelProps, 'control'>;

export const FormCheckboxField = <T extends FieldValues>({ control, name, ...props }: FormCheckboxFieldProps<T>) => {
  const { field } = useController({ control, name });
  return <FormControlLabel {...props} onChange={field.onChange} control={<Checkbox />} />;
};
