/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { ActionLink } from 'components/ActionLink';
import { VSpacer } from 'components/VSpacer';
import { PaymentMethodCurrent } from '../PaymentMethodCurrent';

type OneTimePaymentFormExistingPropsT = {
  accountType: string;
  accountNumber: string;
  routingNumber: string;
  onEdit: () => void;
};

export const OneTimePaymentFormExisting = ({
  accountNumber,
  accountType,
  routingNumber,
  onEdit,
}: OneTimePaymentFormExistingPropsT) => (
  <>
    <PaymentMethodCurrent accountNumber={accountNumber} routingNumber={routingNumber} accountType={accountType} />
    <VSpacer spacing={5} />
    <ActionLink onClick={onEdit}>Use Another Payment Method</ActionLink>
  </>
);
