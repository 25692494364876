// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigationHelpers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathParts = location.pathname.split('/');
  const headRoute = pathParts[1];
  const parentRoute = pathParts.slice(0, -1).join('/');
  const navigateToParentRoute = () => {
    navigate(parentRoute);
  };

  const getParentRoute = () => parentRoute;

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  const navigateToAppendedRoute = (route: string) => {
    navigate(`${location.pathname}${route}`);
  };

  return { navigateToParentRoute, getParentRoute, navigateToAppendedRoute, navigateToDashboard, headRoute };
};

export const useNavigateFromHeadRouteHelpers = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const pathParts = pathName.split('/');
  const loanCode = pathParts[2];
  const parentRoute = pathParts.slice(0, -2).join('/');

  const isParentRoute = Boolean(parentRoute);
  const getParentRoute = () => `${parentRoute}/${loanCode}`;

  return { getParentRoute, isParentRoute, pathName };
};
