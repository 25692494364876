/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Grid } from '@mui/material';
import { TextH2 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import { type Loan } from 'store/LoanStore';
import { DashboardAccount } from './AccountCard/DashboardAccount';

type DashboardAccountListPropsT = {
  loans: Loan[];
  type: 'Accounts' | 'Closed Accounts';
};

export const DashboardAccountList = ({ loans, type }: DashboardAccountListPropsT) => (
  <Box hidden={loans.length === 0}>
    <TextH2 sx={{ fontWeight: 500 }}>{`${type} (${loans.length})`}</TextH2>
    <VSpacer spacing={2.5} />
    <Grid container spacing={2.5}>
      {loans.map((loan, index) => (
        <DashboardAccount key={index} loan={loan} />
      ))}
    </Grid>
  </Box>
);
