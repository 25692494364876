/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled, Box, useMediaQuery } from '@mui/material';
import { GreyButton } from 'components/Buttons';
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { Statuses } from 'Status';
import { type Loan } from 'store/LoanStore';
import Arrow from 'assets/Arrow.svg';
import { Theme } from 'theme';
import { DashboardField } from '../DashboardField';
import { amountDueLabels, getDueDateLabel, getDueDateValue } from '../DashboardHelpers';

export const $AccountDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '20px 32px',
  backgroundColor: 'rgba(0, 56, 101, 0.04)',
  borderRadius: '4px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    padding: '16px 32px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '16px',
  },
}));

const $ButtonWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    flexGrow: 0,
    justifyContent: 'center',
    marginTop: 'auto',
  },
}));

type DashboardAccountPropsT = {
  loan: Loan;
};

export const DashboardAccountDetails = ({ loan }: DashboardAccountPropsT) => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(Theme.breakpoints.only('lg'));

  const {
    loanCode,
    loanSummary: { accountStatus, totalPaidToDateCents },
    paymentInformation: { paymentDueInCents, paymentDueDate, autopayEnabled, paymentToBringAccountCurrentCents },
  } = loan;

  const isAccountPastDue = paymentToBringAccountCurrentCents > 0;
  const isAccountClosed = accountStatus === Statuses.CLOSED;

  return (
    <$AccountDetails data-testid='account-details'>
      <DashboardField label='Account Number' data={loanCode} spacing={4} divider />
      <DashboardField
        label={amountDueLabels[accountStatus]}
        data={toCurrencyFromCents(isAccountClosed ? -totalPaidToDateCents : paymentDueInCents)}
        error={isAccountPastDue}
        spacing={4}
        divider
      />
      <DashboardField
        label={getDueDateLabel(isAccountClosed, autopayEnabled, isDesktop)}
        data={getDueDateValue(paymentDueDate, autopayEnabled, isMobile, isDesktop)}
      />
      <$ButtonWrapper>
        <GreyButton sx={{ maxWidth: isDesktop ? '136px' : '302px', height: '38px' }}>
          <Box display='flex'>
            {isAccountClosed ? 'View' : 'Manage'}
            <img style={{ marginLeft: '8px', paddingTop: '2px' }} src={Arrow} alt='arrow_icon' />
          </Box>
        </GreyButton>
      </$ButtonWrapper>
    </$AccountDetails>
  );
};
