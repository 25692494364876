/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { type ButtonProps, Button, styled } from '@mui/material';
import { useNavigationHelpers } from 'hooks/navigateHelper';

const $SetUpButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#0a72b4',
  color: theme.palette.background.default,
  width: '200px',
  flex: '0 0 auto',
  '&:hover': {
    backgroundColor: '#0a72b4',
  },
}));

export const SetUpButton = ({ onClick, ...props }: ButtonProps) => {
  const { navigateToAppendedRoute } = useNavigationHelpers();

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }

    navigateToAppendedRoute('/autopay');
  };

  return (
    <$SetUpButton {...props} onClick={onClickHandler}>
      Set Up Autopay
    </$SetUpButton>
  );
};
