/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Grid } from '@mui/material';
import { VSpacer } from 'components/VSpacer';
import { TextH2 } from 'components/Typographies';
import { DashboardAccountCardContainer, $CardWrapper } from '../AccountCard/DashboardAccount';
import { AccountOverviewSkeleton } from './AccountOverviewSkeleton';
import { AccountDetailsSkeleton } from './AccountDetailsSkeleton';

export const DashboardSkeleton = () => (
  <>
    <AccountListSkeleton type='Accounts' count={2} />
    <VSpacer spacing={7} />
    <AccountListSkeleton type='Closed Accounts' count={1} />
  </>
);

type AccountListSkeletonPropsT = {
  count: number;
  type: 'Accounts' | 'Closed Accounts';
};

const AccountListSkeleton = ({ type, count }: AccountListSkeletonPropsT) => {
  const accounts = Array.from({ length: count }, (_, index) => <DashboardAccountSkeleton key={index} />);
  return (
    <>
      <TextH2 sx={{ fontWeight: 500 }}>{`${type}`}</TextH2>
      <VSpacer spacing={2.5} />
      <Grid container spacing={2.5}>
        {accounts}
      </Grid>
    </>
  );
};

const DashboardAccountSkeleton = () => (
  <Grid item xs={12}>
    <$CardWrapper>
      <DashboardAccountCardContainer>
        <AccountOverviewSkeleton />
        <AccountDetailsSkeleton />
      </DashboardAccountCardContainer>
    </$CardWrapper>
  </Grid>
);
