/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, type BoxProps, styled, useTheme } from '@mui/material';
import { type ReactNode, useState } from 'react';

const $OutlinedBox = styled(Box)(({ theme }) => ({
  ...theme.typography.data3,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  minHeight: '54px',
  boxSizing: 'border-box',
}));

export const FormContainerStatuses = {
  ERROR: 'ERROR',
  HOVER: 'HOVER',
  SELECTED: 'SELECTED',
  NORMAL: 'NORMAL',
} as const;

type FormOutlinedContainerProps = {
  children: ReactNode;
  status: keyof typeof FormContainerStatuses;
} & BoxProps;

export const FormOutlinedContainer = ({ children, status, ...props }: FormOutlinedContainerProps) => {
  const theme = useTheme();
  const [hovering, setIsHovering] = useState(false);

  const getBorderStyle = () => {
    const internalStatus = hovering && status !== FormContainerStatuses.ERROR ? FormContainerStatuses.HOVER : status;
    switch (internalStatus) {
      case FormContainerStatuses.ERROR:
        return {
          border: `2px solid ${theme.palette.error.main}`,
        };
      case FormContainerStatuses.HOVER:
      case FormContainerStatuses.SELECTED:
        return {
          border: `2px solid ${theme.palette.info.main}`,
        };
      default:
        return {
          border: '1px solid rgba(0, 56, 101, 0.1)',
          padding: '1px',
        };
    }
  };

  return (
    <$OutlinedBox
      sx={getBorderStyle()}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      data-testid='outlined-box'
      {...props}
    >
      {children}
    </$OutlinedBox>
  );
};
