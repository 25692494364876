// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { createRouter } from './router';
import App from './App';
import './index.css';

const router = createRouter(App);

const container = document.getElementById('root');

if (!container) {
  throw new Error('root does not exist');
}

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

void reportWebVitals();
