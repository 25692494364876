/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { styled } from '@mui/material';
import { Footer } from 'components/Footer/Footer';
import { TagResponseHeader } from 'components/Header/TagResponseHeader';

export const $Container = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette?.common?.white,
}));

export const $ContentBox = styled('div')(({ theme }) => ({
  margin: '0 auto',
  padding: '150px 50px',
  textAlign: 'center',
  backgroundColor: theme.palette?.common?.white,
}));

export const $ContentHeadline = styled('div')(() => ({
  fontSize: '22px',
  paddingBottom: '24px',
}));

export const $ContentSubtext = styled('div')(() => ({
  fontSize: '16px',
}));

export const TagAccepted = () => (
  <$Container>
    <TagResponseHeader />
    <$ContentBox>
      <$ContentHeadline>Thank you!</$ContentHeadline>
      <$ContentSubtext>We will charge your loan for this purchase.</$ContentSubtext>
    </$ContentBox>
    <Footer />
  </$Container>
);
