/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { Box, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { pastDueBannerDismissed, autopayBannerDismissed, showingPastDueBanner } from 'store/BannerStore';
import { payableLoansSelector } from 'store/LoanStore';
import { useEffect, useRef } from 'react';
import { AutopayOnBanner } from './Autopay/AutopayOnBanner';
import { AutopaySetUpBanner } from './Autopay/AutopaySetUpBanner';
import { PastDueBanner } from './PastDue/PastDueBanner';
import { PaymentProcessingBanner } from './PaymentProcessingBanner';

const $BannerArea = styled(Box)(({ theme }) => ({
  position: 'sticky',
  height: '100%',
  margin: '0 auto',
  zIndex: '10',
  left: 0,
  bottom: 0,
  [theme.breakpoints.only('lg')]: {
    maxWidth: '1040px',
  },
}));

const $BannerContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  left: 0,
  bottom: 0,
  [theme.breakpoints.only('md')]: {
    padding: '8px 40px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '8px 16px',
  },
  marginBottom: '8px',
}));

type BannerHouseProps = {
  onHeightChange: (_: number) => void;
};

export const BannerHouse = ({ onHeightChange }: BannerHouseProps) => {
  const location = useLocation();
  const isDashboardPage = location.pathname.toLowerCase().includes('dashboard');
  const payableLoans = useRecoilValue(payableLoansSelector);
  const isPastDueBannerDismissed = useRecoilValue(pastDueBannerDismissed);
  const isPastDueBannerShowing = useRecoilValue(showingPastDueBanner);
  const isAutopayBannerDismissed = useRecoilValue(autopayBannerDismissed);
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    onHeightChange(ref.current?.clientHeight ?? 0);
  });

  return (
    <$BannerArea>
      <$BannerContainer ref={ref}>
        <AutopayOnBanner />
        {!isPastDueBannerShowing && !isAutopayBannerDismissed && (
          <AutopaySetUpBanner dashboard={isDashboardPage} payableLoans={payableLoans} />
        )}
        {!isPastDueBannerDismissed && <PastDueBanner dashboard={isDashboardPage} />}
        <PaymentProcessingBanner dashboard={isDashboardPage} />
      </$BannerContainer>
    </$BannerArea>
  );
};
