/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { TextField, type TextFieldProps } from '@mui/material';
import type { FormRules } from 'helpers/formRulesHelpers';
import { useState } from 'react';
import { useController, type Control, type FieldValues, type FieldPath } from 'react-hook-form';

export type FormTextFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  rules?: FormRules;
} & TextFieldProps;

export const FormTextField = <T extends FieldValues>({ name, control, rules, ...textProps }: FormTextFieldProps<T>) => {
  const [showingError, setShowingError] = useState(false);
  const { field, fieldState, formState } = useController({
    name,
    control,
    rules,
  });

  return (
    <TextField
      fullWidth
      name={field.name}
      value={field.value as string}
      variant='outlined'
      onChange={field.onChange}
      onBlur={() => {
        setShowingError(true);
        field.onBlur();
      }}
      helperText={(formState.submitCount > 0 || showingError) && fieldState.error?.message}
      error={(formState.submitCount > 0 || showingError) && !!fieldState.error}
      inputRef={field.ref}
      {...textProps}
      inputProps={{ ...textProps.inputProps, 'data-lpignore': 'true' }}
    />
  );
};
