/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled, Box, Divider } from '@mui/material';
import type { BoxProps } from '@mui/system';
import NalupayLogo from 'assets/nalupay_logo_bw.svg';
import { TitleSubTitle } from 'components/TitleSubTitle';
import { dateTo, toSentenceCase } from 'helpers/stringHelpers';
import { useRecoilValue } from 'recoil';
import { selectedLoanState } from 'store/LoanStore';
import { toCurrency } from 'helpers/numberHelpers';
import dayjs from 'dayjs';
import { DataRow } from './DataRow';
import type { PaymentAccountT } from './Content/Payments/PaymentAccountModel';
import { VSpacer } from './VSpacer';

const $Page = styled(Box)({
  maxWidth: '1125px',
  maxHeight: '1500px',
  padding: '109.6px 40px 20px 40px',
});

const $ContentBox = styled(Box)({
  textAlign: 'center',
  maxWidth: '1275px',
  maxHeight: '1650px',
  margin: '0px',
});

const NaluLogo = (props: BoxProps) => <Box {...props} component='img' src={NalupayLogo} alt='NaluLogo' />;
const $NaluLogo = styled(NaluLogo)({
  maxWidth: '302.5px',
  maxHeight: '207.4px',
  padding: '74px',
  margin: '0px',
});

interface CustomPropsT {
  data: PaymentAccountT;
}

export const PaymentSummaryToPrint = ({ data, ...props }: CustomPropsT) => {
  const loan = useRecoilValue(selectedLoanState);
  const { bankAccountType, bankAccountNumber } = loan.paymentInformation;
  const isScheduledPayment = data.scheduledDate && !dayjs(data.scheduledDate).isSame(dayjs(), 'day');
  const paymentDate = data.scheduledDate ? new Date(data.scheduledDate) : new Date();

  return (
    <Box {...props} sx={{ width: '100%', textAlign: 'center' }}>
      <$Page>
        <Box sx={{ display: 'grid', justifyContent: 'center' }}>
          <div>
            <$NaluLogo />
          </div>
          <$ContentBox>
            <div style={{ display: 'grid', justifyContent: 'center' }}>
              <TitleSubTitle
                title={
                  isScheduledPayment ? `You're all set, thank you!` : `You're all set, thank you for your payment!`
                }
                subtitle=''
              />
            </div>
            <VSpacer spacing={11} />
            <Box sx={{ width: '440px', height: '197px' }}>
              {isScheduledPayment ? (
                <DataRow
                  label='Payment Method'
                  data={`Account ${data.accountNumber ? `*${data.accountNumber.slice(-4)}` : 'on file'}`}
                />
              ) : (
                <DataRow
                  label='Payment Type'
                  data={`${toSentenceCase(data.accountType ?? bankAccountType)} ACH *${
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    (data.accountNumber || bankAccountNumber)?.slice(-4) ?? ''
                  }`}
                />
              )}
              <VSpacer spacing={3} />
              <Divider />
              <VSpacer spacing={2.7} />
              <DataRow label='Payment Amount' data={toCurrency(data.paymentAmount)} />
              <VSpacer spacing={3} />
              <Divider />
              <VSpacer spacing={2.7} />
              <DataRow label='Payment Date' data={dateTo('short_abbr', paymentDate.toLocaleDateString())} />
              <VSpacer spacing={3} />
              <Divider />
            </Box>
            <VSpacer spacing={10} />
          </$ContentBox>
        </Box>
        <div style={{ display: 'grid', justifyContent: 'center' }}>
          <TitleSubTitle title='Payments may take up to 5 business days to process.' subtitle='' />
        </div>
        <VSpacer spacing={28} />
        <div style={{ display: 'grid', justifyContent: 'left' }}>
          <TitleSubTitle title={dateTo('long', new Date().toLocaleString())} subtitle='' />
        </div>
      </$Page>
    </Box>
  );
};
