/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  Select,
  type MenuProps as MuiMenuProps,
  type SelectChangeEvent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Statuses } from 'Status';
import { TextH4 } from 'components/Typographies';
import type { ReactNode } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { filteredLoansSelector, openLoansSelector, selectedLoanCodeState, selectedLoanState } from 'store/LoanStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { RouteNames } from 'hooks/useLoanCodeInRoute';
import { DropDownInputBase } from './DropDownInputBase';
import { dropDownMenuItem } from './DropDownMenuItem';

const $Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 0 25px 0',
  backgroundColor: theme.palette.background.paper,
}));

const $InputLabel = styled(InputLabel)({
  display: 'flex',
  justifyContent: 'center',
  transformOrigin: 'unset',
  position: 'relative',
  transition: 'unset',
  transform: 'unset',
  marginBottom: '8px',
  width: '318px',
});

const $ListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
  fontSize: '14px',
  lineHeight: '27px',
  letterSpacing: '1.12px',
  margin: '12px',
  borderRadius: '4px',
  paddingLeft: '12px',
}));

const menuProps: Partial<MuiMenuProps> = {
  keepMounted: true,
  disablePortal: true,
  sx: (theme) => ({
    '> .MuiMenu-paper::before': {
      position: 'fixed',
      width: '20px',
      height: '20px',
      content: '""',
      left: 'calc(50% - 18px)',
      top: '172px',
      background: `conic-gradient(at 50% 46%,transparent 135deg,${theme.palette.background.paper} 0,${theme.palette.background.paper} 225deg, transparent 0)`,
      [theme.breakpoints.down('md')]: {
        top: '156px',
      },
    },
    '> .MuiMenu-paper': {
      transition: 'unset !important',
      transform: 'none !important',
      marginTop: '12px',
      width: '320px',
      boxShadow: theme.shadows[3], // NOSONAR
    },
    '> .MuiMenu-paper .Mui-selected': {
      backgroundColor: '#ebf7fe !important',
    },
    '> .MuiMenu-paper .MuiGrid-grid-xs-4': {
      textAlign: 'right',
    },
    '> .MuiMenu-paper .MuiGrid-grid-xs-8': {
      textAlign: 'right',
    },
  }),
};

export const LoanSelection = () => {
  const setSelectedLoanCode = useSetRecoilState(selectedLoanCodeState);
  const selectedLoan = useRecoilValue(selectedLoanState);
  const navigate = useNavigate();
  const location = useLocation();
  const routeNames = Object.values(RouteNames);

  const loanChangeHandler = ({ target }: SelectChangeEvent, _child: ReactNode) => {
    setSelectedLoanCode(target.value);
    const [, pathRoute] = location.pathname.split('/');
    const currentRoute = routeNames.find((route) => route === pathRoute) ?? RouteNames.Overview;
    navigate(`/${currentRoute}/${target.value ?? ''}`);
  };

  const openLoans = useRecoilValue(openLoansSelector);
  const closedLoans = useRecoilValue(filteredLoansSelector(Statuses.CLOSED));
  const accountsText = `ACCOUNTS (${openLoans?.length})`;
  const closedText = `CLOSED (${closedLoans?.length})`;

  return (
    <$Container>
      <FormControl>
        <$InputLabel id='select-account'>
          <TextH4 color='primary'>YOUR ACCOUNTS:</TextH4>
        </$InputLabel>
        <Select
          data-testid='select-account'
          labelId='select-account'
          variant='filled'
          value={selectedLoan?.loanCode}
          onChange={loanChangeHandler}
          displayEmpty
          input={<DropDownInputBase />}
          MenuProps={menuProps}
          IconComponent={KeyboardArrowDownSharpIcon}
          renderValue={() => selectedLoan?.loanSummary.merchantName ?? ''}
        >
          {openLoans.length > 0 && <$ListSubheader>{accountsText}</$ListSubheader>}
          {openLoans.length > 0 && dropDownMenuItem(openLoans)}
          {closedLoans.length > 0 && <$ListSubheader>{closedText}</$ListSubheader>}
          {closedLoans.length > 0 && dropDownMenuItem(closedLoans)}
        </Select>
      </FormControl>
    </$Container>
  );
};
