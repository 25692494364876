/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isSelectedLoanPastDue, pastDueLoansSelector, selectedLoanState } from 'store/LoanStore';
import { pastDueBannerDismissed, showingPastDueBanner } from 'store/BannerStore';
import { Desktop } from 'components/Desktop';
import { TextSubtitle16, TextBody3 } from 'components/Typographies';
import { useEffect } from 'react';
import { MobileAndTab } from 'components/MobileAndTab';
import { $BannerWrapper, $BannerDivider, getPastDuePaymentLabel } from '../BannerHelpers';
import { BannerPayNow } from './BannerPayNow';
import { LatePaymentIcon } from './LatePaymentIcon';

const $PastDueBanner = styled($BannerWrapper)(({ theme }) => ({
  display: 'flex',
  backgroundColor: 'rgba(229, 43, 76, .96)',
  color: theme.palette.background.default,
  flexDirection: 'column',
  padding: '32px 40px',
  [theme.breakpoints.down('lg')]: {
    padding: '16px',
  },
}));

const $Title = styled(Box)(({ theme }) => ({
  ...theme.typography.h1,
  lineHeight: '1.8',
  color: 'inherit',
  [theme.breakpoints.down('lg')]: {
    ...theme.typography.data4,
    lineHeight: '1.75',
    flexGrow: 1,
  },
}));

const $IconButton = styled(IconButton)({
  margin: '0 -5px 0 35px',
  color: 'inherit',
  padding: 0,
});

type PastDueBannerPropsT = {
  dashboard: boolean;
};

export const PastDueBanner = ({ dashboard }: PastDueBannerPropsT) => {
  const { paymentInformation } = useRecoilValue(selectedLoanState);
  const setBannerDismissed = useSetRecoilState(pastDueBannerDismissed);
  const setBannerShowing = useSetRecoilState(showingPastDueBanner);
  const pastDueLoans = useRecoilValue(pastDueLoansSelector);
  const isAccountPastDue = useRecoilValue(isSelectedLoanPastDue);
  const label = getPastDuePaymentLabel(dashboard, paymentInformation.paymentToBringAccountCurrentCents);
  const showBanner = (dashboard && pastDueLoans.length > 0) || isAccountPastDue;

  const handleDismissBanner = () => {
    setBannerDismissed(true);
    setBannerShowing(false);
  };

  useEffect(() => {
    setBannerShowing(showBanner);
  }, [showBanner]);

  return (
    <>
      {showBanner && (
        <$PastDueBanner data-testid='pastDueBanner'>
          <Box display='flex' alignItems='center'>
            <LatePaymentIcon />
            <$Title>Payment&nbsp;Past&nbsp;Due</$Title>
            <Desktop>
              <$BannerDivider orientation='vertical' />
              <TextSubtitle16 sx={{ lineHeight: 1.71, flexGrow: 1 }}>{label}</TextSubtitle16>
              {!dashboard && <BannerPayNow sx={{ marginLeft: '32px' }} />}
            </Desktop>
            <$IconButton onClick={handleDismissBanner} data-testid='close_button_past_due'>
              <CloseIcon sx={{ color: 'inherit' }} data-testid='CloseIcon' />
            </$IconButton>
          </Box>
          <MobileAndTab>
            <TextBody3 sx={{ lineHeight: 2, marginTop: '12px' }}>{label}</TextBody3>
            {!dashboard && <BannerPayNow sx={{ alignSelf: 'center', marginTop: '20px' }} />}
          </MobileAndTab>
        </$PastDueBanner>
      )}
    </>
  );
};
