/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Suspense } from 'react';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Snapshot } from './Snapshot/Snapshot';
import { UpcomingPayment } from './UpcomingPayment/UpcomingPayment';
import { LoanDetails } from './LoanDetails/LoanDetails';
import { SnapshotSkeleton } from './Snapshot/SnapshotSkeleton';
import { LoanDetailsSkeleton } from './LoanDetails/LoanDetailsSkeleton';
import { UpcomingPaymentSkeleton } from './UpcomingPayment/UpcomingPaymentSkeleton';

export const Overview = () => (
  <Grid container spacing={2.5}>
    <Suspense fallback={<SnapshotSkeleton />}>
      <Snapshot />
    </Suspense>
    <Suspense fallback={<UpcomingPaymentSkeleton />}>
      <UpcomingPayment />
    </Suspense>
    <Suspense fallback={<LoanDetailsSkeleton />}>
      <LoanDetails />
    </Suspense>
    <Suspense>
      <Outlet />
    </Suspense>
  </Grid>
);
