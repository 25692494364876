/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { styled, AppBar, Container, Toolbar, Box, type BoxProps as MuiBoxProps } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sidebarState } from 'store/LayoutStore';
import NaluLogo from 'assets/nalupay_logo.svg';
import { Hamburger } from '../Hamburger';
import { Desktop } from '../Desktop';
import { MobileAndTab } from '../MobileAndTab';
import { HeaderMenuItems } from './HeaderMenuItems';

const $Header = styled(AppBar)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  backgroundColor: `${theme.palette.background.default}`,
  marginBottom: '2px',
}));

const $Container = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0 24px !important',
  },
  [theme.breakpoints.only('md')]: {
    padding: '0 56px !important',
  },
  [theme.breakpoints.only('lg')]: {
    padding: '0 176px !important',
  },
}));

const $HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  height: '80px',
  margin: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    height: '56px',
  },
}));

const Logo = (props: MuiBoxProps) => <Box {...props} component='img' src={NaluLogo} alt='NaluLogo' />;

export const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarState);
  return (
    <$Header position='sticky'>
      <$Container maxWidth='lg'>
        <$HeaderToolbar disableGutters>
          <Logo />
          <Desktop>
            <HeaderMenuItems />
          </Desktop>
          <MobileAndTab>
            <Hamburger onClick={() => setSidebarOpen(!sidebarOpen)} />
          </MobileAndTab>
        </$HeaderToolbar>
      </$Container>
    </$Header>
  );
};
