/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import endpointConfig from 'config/endpointConfig';
import { axios } from 'helpers/axiosInstance';
import { selector, selectorFamily } from 'recoil';

export const FEATURE_FLAGS = {
  SCHEDULE_PAYMENT_FLAG: 'Tech-32097-CP-SchedulePayment',
};

export const isFeatureOn = selectorFamily<boolean, keyof typeof FEATURE_FLAGS>({
  key: 'enabledFeatureFlags',
  get:
    (featureFlag) =>
    ({ get }) =>
      !!get(featureFlagState).includes(FEATURE_FLAGS[featureFlag]),
});

export const featureFlagState = selector({
  key: 'featureFlags',
  async get() {
    const config = endpointConfig.FEATURE_FLAGS;
    const { data } = await axios<string[]>(config);
    return data ?? [''];
  },
});
