/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { Link, styled, Typography } from '@mui/material';

const $Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.data4,
  letterSpacing: 0.16,
  lineHeight: '28px',
}));

export const PrivacyFormDescription = () => (
  <$Text data-testid='donotsell'>
    When you work with NaluPay, you are trusting us with your information. NaluPay takes protection of your information
    seriously and is committed to ensuring the security of all customer data. New privacy regulations now require
    businesses like NaluPay to give California residents a process for opting out of any potential practice where
    customer personal information might be sold. Although NaluPay does not sell personal information for monetary value,
    certain actions may be considered a &#8220;sale&#8221; under the CCPA as NaluPay works with other parties to
    facilitate loan fundings and solutions. Opting out of information &#8220;selling&#8221; may adversely affect
    NaluPay&#8217;s ability to process your loan request.
    <br />
    <br />
    For further information on our privacy practices, please review our{' '}
    <Link target='_blank' rel='noopener noreferrer' href='/PrivacyPolicy' sx={{ textDecoration: 'none' }}>
      notice
    </Link>{' '}
    or contact us at 877-NaluPay /{' '}
    <Link sx={{ color: 'inherit', textDecoration: 'none' }} href='tel:(877) 625-8729'>
      (877) 625-8729.
    </Link>
  </$Text>
);
