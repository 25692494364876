/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Button, styled } from '@mui/material';

export const ActionLink = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  ...theme.typography.link,
  fontWeight: 500,
  textDecoration: 'none',
  marginLeft: '3px',
  maxHeight: '25px',
}));
