/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { useNavigate } from 'react-router-dom';
import { styled, Typography, IconButton, Box } from '@mui/material';
import { VSpacer } from 'components/VSpacer';
import { TextH2 } from 'components/Typographies';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const $Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.data4,
  letterSpacing: 0.16,
  fontWeight: 500,
  lineHeight: '28px',
}));

const $Header = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  minHeight: 0,
  justifyContent: 'flex-start',
}));

export const AutopayAuthorizationTerms = () => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Box>
      <VSpacer spacing={4} />
      <$Header>
        <IconButton sx={{ padding: 0 }} onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
      </$Header>
      <VSpacer spacing={4} />
      <TextH2 sx={{ textAlign: 'center' }}>Autopay Authorization Terms</TextH2>
      <VSpacer spacing={4} />
      <$Text>
        I understand and agree that Lender will not be responsible or liable for any penalties or charges assessed by
        any other financial institution if my account listed above does not have sufficient funds to make my loan
        payment. I understand that Lender will assess a fee if my loan payment is not made as a result of my account
        listed above having insufficient funds. For loan balance and payment information or to terminate this
        authorization, please contact us at support@nalupay.com or 877-NaluPay (877-625-8729). In the event of an
        unauthorized or erroneous automated payment, please contact us and your financial institution as soon as
        possible.
      </$Text>
      <VSpacer spacing={4} />
      <$Text>
        Lender, NaluPay and their designees are hereby authorized to initiate recurring monthly debits, beginning with
        my next payment due date, from the designated account listed above for the payment on the loan account listed
        above and waives any claim or defense that its electronic authorization is not authentic, or enforceable in any
        enforcement proceeding to enforce this Electronic Funds Transfer and Automatic Payment Authorization Form.
      </$Text>
    </Box>
  );
};
