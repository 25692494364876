// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { Button, type ButtonProps, styled } from '@mui/material';

const $Button = styled(Button)(({ theme }) => ({
  ...theme.typography.body1,
  margin: theme.spacing(1),
  color: theme.palette.info.main,
  width: '180px',
  height: '38px',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.primary.main,
  },
  borderRadius: '20px',
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 56, 101, 0.1)',
    '&:hover': {
      background: 'rgba(0, 56, 101, 0.1)',
    },
  },
}));

const SubmitButton = ({ ...props }: ButtonProps) => (
  <$Button {...props} type='submit' size='large' color='primary' data-testid='button' />
);

export default SubmitButton;
