// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import type { PaymentAccountT } from 'components/Content/Payments/PaymentAccountModel';
import endpointConfig from 'config/endpointConfig';
import dayjs from 'dayjs';
import { axios } from 'helpers/axiosInstance';
import { useLoansStateWithRefresh } from './useLoanStateWithRefresh';

export const usePayments = () => {
  const {
    loan: {
      loanCode,
      paymentInformation: { autopayEnabled, bankAccountNumber, bankAccountType, bankRoutingNumber },
    },
    refreshLoans,
  } = useLoansStateWithRefresh();

  const paymentFailureMessage = 'tried new payment without payment info';

  const makePayment = async (data: PaymentAccountT) => {
    if (data.saveAsPrimary) {
      return paymentWithNewPaymentMethodSave(data);
    }

    if (data.accountNumber) {
      return paymentWithNewPaymentMethodNotSave(data);
    }

    return paymentWithStoredPaymentMethod(data);
  };

  const paymentWithStoredPaymentMethod = async (data: PaymentAccountT) => {
    const config = { ...endpointConfig.BORROWER_PORTAL.PAY_USING_STORED_INFO };
    config.url = config.url?.replace('{loanCode}', loanCode);
    config.data = {
      amountInCents: Math.round(data.paymentAmount * 100),
      effectiveDate:
        data.scheduledDate && !dayjs(data.scheduledDate).isSame(dayjs(), 'day') ? new Date(data.scheduledDate) : null,
    };
    return axios.request(config);
  };

  const paymentWithNewPaymentMethodSave = async (data: PaymentAccountT) => {
    if (!data.accountNumber || !data.routingNumber || !data.accountType || !data.saveAsPrimary) {
      throw new Error(paymentFailureMessage);
    }

    const config = { ...endpointConfig.BORROWER_PORTAL.SET_PAYMENT_METHOD };
    config.data = {
      applicationCode: loanCode,
      accountNumber: data.accountNumber,
      routingNumber: data.routingNumber,
      bankAccountType: data.accountType,
      autopayFlag: autopayEnabled,
      effectiveDate:
        data.scheduledDate && !dayjs(data.scheduledDate).isSame(dayjs(), 'day') ? new Date(data.scheduledDate) : null,
    };

    await axios.request(config);

    return paymentWithStoredPaymentMethod(data);
  };

  const setPaymentMethod = async (data: PaymentAccountT) => {
    if (!data.accountNumber || !data.routingNumber || !data.accountType || !data.saveAsPrimary) {
      throw new Error(paymentFailureMessage);
    }

    const config = { ...endpointConfig.BORROWER_PORTAL.SET_PAYMENT_METHOD };
    config.data = {
      applicationCode: loanCode,
      accountNumber: data.accountNumber,
      routingNumber: data.routingNumber,
      bankAccountType: data.accountType,
      saveAsPrimary: data.saveAsPrimary,
      autopayFlag: autopayEnabled,
    };

    return axios.request(config);
  };

  const setupAutopay = async (data: PaymentAccountT) => {
    const config = { ...endpointConfig.BORROWER_PORTAL.SET_PAYMENT_METHOD };
    config.data = {
      applicationCode: loanCode,
      accountNumber: data.accountNumber ? data.accountNumber : bankAccountNumber,
      routingNumber: data.routingNumber ? data.routingNumber : bankRoutingNumber,
      bankAccountType: data.accountType ? data.accountType : bankAccountType,
      autopayFlag: true,
    };

    await axios.request(config);
    return refreshLoans();
  };

  const paymentWithNewPaymentMethodNotSave = async (data: PaymentAccountT) => {
    if (!data.accountNumber || !data.routingNumber) {
      throw new Error(paymentFailureMessage);
    }

    const config = { ...endpointConfig.BORROWER_PORTAL.PAY_USING_ONETIME_INFO };
    config.url = config.url?.replace('{loanCode}', loanCode);
    config.data = {
      amountInCents: Math.round(data.paymentAmount * 100),
      BankAccountNumber: data.accountNumber,
      BankRoutingNumber: data.routingNumber,
      effectiveDate:
        data.scheduledDate && !dayjs(data.scheduledDate).isSame(dayjs(), 'day') ? new Date(data.scheduledDate) : null,
    };

    return axios.request(config);
  };

  const turnOffAutopay = async () => {
    const config = { ...endpointConfig.BORROWER_PORTAL.SET_PAYMENT_METHOD };
    config.data = {
      applicationCode: loanCode,
      autopayFlag: false,
    };
    await axios(config);
    refreshLoans();
  };

  const cancelPayment = async (paymentId: string) => {
    const config = { ...endpointConfig.BORROWER_PORTAL.CANCEL_PAYMENT };
    config.url = config.url?.replace('{paymentId}', paymentId);
    return axios.request(config);
  };

  return { makePayment, turnOffAutopay, setupAutopay, setPaymentMethod, cancelPayment };
};
