/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { styled } from '@mui/material';
import naluexpand from 'assets/nalupay_logo.svg';

const $Header = styled('header')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 5,
  backgroundColor: theme.palette?.common?.white,
  textAlign: 'center',
}));

const $Container = styled('div')(() => ({
  margin: '0 10%',
  padding: '20px 0',
  borderBottom: '1.3px solid rgba(0, 56, 101, 0.1)',
}));

const $Logo = styled('img')(({ theme }) => ({
  width: '130px',
  height: '50px',
  objectFit: 'contain',
  [theme.breakpoints?.down('xs')]: {
    height: '36px',
    width: '94px',
  },
}));

export const TagResponseHeader = () => (
  <$Header>
    <$Container>
      <$Logo src={naluexpand} alt='nalu-logo' />
    </$Container>
  </$Header>
);
