/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import type { ReactNode } from 'react';
import { styled, Box } from '@mui/material';
import type { SystemCssProperties, Theme } from '@mui/system';

const $Line = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  marginBottom: '10px',
  lineHeight: '1.71',
  color: theme.palette.secondary.main,
}));

export const FooterLine = ({ children, sx }: { children: ReactNode; sx?: SystemCssProperties<Theme> }) => (
  <$Line sx={sx}>{children}</$Line>
);
