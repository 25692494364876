// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { styled, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataCellSkeleton } from 'components/DataCellSkeleton';
import { SectionBox } from 'components/SectionBox';

const $Grid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only('lg')]: {
    height: '253px',
  },
  [theme.breakpoints.only('md')]: {
    height: '272px',
  },
}));

const $Items = styled(Grid)({
  display: 'flex',
});

const Column = ({ noDivider }: { noDivider: boolean }) => (
  <$Grid item xs={12} md={6}>
    <Grid container>
      <$Items item xs={12}>
        <DataCellSkeleton />
      </$Items>
      <$Items item xs={12}>
        <DataCellSkeleton />
      </$Items>
      <$Items item xs={12}>
        <DataCellSkeleton noDivider={noDivider} />
      </$Items>
    </Grid>
  </$Grid>
);

export const LoanDetailsSkeleton = () => {
  const theme = useTheme();
  const notMobile = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <SectionBox header='LOAN DETAILS' xs={12} lg={7.5}>
      <Grid container spacing={{ xs: 0, md: 6, lg: 3.75 }}>
        <Column noDivider={notMobile} />
        <Column noDivider />
      </Grid>
    </SectionBox>
  );
};
