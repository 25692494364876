/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { LoanPeriods } from 'LoanPeriod';
import { useRecoilValue } from 'recoil';
import { currentLoanPeriodState, selectedLoanState } from 'store/LoanStore';
import { filteredTransactionSelector } from 'store/TransactionStore';
import { TransactionStatuses } from 'TransactionStatus';
import { TransactionType } from 'TransactionType';

/**
 * @returns Original Loan amount in cents (will always return 0 if the loan's purchase window is not expired)
 */
export const useGetOriginalLoanAmount = () => {
  const selectedLoan = useRecoilValue(selectedLoanState);
  const filterOriginalLoanAmount = {
    loanCodes: [selectedLoan.loanCode],
    statuses: [TransactionStatuses.VALID],
    types: [TransactionType.LOAN],
  };

  const currentLoanPeriod = useRecoilValue(currentLoanPeriodState);
  if (currentLoanPeriod === LoanPeriods.PURCHASE_WINDOW_EXPIRED_PROMO_ONGOING) {
    return selectedLoan.paymentInformation.totalPayoffCents + selectedLoan.paymentsCredits * 100;
  }

  // Loan transaction with original loan amount is posted only after the purchase window & promo period (if there is one) expires.
  const loanTransaction = useRecoilValue(filteredTransactionSelector(filterOriginalLoanAmount));
  if (loanTransaction.length > 0 && loanTransaction[0].transactions.length > 0) {
    return loanTransaction[0]?.transactions[0]?.lineItemSummary.originalAmountCents;
  }

  return 0;
};
