/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import type { InputLabelProps } from '@mui/material';
import { Box } from '@mui/material';
import { FormMaskField } from 'components/FormComponents/FormMaskField';
import { FormRadioField } from 'components/FormComponents/FormRadioField';
import { FormRadioGroupField } from 'components/FormComponents/FormRadioGroupField';
import { FormTextField } from 'components/FormComponents/FormTextField';
import { FadedTextH35 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import type { Control, UseFormGetValues } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { routingNumberValidation } from 'validation/validators';
import type { PaymentAccountT } from './PaymentAccountModel';

const ACCOUNT_BETWEEN_4_17 = 'Account must be between 4 and 17 characters.';
const required = { value: true, message: 'This field is required.' };

const inputLabelProps: Partial<InputLabelProps> = {
  sx: (theme) => ({
    color: theme.palette.secondary.dark,
    ...theme.typography.h3_5,
  }),
};

type PaymentAccountPropsT = {
  control: Control<PaymentAccountT>;
  getValues: UseFormGetValues<PaymentAccountT>;
};

export const PaymentAccount = ({ control, getValues }: PaymentAccountPropsT) => {
  const { trigger } = useFormContext();

  return (
    <>
      <Box textAlign='left'>
        <FadedTextH35>Select Account Type:</FadedTextH35>
        <VSpacer spacing={2} />
        <FormRadioGroupField control={control} name='accountType' rules={{ required }}>
          <FormRadioField label='Checking' value='CHECKING' />
          <FormRadioField label='Savings' value='SAVINGS' />
        </FormRadioGroupField>
        <VSpacer spacing={4} />
        <FadedTextH35>Enter Account Number</FadedTextH35>
        <VSpacer spacing={3} />
        <FormMaskField
          name='accountNumber'
          inputProps={{ 'data-testid': 'account-number' }}
          InputLabelProps={inputLabelProps}
          onPostChange={async () => trigger('accountNumberConfirm')}
          label='Account Number *'
          control={control}
          rules={{
            required,
            minLength: { value: 4, message: ACCOUNT_BETWEEN_4_17 },
            maxLength: { value: 17, message: ACCOUNT_BETWEEN_4_17 },
          }}
        />
        <VSpacer spacing={3} />
        <FormMaskField
          name='accountNumberConfirm'
          inputProps={{ 'data-testid': 'account-number2' }}
          InputLabelProps={inputLabelProps}
          label='Confirm Account Number *'
          control={control}
          rules={{
            required,
            validate: (confirm) =>
              confirm === getValues('accountNumber') ||
              getValues('accountNumber')?.trim() === '' ||
              'Account numbers do not match.',
          }}
        />
        <VSpacer spacing={4} />
        <FadedTextH35>Enter 9-digit Routing Number</FadedTextH35>
        <VSpacer spacing={3} />
        <FormTextField
          name='routingNumber'
          inputProps={{ 'data-testid': 'routing-number' }}
          InputLabelProps={inputLabelProps}
          label='Routing Number *'
          control={control}
          rules={routingNumberValidation}
        />
      </Box>
    </>
  );
};
