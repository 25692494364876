/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import type { ButtonProps } from '@mui/material';
import { styled } from '@mui/material';
import { GreenButton } from 'components/Buttons';
import { useNavigationHelpers } from 'hooks/navigateHelper';

type MakePaymentButtonProps = ButtonProps & {
  isAccountPastDue: boolean;
};

const $GreenButton = styled(GreenButton)({
  '&:hover': {
    backgroundColor: '#128963',
  },
});

export const MakePaymentButton = ({ isAccountPastDue, ...props }: MakePaymentButtonProps) => {
  const { navigateToAppendedRoute } = useNavigationHelpers();
  return (
    <$GreenButton onClick={() => navigateToAppendedRoute('/onetime')} {...props}>
      {isAccountPastDue ? 'Pay Now' : 'Make a Payment'}
    </$GreenButton>
  );
};
