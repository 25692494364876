/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { DataRow } from 'components/DataRow';
import { VSpacer } from 'components/VSpacer';
import { PaymentMethodCard } from './Autopay/PaymentMethodCard';

type PaymentMethodCurrentPropsT = {
  accountType: string;
  accountNumber: string;
  routingNumber: string;
};

export const PaymentMethodCurrent = ({ accountNumber, accountType, routingNumber }: PaymentMethodCurrentPropsT) => (
  <>
    <DataRow label='Current Payment Method' data='' />
    <VSpacer spacing={3} />
    <PaymentMethodCard accountType={accountType} accountNumber={accountNumber} routingNumber={routingNumber} />
  </>
);
