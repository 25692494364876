/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type defaultDayjs from 'dayjs';
import { type DateIOFormats } from '@date-io/core/IUtils';

interface CustomDateAdapterProps {
  locale?: string;
  instance?: typeof defaultDayjs;
  formats?: Partial<DateIOFormats>;
}

/*
Mui date-picker is hardcoded to call charAt() then toUpperCase() when getting the weekday headers, so they end up as ['S'. 'M', 'T', 'W', 'T', 'F', 'S']
The purpose of this custom adapter is to get the headers to show up as ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
It returns a regular AdapterDayjs object but with the getWeekdays method overrided to return a list of objects pretending to be strings
When they call charAt() on one of those objects it returns another object that when they call toUpperCase() on returns the 3 character weekday header. 
*/
export default function CustomDateAdapter({ locale, instance, formats }: CustomDateAdapterProps): AdapterDayjs {
  const adapter = new AdapterDayjs({ locale, instance, formats });

  const overrideCharAtObject = (day: string) => ({ charAt: () => overrideToUpperCaseObject(day) } as unknown as string);
  const overrideToUpperCaseObject = (day: string) => ({ toUpperCase: () => day });

  return {
    ...adapter,
    getWeekdays() {
      const customWeekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      return customWeekdays.map((day) => overrideCharAtObject(day));
    },
  };
}
