/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { styled } from '@mui/material';
import ClockIcon from 'assets/Clock.svg';
import { useTransactionHelpers } from 'hooks/useTransactionHelper';
import { useRecoilValue } from 'recoil';
import { selectedLoanCodeState } from 'store/LoanStore';
import { $BannerWrapper, getPaymentProcessingLabel } from './BannerHelpers';

export const $ThinBanner = styled($BannerWrapper)(({ theme }) => ({
  display: 'flex',
  minHeight: '64px',
  backgroundColor: 'rgba(0, 193, 212, .96)',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px',
  color: theme.palette.background.default,
  ...theme.typography.subtitle1_6,
  lineHeight: '1.71',
  [theme.breakpoints.down('lg')]: {
    ...theme.typography.body3,
    lineHeight: '2',
  },
}));

type PaymentProcessingBannerPropsT = {
  dashboard: boolean;
};

export const PaymentProcessingBanner = ({ dashboard }: PaymentProcessingBannerPropsT) => {
  const { getProcessingPayments } = useTransactionHelpers();
  const selectedLoanCode = useRecoilValue<string>(selectedLoanCodeState);
  const processingPayments = dashboard ? getProcessingPayments() : getProcessingPayments(selectedLoanCode);
  const label = getPaymentProcessingLabel(processingPayments);
  return (
    <>
      {processingPayments.length > 0 && (
        <$ThinBanner data-testid='paymentProcessingBanner'>
          <img src={ClockIcon} alt='clock_icon' style={{ marginRight: '12px' }} />
          {label}
        </$ThinBanner>
      )}
    </>
  );
};
