/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Divider, Drawer, IconButton, styled, Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import CloseIcon from '@mui/icons-material/Close';
import { DashboardButton } from 'components/DashboardButton';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import { sidebarState } from '../../store/LayoutStore';
import { ProfileMenu } from './ProfileMenu';

const $IconButton = styled(IconButton)({
  height: '24px',
  width: '24px',
  padding: 0,
  marginBottom: '32px',
  alignSelf: 'flex-end',
  display: 'inline-flex',
});

export const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarState);
  const { navigateToParentRoute } = useNavigationHelpers();

  const handleClose = () => {
    setSidebarOpen(false);
    navigateToParentRoute();
  };

  return (
    <Drawer PaperProps={{ sx: { backgroundColor: 'background.default' } }} open={sidebarOpen} onClose={handleClose}>
      <$IconButton onClick={() => setSidebarOpen(false)}>
        <CloseIcon data-testid='CloseIcon' />
      </$IconButton>
      <Box sx={{ width: 'fit-content', marginLeft: '-16px' }}>
        <DashboardButton />
      </Box>
      <Divider sx={{ margin: '4px 0 23px 0', height: '2px' }} />
      <ProfileMenu />
    </Drawer>
  );
};
