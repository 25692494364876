/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

import type { SyntheticEvent } from 'react';

export function promiseHandler<T>(promise: (event: SyntheticEvent) => Promise<T>) {
  return (event: SyntheticEvent) => {
    if (promise) {
      promise(event).catch((_error) => {
        window.history.pushState(null, '', '/error');
      });
    }
  };
}
