// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { useMsal } from '@azure/msal-react';
import { useResetRecoilState } from 'recoil';
import { userAuthState } from 'store/UserStore';
import { BrowserUtils } from '@azure/msal-browser';

export const useAuth = () => {
  const { instance } = useMsal();
  const resetUserAuth = useResetRecoilState(userAuthState);

  const checkAuth = () => {
    if (instance.getAllAccounts().length === 0) {
      logoutWithNavigate();
    }
  };

  const logoutWithNavigate = () => {
    try {
      resetUserAuth();
      void instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      });
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const logoutWithRedirect = (redirectRoute: string) => {
    try {
      resetUserAuth();
      void instance.logoutRedirect({
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: redirectRoute,
      });
    } catch (error: unknown) {
      console.error(error);
    }
  };

  return { checkAuth, logoutWithRedirect, resetUserAuth, logout: logoutWithNavigate };
};
