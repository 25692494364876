/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Suspense, type ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Content } from 'components/Content/Content';
import { LoanSelectionSkeleton } from 'components/LoanSelection/LoanSelectionSkeleton';
import { NavigationTabs } from 'components/Content/NavigationTabs';
import { useLoanCodeInRoute } from 'hooks/useLoanCodeInRoute';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { LoanSelection } from '../LoanSelection/LoanSelection';
import { Sidebar } from '../Sidebar/Sidebar';
import Session from '../SessionExpiration/Session';

const $Container = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  minWidth: '320px',
  backgroundColor: `${theme.palette.background.default}`,
}));

export type LayoutPropsT = {
  children?: ReactNode;
  showDropDown?: boolean;
};

export const Layout = ({ children, showDropDown = true }: LayoutPropsT) => {
  useLoanCodeInRoute();
  return (
    <$Container disableGutters maxWidth={false}>
      <Header />
      <Sidebar />
      {showDropDown ? (
        <>
          <Suspense fallback={<LoanSelectionSkeleton />}>
            <LoanSelection />
          </Suspense>
          <NavigationTabs />
          <Content>{children}</Content>
        </>
      ) : (
        children
      )}
      <Footer />
      <Session />
    </$Container>
  );
};
