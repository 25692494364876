/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import type { PropsWithChildren } from 'react';
import { Suspense } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { featureFlagState } from 'store/FeatureFlagStore';

export const FeatureFlagLoader = ({ children }: PropsWithChildren) => {
  useRecoilValueLoadable(featureFlagState);
  return <Suspense>{children}</Suspense>;
};
