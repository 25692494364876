/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { IconButton, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const $DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  paddingBottom: '32px',
  minHeight: 0,
  justifyContent: 'flex-end',
}));

type AutopayHeaderPropsT = {
  onClose: () => void;
};

export const FlyoutHeader = ({ onClose }: AutopayHeaderPropsT) => (
  <$DrawerHeader>
    <IconButton sx={{ padding: 0 }} data-testid='xbutton' onClick={onClose}>
      <ClearIcon />
    </IconButton>
  </$DrawerHeader>
);
