/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import type { TypographyProps } from '@mui/material';
import { TextData1, TextH2 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import type { ReactNode } from 'react';

type TitledNumberPropsT = {
  title: ReactNode;
  amount: number | string;
  headerProps?: TypographyProps;
  numberProps?: TypographyProps;
};

export const TitledNumber = ({
  title,
  amount,
  headerProps = undefined,
  numberProps = undefined,
}: TitledNumberPropsT) => (
  <>
    {typeof title === 'string' && <TextH2 {...headerProps}>{title}</TextH2>}
    {typeof title !== 'string' && title}
    <VSpacer spacing={1.5} />
    <TextData1 {...numberProps}>{amount}</TextData1>
    <VSpacer />
  </>
);
