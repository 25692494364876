/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import endpointConfig from 'config/endpointConfig';
import { axios } from 'helpers/axiosInstance';
import { atom, selector, selectorFamily } from 'recoil';
import { Statuses, Substatuses, type StatusesType } from 'Status';
import { sortLoansByDate } from 'helpers/arrayHelpers';
import dayjs from 'dayjs';
import { recoilPersist } from 'recoil-persist';
import { LoanPeriods } from 'LoanPeriod';

type LoanSummary = {
  merchantName: string;
  accountStatus: keyof StatusesType;
  accountStatusSubtype: string;
  totalLineOfCreditCents: number;
  daysLeftToSpend: number;
  creditUsedCents: number;
  creditRemainingCents: number;
  loanAmountCents: number;
  numberOfRemainingPayments: number;
  numberOfPaymentsMade: number;
  totalPaidToDateCents: number;
  promoPeriodLength: number;
  promoPeriodStartDate: string;
  promoPeriodEndDate: string;
};

type PlanInformation = {
  planType: string;
  term: number;
  apr: number;
  purchaseWindow: string;
  purchaseWindowLength: number;
  purchaseWindowEndDate: string;
};

type PaymentInformation = {
  paymentDueInCents: number;
  paymentDueDate: string;
  billingCycleEndDate: string;
  autopayEnabled: boolean;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  bankAccountType: 'CHECKING' | 'SAVINGS';
  totalPayoffCents: number;
  paymentToBringAccountCurrentCents: number;
};

export type Loan = {
  loanCode: string;
  loanSummary: LoanSummary;
  planInformation: PlanInformation;
  paymentInformation: PaymentInformation;
  isAccountPayable: boolean;
  paymentsCredits: number;
};

const defaultLoan: Loan = {
  loanCode: '',
  loanSummary: {
    merchantName: '',
    accountStatus: Statuses.UNKNOWN,
    accountStatusSubtype: '',
    totalLineOfCreditCents: 1,
    daysLeftToSpend: 0,
    creditUsedCents: 0,
    creditRemainingCents: 0,
    loanAmountCents: 0,
    numberOfRemainingPayments: 0,
    numberOfPaymentsMade: 0,
    totalPaidToDateCents: 0,
    promoPeriodLength: 0,
    promoPeriodStartDate: '',
    promoPeriodEndDate: '',
  },
  planInformation: {
    planType: '',
    term: 0,
    apr: 0,
    purchaseWindow: '',
    purchaseWindowEndDate: '',
    purchaseWindowLength: 0,
  },
  paymentInformation: {
    paymentDueInCents: 0,
    paymentDueDate: '',
    billingCycleEndDate: '',
    autopayEnabled: false,
    bankRoutingNumber: '',
    bankAccountNumber: '',
    bankAccountType: 'CHECKING',
    totalPayoffCents: 0,
    paymentToBringAccountCurrentCents: 0,
  },
  isAccountPayable: false,
  paymentsCredits: 0,
};

const defaultLoans: Loan[] = [defaultLoan];

export const submittedDashboardLoan = atom<Loan>({
  key: 'submittedDashboardLoan',
  default: defaultLoan,
});

export const loansRequestId = atom<number>({
  key: 'loansRequestId',
  default: 0,
});

export const loansState = selector({
  key: 'loans',
  async get({ get }) {
    get(loansRequestId);
    const config = endpointConfig.BORROWER_PORTAL.BORROWER_LOANS_INFO;
    const { data } = await axios<Loan[]>(config);
    return refineLoans(data) ?? defaultLoans;
  },
});

const refineLoans = (loans: Loan[]) => {
  for (const loan of loans) {
    correctAmountDue(loan);
    setIsAccountPayable(loan);
  }

  return loans;
};

const setIsAccountPayable = (loan: Loan) => {
  loan.isAccountPayable =
    (loan?.loanSummary.accountStatus === Statuses.CLOSED &&
      loan?.loanSummary.accountStatusSubtype === Substatuses.CHARGE_OFF) ||
    loan?.loanSummary.accountStatus !== Statuses.CLOSED;
  return loan;
};

const correctAmountDue = (loan: Loan) => {
  if (
    loan.paymentInformation.paymentDueInCents === 0 &&
    loan.paymentInformation.paymentToBringAccountCurrentCents > 0
  ) {
    loan.paymentInformation.paymentDueInCents = loan.paymentInformation.paymentToBringAccountCurrentCents;
  }

  return loan;
};

export const filteredLoansSelector = selectorFamily<Loan[], keyof StatusesType>({
  key: 'LoanFilters',
  get:
    (status) =>
    ({ get }) =>
      get(loansState).filter((l) => status === l.loanSummary.accountStatus),
});

export const openLoansSelector = selector<Loan[]>({
  key: 'openLoans',
  get({ get }) {
    return get(loansState).filter(
      (loan) =>
        Statuses.ACTIVE === loan?.loanSummary?.accountStatus || Statuses.SUSPENDED === loan?.loanSummary?.accountStatus,
    );
  },
});

export const payableLoansSelector = selector<Loan[]>({
  key: 'payableLoans',
  get({ get }) {
    return get(loansState).filter((loan) => loan.isAccountPayable);
  },
});

export const pastDueLoansSelector = selector<Loan[]>({
  key: 'pastDueLoans',
  get({ get }) {
    return get(loansState).filter((loan) => loan?.paymentInformation?.paymentToBringAccountCurrentCents > 0);
  },
});

export const autopayOffLoansSelector = selector<Loan[]>({
  key: 'autopayOffLoans',
  get({ get }) {
    return get(openLoansSelector).filter((loan) => !loan?.paymentInformation?.autopayEnabled);
  },
});

export const selectedLoanStateAutopayOff = selector<Loan>({
  key: 'selectedLoanAutopay',
  get({ get }) {
    const loans = get(autopayOffLoansSelector);
    const pastDueLoans = sortLoansByDate(loans.filter((l) => l?.loanSummary?.accountStatus === Statuses.SUSPENDED));
    const activeLoansEnabled = sortLoansByDate(
      loans.filter(
        (l) => l?.loanSummary?.accountStatus === Statuses.ACTIVE && l?.paymentInformation?.totalPayoffCents > 0,
      ),
    );
    const activeLoansDisabled = sortLoansByDate(
      loans.filter(
        (l) => l?.loanSummary?.accountStatus === Statuses.ACTIVE && l?.paymentInformation?.totalPayoffCents <= 0,
      ),
    );
    const sortedLoans = pastDueLoans.concat(activeLoansEnabled).concat(activeLoansDisabled);
    const loan = sortedLoans[0];
    return loan ?? defaultLoans[0];
  },
});

export const { persistAtom: persistedLoanCode } = recoilPersist({
  key: 'persistedLoanCode',
  storage: sessionStorage,
});

export const selectedLoanCodeState = atom({
  key: 'selectedLoanCode',
  default: selector({
    key: 'selectedLoanCodeDefault',
    get({ get }) {
      return get(loansState)?.length > 0 ? get(loansState)[0]?.loanCode : '';
    },
  }),
});

export const selectedLoanState = selector<Loan>({
  key: 'selectedLoan',
  get({ get }) {
    const loans = get(loansState);
    const loan = loans?.find((l) => l.loanCode === get(selectedLoanCodeState));
    return loan ?? defaultLoans[0];
  },
});

export const firstPaymentWindowDate = selector({
  key: 'firstPaymentWindowDate',
  get({ get }) {
    const loan = get(selectedLoanState);
    return dayjs(loan.paymentInformation.paymentDueDate).subtract(9, 'day'); // NOSONAR
  },
});

export const lastBillingCycleDate = selector({
  key: 'lastBillingCycleDate',
  get({ get }) {
    const loan = get(selectedLoanState);
    return dayjs(loan.paymentInformation.paymentDueDate).add(45, 'day'); // NOSONAR
  },
});

export const isSelectedLoanPastDue = selector<boolean>({
  key: 'isSelectedLoanPastDue',
  get({ get }) {
    const loan = get(selectedLoanState);
    return loan.paymentInformation.paymentToBringAccountCurrentCents > 0;
  },
});

const purchaseWindowEndedState = selector({
  key: 'purchaseWindowEnded',
  get({ get }) {
    const dateString = get(selectedLoanState)?.planInformation.purchaseWindowEndDate;
    return new Date() > new Date(dateString);
  },
});

const promoPeriodEndedState = selector({
  key: 'promoPeriodEnded',
  get({ get }) {
    const dateString = get(selectedLoanState)?.loanSummary.promoPeriodEndDate;
    return new Date() > new Date(dateString);
  },
});

export const currentLoanPeriodState = selector({
  key: 'currentLoanPeriod',
  get({ get }) {
    if (get(purchaseWindowEndedState)) {
      if (get(promoPeriodEndedState)) {
        return LoanPeriods.PURCHASE_WINDOW_EXPIRED_PROMO_EXPIRED;
      }

      return LoanPeriods.PURCHASE_WINDOW_EXPIRED_PROMO_ONGOING;
    }

    return LoanPeriods.PURCHASE_WINDOW_ONGOING;
  },
});

export const selectedPlanState = selector({
  key: 'selectedPlanType',
  get({ get }) {
    const planType = get(selectedLoanState)?.planInformation.planType;

    const planTypes: Record<string, string> = {
      EqualPaymentZeroInterestRate: 'Equal Payment Zero Interest',
      RiskBasedPricing: 'Custom Fit Pricing',
      DeferredInterestWithPayment: 'Deferred Interest With Payment',
      ReducedRate: 'Reduced Rate',
    } as const;

    return planTypes[planType] ?? planType;
  },
});
