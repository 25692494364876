/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Divider, styled } from '@mui/material';
import { WhiteButton } from 'components/Buttons';
import { DataRow } from 'components/DataRow';
import { useState } from 'react';
import { TextH2, TextH35 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import { toCurrencyFromCents } from 'helpers/numberHelpers';
import { dateTo } from 'helpers/stringHelpers';
import { useLocation } from 'react-router-dom';
import { getStatus } from './PaymentRow';
import { CancelPayment } from './CancelPayment';

const $Title = styled(TextH2)(() => ({
  textAlign: 'center',
  marginBottom: '34px',
}));

const $Divider = styled(Divider)(() => ({
  marginTop: '15px',
  marginBottom: '24px',
}));

const $Label = styled(TextH35)(() => ({
  lineHeight: '28px',
}));

const $Data = styled('span')(() => ({
  lineHeight: '28px',
}));

export type PaymentDetailProps = {
  paymentDate: Date;
  status: string;
  amountInCents: number;
  displayAccountNumber?: string;
  paymentId: string;
};

export const PaymentHistoryDetails = () => {
  const paymentDetails = useLocation().state as PaymentDetailProps;
  const isScheduled = paymentDetails.status === 'Scheduled';
  const [showCancelPayment, setShowCancelPayment] = useState(false);

  const startCancelationProcess = () => {
    setShowCancelPayment(true);
  };

  return (
    <>
      {!showCancelPayment && (
        <div style={{ textAlign: 'center' }} data-testid='payment-history-details'>
          <$Title>Payment Details</$Title>
          <DataRow
            label={<$Label>Payment Date</$Label>}
            data={<$Data>{dateTo('really_short_abbr', paymentDetails.paymentDate.toString())}</$Data>}
          />
          <$Divider />
          {isScheduled && (
            <>
              <DataRow
                label={<$Label>Payment Method</$Label>}
                data={<$Data>{paymentDetails.displayAccountNumber}</$Data>}
              />
              <$Divider />
            </>
          )}
          <DataRow
            label={<$Label>Amount</$Label>}
            data={<$Data>{toCurrencyFromCents(paymentDetails.amountInCents)}</$Data>}
          />
          <$Divider />
          <DataRow label={<$Label>Status</$Label>} data={<$Data>{getStatus(paymentDetails.status)}</$Data>} />
          <VSpacer spacing={5} />
          {isScheduled && (
            <WhiteButton sx={{ textAlign: 'center' }} onClick={startCancelationProcess}>
              Cancel Payment
            </WhiteButton>
          )}
        </div>
      )}
      {showCancelPayment && <CancelPayment {...paymentDetails} />}
    </>
  );
};
