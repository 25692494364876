/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import { styled, Box, Button, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { TextLink } from 'components/Typographies';
import { type BoxPropsT } from './DocumentRow';

type FileNamePropsT = { subHeader?: string };

const $Box = styled(Box)<BoxPropsT>(({ subHeader }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: subHeader ? 'space-between' : 'center',
  height: '100%',
}));

const $DocumentIcon = styled(DescriptionOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
}));

const $FileName = styled(Button)<FileNamePropsT>(({ theme, subHeader }) => ({
  height: '22px',
  marginTop: subHeader ? '9px' : 0,
  padding: '6px 5px',
  '> span': {
    color: theme.palette.primary.light,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '192px',
    },
  },
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const $FileSigned = styled(Typography)(({ theme }) => ({
  marginLeft: '30px',
  ...theme.typography.body2,
  color: theme.palette.secondary.dark,
}));

type DocumentTitlePropsT = {
  header: string;
  subHeader?: string;
  onOpen?: () => void;
};

export const DocumentTitle = ({ header, subHeader, onOpen }: DocumentTitlePropsT) => (
  <$Box subHeader={subHeader}>
    <$FileName subHeader={subHeader} variant='text' startIcon={<$DocumentIcon />}>
      <TextLink onClick={onOpen}>{header}</TextLink>
    </$FileName>
    {subHeader && <$FileSigned>Authorized: {subHeader}</$FileSigned>}
  </$Box>
);
