/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import type { AppType } from 'App';
import { AuthenticateUser } from 'components/AuthenticateUser/AuthenticateUser';
import { AutopayAuthorizationTerms } from 'components/AutopayAuthorizationTerms';
import { Dashboard } from 'components/Content/Dashboard/Dashboard';
import { Documents } from 'components/Content/Documents/Documents';
import { Overview } from 'components/Content/Overview/Overview';
import { Autopay } from 'components/Content/Payments/Autopay/Autopay';
import { ManagePaymentMethod } from 'components/Content/Payments/ManagePaymentMethod/ManagePaymentMethod';
import { OneTimePayment } from 'components/Content/Payments/OneTime/OneTimePayment';
import { PaymentFlyout } from 'components/Content/Payments/PaymentFlyout/PaymentFlyout';
import { PaymentHistoryDetails } from 'components/Content/Payments/PaymentHistory/PaymentHistoryDetails';
import { Payments } from 'components/Content/Payments/Payments';
import { PlainFlyout } from 'components/Content/PlainFlyout';
import { TagAccepted } from 'components/Content/TAGResponses/TagAccepted';
import { TagError } from 'components/Content/TAGResponses/TagError';
import { TagInvalid } from 'components/Content/TAGResponses/TagInvalid';
import { TagRejected } from 'components/Content/TAGResponses/TagRejected';
import TagWrapper from 'components/Content/TAGResponses/TagWrapper';
import { DoNotSellMyPersonalInfo } from 'components/DNSMPI/DoNotSellMyPersonalInfo';
import { ThankyouPage } from 'components/DNSMPI/ThankYouPage';
import { GeneralError } from 'components/GeneralError';
import { Layout } from 'components/Layouts/Layout';
import { PlainLayout } from 'components/Layouts/PlainLayout';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import { SessionExpiredPage } from 'components/SessionExpiration/SessionExpired/SessionExpired';
import { Terms } from 'components/Terms';
import type { ReactNode } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

const authenticatedRoute = (children: ReactNode, showDropDown = true) => (
  <AuthenticateUser>
    <Layout showDropDown={showDropDown}>{children}</Layout>
  </AuthenticateUser>
);

const publicRoute = (children: ReactNode) => <PlainLayout>{children}</PlainLayout>;
const paymentFlyout = (location: string) => [
  {
    path: `${location}/autopay`,
    element: (
      <PaymentFlyout>
        <Autopay />
      </PaymentFlyout>
    ),
  },
  {
    path: `${location}/autopayauthorization`,
    element: (
      <PlainFlyout>
        <AutopayAuthorizationTerms />
      </PlainFlyout>
    ),
  },
  {
    path: `${location}/onetime`,
    element: (
      <PaymentFlyout>
        <OneTimePayment />
      </PaymentFlyout>
    ),
  },
  {
    path: `${location}/manage`,
    element: (
      <PaymentFlyout>
        <ManagePaymentMethod />
      </PaymentFlyout>
    ),
  },
  {
    path: `${location}/paymentdetails`,
    element: (
      <PaymentFlyout>
        <PaymentHistoryDetails />
      </PaymentFlyout>
    ),
  },
];

export const createRouter = (App: AppType) =>
  createBrowserRouter([
    {
      path: '/',
      element: <App />,
      errorElement: <App>{publicRoute(<GeneralError />)}</App>,
      children: [
        {
          path: '/',
          element: authenticatedRoute(<Navigate replace to='/dashboard' />, false),
        },
        {
          path: '/dashboard',
          element: authenticatedRoute(<Dashboard />, false),
          children: paymentFlyout('/dashboard'),
        },
        {
          path: '/dashboard/:loanCode',
          element: authenticatedRoute(<Dashboard />, false),
          children: paymentFlyout('/dashboard/:loanCode'),
        },
        {
          path: '/overview',
          element: <Navigate replace to='/dashboard' />,
          children: paymentFlyout('/overview'),
        },
        {
          path: '/payments',
          element: <Navigate replace to='/dashboard' />,
          children: paymentFlyout('/payments'),
        },
        {
          path: '/documents',
          element: <Navigate replace to='/dashboard' />,
        },
        {
          path: '/error',
          element: publicRoute(<GeneralError />),
        },
        {
          path: '/overview/:loanCode',
          element: authenticatedRoute(<Overview />),
          children: paymentFlyout('/overview/:loanCode'),
        },
        {
          path: '/payments/:loanCode',
          element: authenticatedRoute(<Payments />),
          children: paymentFlyout('/payments/:loanCode'),
        },
        {
          path: '/documents/:loanCode',
          element: authenticatedRoute(<Documents />),
          children: paymentFlyout('/documents/:loanCode'),
        },
        {
          path: '/privacyPolicy',
          element: publicRoute(<PrivacyPolicy />),
        },
        {
          path: '/terms',
          element: publicRoute(<Terms />),
        },
        {
          path: '/DNSMPI',
          element: publicRoute(<DoNotSellMyPersonalInfo />),
        },
        {
          path: '/thankyou',
          element: publicRoute(<ThankyouPage />),
        },
        {
          path: '/accepted',
          element: <TagAccepted />,
        },
        {
          path: '/rejected',
          element: <TagRejected />,
        },
        {
          path: '/invalid',
          element: <TagInvalid />,
        },
        {
          path: '/transaction',
          element: <TagWrapper />,
        },
        {
          path: '/tagError',
          element: <TagError />,
        },
        {
          path: '/timeout',
          element: <SessionExpiredPage />,
        },
      ],
    },
  ]);
