/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import type { ButtonProps } from '@mui/material';
import { Box, styled } from '@mui/material';
import { BlueButton, LoadingBlueButton } from 'components/Buttons';
import { TextData4 } from 'components/Typographies';

type ManagePaymentSubmitPropsT = {
  loading: boolean;
} & ButtonProps;

const $TextData4 = styled(TextData4)(({ theme }) => ({
  color: theme.palette.common.black,
  lineHeight: '28px',
  fontWeight: '500',
}));

const $SpacedBox = styled(Box)(() => ({
  marginTop: '40px',
  marginBottom: '40px',
}));

export const ManagePaymentSubmit = ({ loading, ...props }: ManagePaymentSubmitPropsT) => (
  <Box textAlign='center'>
    <$SpacedBox>
      <$TextData4>Only one payment method will be saved at a time.</$TextData4>
    </$SpacedBox>
    <Box textAlign='center'>
      {!loading && (
        <BlueButton {...props} sx={{ textAlign: 'center' }}>
          Add Payment
        </BlueButton>
      )}
      {loading && <LoadingBlueButton />}
    </Box>
  </Box>
);
