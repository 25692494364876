/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, styled } from '@mui/material';
import { useLoansStateWithRefresh } from 'hooks/useLoanStateWithRefresh';
import { useDocumentStore, statementsState } from 'store/DocumentStore';
import { useRecoilValue } from 'recoil';
import { dateTo } from 'helpers/stringHelpers';
import { TextH1 } from 'components/Typographies';
import { downloadBlob, openBlob } from 'helpers/blobHelpers';
import { Accordion } from '../../Accordion';
import { DocumentRow } from './DocumentRow';
import { NoDocuments } from './NoDocuments';

const $Container = styled(Box)({
  width: '100%',
});

export const Statements = () => {
  const {
    loan: { loanCode },
  } = useLoansStateWithRefresh();
  const statements = useRecoilValue(statementsState);
  const { getStatementPdf } = useDocumentStore();
  const showDivider = (length: number, index: number) => length - 1 !== index;

  const handleDownload = (month: number, year: number, createdDate: string) => {
    getStatementPdf(loanCode, month, year)
      .then((response) => {
        downloadBlob(response, `Statement_${dateTo('no_separator', createdDate)}`);
      })
      .catch((_error) => {
        throw new Error('Unable to download statement document.');
      });
  };

  const handleOpen = (month: number, year: number) => {
    getStatementPdf(loanCode, month, year)
      .then((response) => {
        openBlob(response);
      })
      .catch((_error) => {
        throw new Error('Unable to open statement document.');
      });
  };

  return statements.length > 0 ? (
    <$Container>
      {statements.map((group, index1) => (
        <Accordion
          key={`${dateTo('year', group[0].startDate)}-${index1}`}
          header={<TextH1>{dateTo('year', group[0].startDate)}</TextH1>}
          sxSummary={(theme) => ({ backgroundColor: theme.palette.grey[50] })}
          sxDetails={{ padding: '24px 16px' }}
        >
          {group.map((document, index2) => (
            <DocumentRow
              key={`${document.month}-${index2}`}
              {...document}
              header={`${dateTo('short_abbr_no_year', document.startDate)} - ${dateTo(
                'short_abbr_no_year',
                document.endDate,
              )}`}
              showDivider={showDivider(group.length, index2)}
              onDownload={() => handleDownload(+document.month, +document.year, document.createdDate)}
              onOpen={() => handleOpen(+document.month, +document.year)}
            />
          ))}
        </Accordion>
      ))}
    </$Container>
  ) : (
    <NoDocuments />
  );
};
