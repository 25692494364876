/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import {
  Box,
  type BoxProps as MuiBoxProps,
  Container,
  Divider,
  Link,
  type LinkProps as MuiLinkProps,
  styled,
} from '@mui/material';
import IconGroup from 'assets/group.svg';
import config from 'config';
import { FooterLine } from './FooterLine';
import { SeeMoreLocations } from './SeeMoreLocations';

const $FooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: 'sticky',
  top: '100%',
}));

const $FooterCenter = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '> div:last-child': {
    marginBottom: 0,
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 16px 64px 16px !important',
  },
  [theme.breakpoints.only('md')]: {
    padding: '40px 40px 64px 40px !important',
  },
  [theme.breakpoints.only('lg')]: {
    padding: '40px 160px 64px 160px !important',
    '> div:nth-of-type(2) span': {
      marginLeft: '27px',
    },
  },
}));

const $Divider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
  backgroundColor: theme.palette.secondary.main,
  margin: '19px 0 22px 0',
}));

const Img = (props: MuiBoxProps) => <Box {...props} component='img' src={IconGroup} alt='group' />;
const $House = styled(Img)({
  verticalAlign: 'top',
  paddingTop: '3px',
});

const FooterLink = (props: MuiLinkProps) => (
  <Link {...props} variant='body2' target='_blank' rel='noopener noreferrer' />
);
FooterLink.defaultProps = { underline: 'always' };
const $FooterLink = styled(FooterLink)(({ theme }) => ({
  textDecorationColor: theme.palette.secondary.main,
  color: theme.palette.secondary.main,
}));

const DotWithSpacing = () => <>&nbsp;&nbsp;&#8226;&nbsp;&#32;</>;

export const Footer = () => (
  <$FooterContainer>
    <$FooterCenter maxWidth='lg'>
      <FooterLine>Personal loans not currently available in Illinois and Maryland.</FooterLine>
      <FooterLine>
        For California Residents: Consumer and commercial loans made or arranged pursuant to a California Financing Law
        license - Number 603G493.
      </FooterLine>
      <FooterLine>
        Consumer loans funded by one or more participating lenders. Equal Housing Lenders. <$House />
      </FooterLine>
      <FooterLine>&#169; 2022 Bankers Healthcare Group, LLC dba NaluPay.</FooterLine>
      <$Divider />
      <FooterLine>
        <$FooterLink href={`tel:${config.SUPPORT_INFO.Phone_Number}`} underline='none'>
          {config.SUPPORT_INFO.Phone_Number}
        </$FooterLink>
        <DotWithSpacing />
        <$FooterLink href={`mailto:${config.SUPPORT_INFO.Email}`}>{config.SUPPORT_INFO.Email}</$FooterLink>
        <DotWithSpacing />
        <br />
        Customer Support Address: {config.SUPPORT_INFO.Address}
      </FooterLine>
      <SeeMoreLocations>
        <FooterLine>Corporate Headquarters: {config.SUPPORT_INFO.Corporate_HQ_Address}</FooterLine>
        <FooterLine sx={{ paddingBottom: '14px' }}>
          Financial Headquarters: {config.SUPPORT_INFO.Financial_HQ_Address}
        </FooterLine>
      </SeeMoreLocations>
      <FooterLine>
        <$FooterLink href='/terms'>Terms &amp; Conditions</$FooterLink>
        <DotWithSpacing />
        <$FooterLink href='/privacypolicy'>Privacy Policy</$FooterLink>
        <DotWithSpacing />
        <$FooterLink href='/DNSMPI'>Do Not Sell My Personal Information</$FooterLink>
      </FooterLine>
    </$FooterCenter>
  </$FooterContainer>
);
