/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import type { SxProps } from '@mui/material';
import MuiAccordion, { type AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import type { Theme } from 'theme';

export type AccordionPropsT = MuiAccordionProps & {
  children?: ReactNode | ReactNode[];
  header: ReactNode;
  sxSummary?: SxProps<typeof Theme>;
  sxDetails?: SxProps<typeof Theme>;
  onlyCollapseWithArrow?: boolean;
  expandOnLoad?: boolean;
};

export const Accordion = ({
  children,
  header,
  sxSummary,
  sxDetails,
  onlyCollapseWithArrow = false,
  expandOnLoad = false,
  ...rest
}: AccordionPropsT) => {
  const [expand, setExpand] = React.useState(false);
  const toggleAccordian = () => {
    setExpand((previous) => !previous);
  };

  useEffect(() => {
    if (expandOnLoad) {
      setExpand(true);
    }
  }, []);

  return (
    <>
      {onlyCollapseWithArrow ? (
        <MuiAccordion {...rest} disableGutters elevation={0} expanded={expand}>
          <MuiAccordionSummary
            expandIcon={<ArrowDropDownIcon onClick={expand ? toggleAccordian : undefined} />}
            onClick={expand ? undefined : toggleAccordian}
            sx={sxSummary}
          >
            {header}
          </MuiAccordionSummary>
          <MuiAccordionDetails sx={sxDetails}>{children}</MuiAccordionDetails>
        </MuiAccordion>
      ) : (
        <MuiAccordion {...rest} disableGutters elevation={0}>
          <MuiAccordionSummary expandIcon={<ArrowDropDownIcon />} sx={sxSummary}>
            {header}
          </MuiAccordionSummary>
          <MuiAccordionDetails sx={sxDetails}>{children}</MuiAccordionDetails>
        </MuiAccordion>
      )}
    </>
  );
};
