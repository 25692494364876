/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { type ButtonProps, styled } from '@mui/material';
import { RedButton } from 'components/Buttons';
import { useNavigationHelpers } from 'hooks/navigateHelper';

const $PayNowButton = styled(RedButton)({
  width: '200px',
  flex: '0 0 auto',
});

export const BannerPayNow = (props: ButtonProps) => {
  const { navigateToAppendedRoute } = useNavigationHelpers();

  return (
    <$PayNowButton {...props} onClick={() => navigateToAppendedRoute('/onetime')}>
      Pay&nbsp;Now
    </$PayNowButton>
  );
};
