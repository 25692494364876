/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import type { ReactNode } from 'react';
import { Box, type BoxProps as MuiBoxProps, Divider, styled } from '@mui/material';

const $Row = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  minHeight: '25px',
});

const $label = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.secondary.dark,
  ...theme.typography.h3_5,
}));

const $Data = styled(Box)(({ theme }) => ({
  display: 'flex',
  ...theme.typography.data3,
  color: theme.palette.common.black,
}));

export type DataRowPropsT = MuiBoxProps & {
  label: ReactNode;
  data: ReactNode | ReactNode[];
  divider?: ReactNode;
  error?: boolean;
  showDivider?: boolean;
};

export const DataRow = ({ label, data, divider, error, showDivider = false, ...rest }: DataRowPropsT) => (
  <Box {...rest}>
    <$Row>
      <$label>{label}</$label>
      <$Data sx={(theme) => ({ color: error ? theme.palette.error.main : theme.palette.common.black })}>{data}</$Data>
    </$Row>
    {showDivider && (divider ?? <Divider />)}
  </Box>
);
