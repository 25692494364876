// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { Buffer } from 'buffer';
import { type PublicClientApplication } from '@azure/msal-browser';
import { AxiosError } from 'axios';
import serviceEndpoints from 'config/endpointConfig';
import { obfuscateData } from 'helpers/logHelpers';
import { axios } from 'helpers/axiosInstance';

const THROTTLE_TIMEOUT = 500;
let running = false;

export const buffer = (prop: unknown) => {
  if (prop instanceof Error) {
    // `getOwnProprtyNames` thing allows prototype values to be serialized
    return Buffer.from(JSON.stringify(prop, Object.getOwnPropertyNames(prop))).toString('base64');
  }

  return Buffer.from(JSON.stringify(prop)).toString('base64');
};

const nullBuffer = buffer('null'); // seeing this in the logs and trying to track it down

export const logFrontendError = (error: unknown, msalInstance: PublicClientApplication) => {
  if (running || !window.document) {
    return;
  }

  const { vendor, userAgent } = navigator;
  const account = msalInstance.getActiveAccount();
  const claims = account?.idTokenClaims;

  const internalError = error ?? new Error('nullish error');
  let message = error instanceof AxiosError ? obfuscateData(error) : buffer(internalError);

  if (error && (!message || message === nullBuffer)) {
    message = buffer('failed to convert error to base64');
  }

  const borrowerId = claims?.naluPayCustomerId;
  const device = buffer({ vendor, userAgent });
  const pageUrl = buffer(window.location.href);
  const body = { borrowerId, message, pageUrl, device };

  running = true;
  setTimeout(async () => {
    try {
      const config = { ...serviceEndpoints.BORROWER_PORTAL.POST_LOG };
      config.data = JSON.stringify(body);
      await axios(config);
    } finally {
      running = false;
    }
  }, THROTTLE_TIMEOUT);
};
