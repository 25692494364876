// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import { TitledNumber } from 'components/TitledNumber';
import { toCurrencyFromCents } from 'helpers/numberHelpers';

type PaymentDueSummaryPropsT = {
  isAccountPastDue: boolean;
  dueAmountInCents: number;
};

export const PaymentDueSummary = ({ isAccountPastDue, dueAmountInCents }: PaymentDueSummaryPropsT) => (
  <TitledNumber
    numberProps={{ color: (theme) => (isAccountPastDue ? theme.palette.error.main : theme.palette.common.black) }}
    title={isAccountPastDue ? 'Total Amount Due' : 'Amount Due'}
    amount={toCurrencyFromCents(dueAmountInCents)}
  />
);
