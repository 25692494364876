/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { ActionLink } from 'components/ActionLink';
import type { ButtonProps } from '@mui/material';
import { Box, styled } from '@mui/material';
import { BlueButton, LoadingBlueButton } from 'components/Buttons';
import { TextData4 } from 'components/Typographies';
import { VSpacer } from 'components/VSpacer';
import { useNavigationHelpers } from 'hooks/navigateHelper';
import { useNavigate } from 'react-router-dom';

type AutopaySubmitPropsT = {
  loading: boolean;
} & ButtonProps;

const $TextData4 = styled(TextData4)(({ theme }) => ({
  color: theme.palette.common.black,
  lineHeight: '28px',
  fontWeight: '500',
}));

export const AutopaySubmit = ({ loading, ...props }: AutopaySubmitPropsT) => {
  const { getParentRoute } = useNavigationHelpers();
  const navigate = useNavigate();

  const openAuthorization = () => {
    navigate(`${getParentRoute() || window.location.pathname}/autopayauthorization`);
  };

  return (
    <Box textAlign='left'>
      <$TextData4>By selecting submit you authorize NaluPay to enroll this account in autopay.</$TextData4>
      <VSpacer spacing={3} />
      <$TextData4>
        Each month, on your payment due date, your monthly payment amount will be automatically withdrawn from this
        account and will be initiated under the terms of a standing authorization.
      </$TextData4>
      <VSpacer spacing={5} />
      <Box textAlign='center'>
        <ActionLink onClick={openAuthorization}>View Autopay Authorization Terms</ActionLink>
      </Box>
      <VSpacer spacing={6} />
      <Box textAlign='center'>
        {!loading && (
          <BlueButton {...props} sx={{ textAlign: 'center' }}>
            Submit
          </BlueButton>
        )}
        {loading && <LoadingBlueButton data-testid='loading-autopay-submit' />}
      </Box>
    </Box>
  );
};
