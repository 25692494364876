// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.

import { styled } from '@mui/material';
import CountdownTimer from './CountdownTimer';
import type { SessionExtensionModalPropsT } from './SessionExtensionModalProps';

export const SessionCountdown = (props: SessionExtensionModalPropsT) => {
  const handleTimeExpired = () => {
    props.onTimeExpire();
    props.onCloseModal();
  };

  const $CountdownWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      margin: '20px 20px 0 0',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginTop: '64px',
    },
  }));

  return (
    <$CountdownWrapper data-testid='countdown-timer'>
      <CountdownTimer value={props.waitSeconds} onTimeCompleted={handleTimeExpired} />
    </$CountdownWrapper>
  );
};
