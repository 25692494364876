// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import type { TextFieldProps } from '@mui/material';
import { formatPhone } from 'helpers/stringHelpers';
import { useController, type Control, type FieldValues, type FieldPath } from 'react-hook-form';
import { phoneNumberValidation } from 'validation/validators';
import { FormTextField } from './FormTextField';

type FormPhoneFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
} & TextFieldProps;

export const FormPhoneField = <T extends FieldValues>({ control, name, ...textProps }: FormPhoneFieldProps<T>) => {
  const { field } = useController({ name, control, rules: phoneNumberValidation });
  return (
    <FormTextField
      name={name}
      placeholder='(423) 456-7891'
      control={control}
      onChange={(event) => {
        event.target.value = formatPhone(event.target.value);
        field.onChange(event);
      }}
      rules={phoneNumberValidation}
      {...textProps}
    />
  );
};
