/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */
import { Box, Grid, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Statuses } from 'Status';
import type { Loan } from 'store/LoanStore';
import { Status } from '../Status';

type MenuItemPropsT = { status: string };
const $MenuItem = styled(MenuItem)<MenuItemPropsT>(({ status, theme }) => ({
  color: status === Statuses.CLOSED ? theme.palette.grey[greyValue] : theme.palette.common.black,
  padding: '12px 16px',
}));

const greyValue = 100;

const $AccountNumber = styled(Box)({
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0.34px',
  lineHeight: '24px',
  textAlign: 'left',
});

const $AccountName = styled(Box)({
  fontSize: '16px',
  fontWeight: '600',
  letterSpacing: '0.38px',
  lineHeight: '28px',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const dropDownMenuItem = (loans?: Loan[]) =>
  loans?.map((loan) => (
    <$MenuItem value={loan.loanCode} key={loan.loanCode} status={loan.loanSummary.accountStatus}>
      <Grid container>
        <Grid item xs={8}>
          <$AccountNumber>Account Number: {loan.loanCode}</$AccountNumber>
        </Grid>
        <Grid item xs={4}>
          <Status status={loan.loanSummary.accountStatus} size='small' />
        </Grid>
        <Grid item xs={12}>
          <$AccountName>{loan.loanSummary.merchantName}</$AccountName>
        </Grid>
      </Grid>
    </$MenuItem>
  ));
