/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import { Box, styled } from '@mui/material';
import { useToasts } from 'hooks/useToasts';
import { useEffect } from 'react';
import { Toast } from './Toast';

const $ToastsArea = styled(Box)(({ theme }) => ({
  position: 'fixed',
  width: 'min(416px, 90%)',
  right: 0,
  top: 0,
  zIndex: 99,
  margin: '20px 25px 0 0',
  [theme.breakpoints.down('md')]: {
    left: 'calc(50% - min(216px, 45%))',
    alignSelf: 'center',
  },
}));

// Remove toasts after 6 seconds
const TOAST_TIME = 6000;

export const Toaster = () => {
  const { toasts, removeToast } = useToasts();

  useEffect(() => {
    if (toasts.length > 0) {
      const { id } = toasts[0];
      setTimeout(() => {
        removeToast(id);
      }, TOAST_TIME);
    }
  }, [toasts]);

  return (
    <$ToastsArea data-testid='toast-content'>
      {toasts.map((toast, index) => (
        <Toast key={index} toast={toast} />
      ))}
    </$ToastsArea>
  );
};
