// Copyright © Bankers Healthcare Group, Inc.  All rights reserved.
import type { ReactNode } from 'react';
import { Grid, Divider, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const $Grid = styled(Grid)({
  position: 'relative',
});

const $RightGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.only('lg')]: {
    padding: '0 32px 0 63px',
  },
}));

const $LeftGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.only('lg')]: {
    padding: '0 64px 0 32px',
  },
}));

const $Divider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.only('lg')]: {
    margin: '8px 0',
    position: 'absolute',
    height: 'calc(100% - 16px)',
    left: 'calc(50% - 1px)',
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    margin: '32px 0',
  },
}));

type DividedContainerPropsT = { left: ReactNode; right: ReactNode; noDivider?: boolean };

export const DividedContainer = ({ left, right, noDivider }: DividedContainerPropsT) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('lg'));

  return (
    <$Grid container>
      <$LeftGrid item xs={12} lg={6}>
        {left}
      </$LeftGrid>

      {!noDivider && <$Divider orientation={isDesktop ? 'vertical' : 'horizontal'} />}

      <$RightGrid item xs={12} lg={6}>
        {right}
      </$RightGrid>
    </$Grid>
  );
};
