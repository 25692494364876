/* Copyright © Bankers Healthcare Group, Inc. All rights reserved. */

import type { AxiosRequestConfig } from 'axios';
import config from 'config';

const { PRIVACY_DOMAIN, BORROWER_PORTAL_DOMAIN, AUTHORITY_DOMAIN, FEATURE_DOMAIN } = config.API_DOMAINS;

declare module 'axios' {
  export interface AxiosRequestConfig {
    useAuth: boolean;
    useRetry: boolean;
    useGlobalError: boolean;
  }
}

type RequestConfig = Readonly<AxiosRequestConfig>;

const endpointConfigs = {
  AUTHORITY: {
    OPENID_CONFIG: {
      url: `${AUTHORITY_DOMAIN}/.well-known/openid-configuration`,
      method: 'GET',
      useAuth: false,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
  },
  PRIVACY: {
    DO_NOT_SELL_MY_PERSONAL_INFO: {
      url: `${PRIVACY_DOMAIN}/send-do-not-sell-mypersonalinfo-mail`,
      method: 'POST',
      useAuth: false,
      useRetry: true,
      useGlobalError: false,
    } as RequestConfig,
  },
  BORROWER_PORTAL: {
    TAG_SUCCESS: {
      url: `${BORROWER_PORTAL_DOMAIN}/transaction`,
      method: 'POST',
      useAuth: false,
      useRetry: false,
      useGlobalError: false,
    } as RequestConfig,
    BORROWER_LOANS_INFO: {
      url: `${BORROWER_PORTAL_DOMAIN}/loans`,
      method: 'Get',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
    PAY_USING_STORED_INFO: {
      url: `${BORROWER_PORTAL_DOMAIN}/loans/{loanCode}/payments/ach-payment`,
      method: 'POST',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
    PAY_USING_ONETIME_INFO: {
      url: `${BORROWER_PORTAL_DOMAIN}/loans/{loanCode}/payments/ach-guest-payment`,
      method: 'POST',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
    POST_LOG: {
      url: `${BORROWER_PORTAL_DOMAIN}/log`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      useAuth: false,
      useRetry: true,
      useGlobalError: false,
    } as RequestConfig,
    SET_PAYMENT_METHOD: {
      url: `${BORROWER_PORTAL_DOMAIN}/payment/set-payment-method`,
      method: 'PUT',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
    GET_AUTOPAY_INFO: {
      url: `${BORROWER_PORTAL_DOMAIN}/autopay/loans/{loanCode}/forms`,
      method: 'GET',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
    GET_AUTOPAY_DOCS: {
      url: `${BORROWER_PORTAL_DOMAIN}/autopay/documents/{fileName}`,
      method: 'GET',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
      responseType: 'blob',
    } as RequestConfig,
    GET_STATEMENT_INFO: {
      url: `${BORROWER_PORTAL_DOMAIN}/statements/loans/{loanCode}/forms`,
      method: 'GET',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
    GET_STATEMENT_PDF: {
      url: `${BORROWER_PORTAL_DOMAIN}/statements/document`,
      method: 'GET',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
      responseType: 'blob',
    } as RequestConfig,
    GET_TRANSACTIONS: {
      url: `${BORROWER_PORTAL_DOMAIN}/transaction`,
      method: 'Get',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
    GET_PAYMENTS_EXISTING: {
      url: `${BORROWER_PORTAL_DOMAIN}/loans/{loanCode}/payments`,
      method: 'GET',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
    GET_PAYMENTS_SCHEDULED: {
      url: `${BORROWER_PORTAL_DOMAIN}/loans/{loanCode}/scheduled-payments`,
      method: 'GET',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
    CANCEL_PAYMENT: {
      url: `${BORROWER_PORTAL_DOMAIN}/payment/{paymentId}/cancel`,
      method: 'POST',
      useAuth: true,
      useRetry: true,
      useGlobalError: true,
    } as RequestConfig,
  },
  FEATURE_FLAGS: {
    url: `${FEATURE_DOMAIN}/featureFlags`,
    method: 'GET',
    useAuth: true,
    useRetry: true,
    useGlobalError: false,
  } as RequestConfig,
};

export default endpointConfigs;
